import FormGroupInput from "components/GeneralComponent/FormGroupInput.jsx";
import FormGroupSelect from "components/GeneralComponent/FormGroupSelect.jsx";
import FormGroupTable from "components/GeneralComponent/FormGroupTable.jsx";
import useEditRole from "hooks/useEditRole.js";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
} from "reactstrap";
import Swal from "sweetalert2";
import { fetchData } from "utils/Api.js";
import ModalApplHistory from "../../components/modal/ModalApplHistory.js";
import { AllowValidateNumbers } from "utils/CommonMethods.js";

const initialValues = {
  ApplicantCase_InvestigationId: 0,
  ApplicantFamily_MedicalCardDetailId: 0,
  ApplicantFamilyDetailId: "",
  EligibleCardId: "",
  MedicalCardAmount: 0,
  UserId: localStorage.getItem("UserId"),
  UserIP: localStorage.getItem("UserIP"),
};

const columns = [
  { field: "FamilyMemberName", name: "Family Member Name" },
  { field: "EligibleCard", name: "Eligible" },
  { field: "MedicalCardAmount", name: "Amount" },
  {
    field: "CreatedDate",
    name: "Created Date",
    type: "date",
  },
  // {
  //   field: "UpdatedDate",
  //   name: "Updated Date",
  //   type: "date",
  // },
];

const MedicalCardDetails = (props) => {
  const [role, appId] = useEditRole();

  // const [formFields, setDataFields] = useState(initialValues);
  const [formFields, setFormFields] = useState({
    ...initialValues,
    ApplicantCase_InvestigationId: appId,
  });
  // const [dataFieldsList, setDataFieldsList] = useState([]);
  const [medicalList, setMedicalList] = useState([]);
  const [selectionList, setSelectionList] = useState({
    FamilyMemberList: [],
    EligibleCardList: [],
  });

  const [formLoading, setFormLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    const fetchApplicantId = async () => {
      setTableLoading(true);
      let data1 = await fetchData("Applicant", "Crud_Family_Detail", {
        OperationId: 1,
        ApplicantCase_InvestigationId: formFields.ApplicantCase_InvestigationId,
        ApplicantFamily_MedicalCardDetailId:
          formFields.ApplicantFamily_MedicalCardDetailId,
      });
      fetchData("Applicant", "Crud_Family_Medical_Card_Detail", {
        OperationId: 1,
        ApplicantCase_InvestigationId: formFields.ApplicantCase_InvestigationId,
        ApplicantFamily_MedicalCardDetailId:
          formFields.ApplicantFamily_MedicalCardDetailId,
      }).then((result) => {
        setMedicalList(result?.DataSet?.Table);
        setSelectionList({
          ...selectionList,
          EligibleCardList: result?.DataSet?.Table1,
          FamilyMemberList: data1?.DataSet?.Table,
        });
        setTableLoading(false);
      });
    };
    // fetchFamilyMember();
    fetchApplicantId();
  }, []);
  const handleInputChange = (event) => {
    if (event.target.name === "MedicalCardAmount") {
      const {value} = event.target;
      const sanitized = AllowValidateNumbers(value,event?.target?.required);
      setFormFields({
        ...formFields,
        [event.target.name]:sanitized
      });
    } else {
      setFormFields({
        ...formFields,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let swelmsg = formFields.ApplicantFamily_MedicalCardDetailId === 0 ? 2 : 3;
    // let exist = medicalList.some(
    //   (item) =>
    //   item.ApplicantFamilyDetailId == formFields.ApplicantFamilyDetailId
    // );

    // if (exist) {
    //   let academicLevel = medicalList.some(
    //     (item) =>
    //       item.ApplicantFamilyDetailId == formFields.ApplicantFamilyDetailId &&
    //       item.EligibleCardId != formFields.EligibleCardId
    //   );
    //   if (academicLevel) {
    //     Swal.fire({
    //       customClass: {
    //         container: "my-swal",
    //       },
    //       text: "This name already exists with a different eligible card.",
    //       icon: "info",
    //     });
    //     return;
    //   }
    //   Swal.fire({
    //     customClass: {
    //       container: "my-swal",
    //     },
    //     text: "This name and eligible card already exists.",
    //     icon: "info",
    //   });
    //   return;
    // }


    let exist = {};
    // exist = medicalList?.find(
    //   (item) =>
    //     item.ApplicantFamilyDetailId == formFields.ApplicantFamilyDetailId &&
    //     item.EligibleCardId == formFields.EligibleCardId
    // );
    if (swelmsg == 2) {
      exist = medicalList?.find(
        (item) =>
          item.ApplicantFamilyDetailId == formFields.ApplicantFamilyDetailId &&
          item.EligibleCardId == formFields.EligibleCardId
      );
    } else {
      
      exist = medicalList?.find(
        (item) =>
          item.ApplicantFamilyDetailId == formFields.ApplicantFamilyDetailId &&
          item.EligibleCardId == formFields.EligibleCardId && 
          item?.ApplicantFamily_MedicalCardDetailId != formFields?.ApplicantFamily_MedicalCardDetailId
      );
    }
    
    if (exist) {
      Swal.fire({
        title: "Warning",
        text: `Duplicate data not allowed for same family member and eligible card`,
        icon: "warning",
      });
      setFormLoading(false);
      return;
    }
    
    if (swelmsg === parseInt(3)) {
      swelmsg = "Are you sure to edit the record?";
    } else {
      swelmsg = "Are you sure to add the record?";
    }

    Swal.fire({
      customClass: {
        container: "my-swal",
      },
      text: swelmsg,
      icon: "success",
      showCancelButton: true,
      cancelButtonText: `Cancel`,
      cancelButtonColor: "#2f4050",
      confirmButtonText: `Confirm`,
      confirmButtonColor: "#bf1e2e",
    }).then((result) => {
      if (result.isConfirmed) {
       
        requestCall(
          formFields.ApplicantFamily_MedicalCardDetailId === 0 ? 2 : 3,  
          formFields
        );
      }
    });
  };
  const onEdit = (index) => {
    setFormFields({ 
      ...formFields, 
      ...medicalList[index], 
      MedicalCardAmount: medicalList[index].MedicalCardAmount == 0 ? '0':  medicalList[index].MedicalCardAmount });
  };

  const onDelete = (index) => {
    Swal.fire({
      customClass: {
        container: "my-swal",
      },
      text: "Are you sure want to Delete Record?",
      icon: "success",
      showCancelButton: true,
      cancelButtonText: `Cancel`,
      cancelButtonColor: "#2f4050",
      confirmButtonText: `Confirm`,
      confirmButtonColor: "#bf1e2e",
    }).then((result) => {
      if (result.isConfirmed) {
        requestCall(4, { ...formFields, ...medicalList[index] });
      }
    });
  };
  /**
   * Request Call Function
   * @param {number} OperationId
   * @param {*} payload
   */
  const requestCall = (opId, payload) => {
    setFormLoading(true);
    fetchData("Applicant", "Crud_Family_Medical_Card_Detail", {
      OperationId: opId,
      ...payload,
    }).then((result) => {
      if (result?.DataSet?.Table[0]?.HasError == 1) {
        Swal.fire({
          title: "Error",
          text: result?.DataSet?.Table[0]?.Message,
          icon: "error",
        });
        setFormLoading(false);
        return;
      }
      if (result?.DataSet?.Table[0]?.HasError == 2) {
        Swal.fire({
          title: "Warning",
          text: result?.DataSet?.Table[0]?.Message,
          icon: "warning",
        });
        return;
      }
      
      Swal.fire({
        title: "Success",
        text: result?.DataSet?.Table[0]?.Message,
        icon: "success",
      });
      setMedicalList(result?.DataSet?.Table1);
      setFormFields({ ...initialValues, ApplicantCase_InvestigationId: appId });
      setFormLoading(false);
    });
  };

  return (
    <div>
      <Card className="mb-3">
        <CardHeader>
          <h6 className="font-weight-bold mb-0">Medical Card Details</h6>
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <h2 className="h6">Medical Card Details</h2>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col md={12}>
                <FormGroupTable
                  loading={tableLoading}
                  columns={columns}
                  rows={medicalList}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  hideAction={role}
                />
              </Col>
            </Row>

            <Row form>
              <Col md={3}>
                <FormGroupSelect
                  label="Family Member Name*"
                  name="ApplicantFamilyDetailId"
                  value={formFields?.ApplicantFamilyDetailId}
                  onChange={handleInputChange}
                  list={selectionList.FamilyMemberList}
                  fieldId="ApplicantFamilyDetailId"
                  fieldName="Name"
                  required={true}
                  disabled={role}
                />
              </Col>
              <Col md={3}>
                <FormGroupSelect
                  label="Eligible*"
                  name="EligibleCardId"
                  value={formFields.EligibleCardId}
                  onChange={handleInputChange}
                  list={selectionList.EligibleCardList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  required={true}
                  disabled={role}
                />
              </Col>
              <Col md={3}>
                <FormGroupInput
                  label="Amount"
                  name="MedicalCardAmount"
                  value={`${formFields.MedicalCardAmount ? formFields.MedicalCardAmount : ''}`
                    // formFields.MedicalCardAmount === ""
                    //   ? "0"
                    //   : formFields.MedicalCardAmount
                  }
                  onChange={handleInputChange}
                  // required={true}
                  isNumber="true"
                  disabled={role}
                  maxLength="9"
                />
              </Col>
            </Row>
            <Row className="text-right">
              <Col md={12}>
                <FormGroup>
                  {role ? null : (
                    <Button color="primary" type="submit">
                      Add Medical Card
                    </Button>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default MedicalCardDetails;
