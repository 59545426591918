import React from "react"
import ReactDOM from "react-dom"

import "bootstrap/dist/css/bootstrap.css"
import "assets/scss/paper-dashboard.scss"
import "assets/css/print.css"
import "perfect-scrollbar/css/perfect-scrollbar.css"
import "font-awesome/css/font-awesome.min.css"
import App from "App"

ReactDOM.render(<App />, document.getElementById("root"))
//<Redirect to="/admin/dashboard" />
//<Route path="/admin" render={(props) => <AdminLayout {...props} />} />
