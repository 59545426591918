import FormGroupButton from "components/GeneralComponent/FormGroupButton"
import FormGroupSelect from "components/GeneralComponent/FormGroupSelect"
import FormGroupTable from "components/GeneralComponent/FormGroupTable"
import React, { useEffect, useMemo, useState } from "react"
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Button,
  Spinner,
} from "reactstrap"
import Swal from "sweetalert2"
import * as api from "utils/Api"
import { GetSetupMaster } from "utils/CommonMethods"
import { DataTableCustomStyles, Roles, SetupMasterIds } from "utils/Constants"
import { useParams } from "react-router"
import { useHistory } from "react-router-dom"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {
  dateFormat,
  dateFormatPlaceholder,
  getDate,
  getDatefrom,
} from "utils/CommonMethods"
import DataTable from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions"
import "react-data-table-component-extensions/dist/index.css"
import UnAuthorized from "components/GeneralComponent/UnAuthorized"
const PaymentListing = () => {
  const { id } = useParams()
  const history = useHistory()

  const RoleId = localStorage.getItem("RoleId")

  const [statusList, setStatusList] = useState([])
  const [paymentListSummary, setPaymentListSummary] = useState([])
  const [loading, setLoading] = useState(false)
  const [paymentList, setPaymentList] = useState([])
  const [paymentHistory, setPaymentHistory] = useState([])

  const [categoryDDL, setCategoryDDL] = useState([])
  const [FundSubCatDDL, setFundSubCatDDL] = useState([])

  const [paymentDDL, setPaymentDDL] = useState([])
  // const [isTransExist, setIsTransExist] = useState(false);

  const initialValues = {
    OperationId: 0,
    CreatedDateFrom: "",
    CreatedDateTo: "",
    FromApprovalDate: "",
    ToApprovalDate: "",
    PaymentListStatusId: 0,
    PaymentListMasterId: id,
    Remarks: "",
    UserId: localStorage.getItem("UserId"),
    UserIP: localStorage.getItem("UserIP"),
    ArrayPaymentList: [],
    ApplicantCaseCode: "",
    ApplicantCNIC: "",
    PrimaryFundCategoryId: 0,
    PaymentType: 0,
    ReceiverName: "",
    ReceiverCNIC: "",
    ReceiverContactNumber: "",
    UploadReceipt: "",
    FundSubCategoryId: 0,
  }

  const [formFields, setFormFields] = useState(initialValues)

  const columnsHistory = [
    {
      name: "Status",
      field: "Status",
    },
    {
      name: "Remarks",
      field: "Remarks",
    },
    {
      name: "Created Date",
      field: "CreatedDate",
    },
    {
      name: "Action By",
      field: "ActionBy",
    },
  ]

  const columnsSummary = [
    {
      name: "Payment Type",
      field: "PaymentType",
    },
    {
      name: "Total Payable Amount",
      field: "Current_TotalPayableAmount",
    },
  ]
  const columns = [
    {
      name: "Sr.",
    },
    {
      name: "Applicant Case Code",
    },
    {
      name: "Name",
    },
    {
      name: "CNIC No",
    },
    {
      name: "City",
    },
    {
      name: "Fund Category",
    },

    {
      name: "Fund Sub Category",
    },

    {
      name: "Case Status",
    },
    {
      name: "Total Approved Amount",
    },
    {
      name: "Balance Amount",
    },
    {
      name: "Installment Amount",
    },

    {
      name: "Repetition",
    },

    {
      name: "Frequency",
    },

    {
      name: "Payment Type",
    },
    {
      name: "Bank",
    },
    {
      name: "Account Title",
    },
    {
      name: "Account No",
    },
    {
      name: "Receiver CNIC No",
    },
    {
      name: "Electronic Payment",
    },
    // {
    //   name: "PaymentScheduleId",
    // },
    {
      name: "View Case",
    },

    {
      name: "Action",
    },
  ]

  const DataTableColumns = useMemo(() => {
    return [
      {
        name: "Sr.",
        cell: (row, index) => index + 1,
        cellExport: (row, index) => index + 1,
        width: "88px",
        sortable: true,
      },
      {
        name: "Applicant Case Code",
        selector: (row) => row.ApplicantCaseCode,
        cell: (row) => <span>{row.ApplicantCaseCode}</span>,
        width: "88px",
        sortable: true,
      },
      {
        name: "Name",
        selector: (row) => row.ApplicantName,
        cell: (row) => <span>{row.ApplicantName}</span>,
        width: "88px",
        sortable: true,
      },
      {
        name: "CNIC No",
        selector: (row) => row.CnicNo,
        cell: (row) => <span>{row.CnicNo}</span>,
        width: "88px",
        sortable: true,
      },
      {
        name: "Primary Contact No",
        selector: (row) => row.ApplicantPrimaryContactNumber,
        cell: (row) => <span>{row.ApplicantPrimaryContactNumber}</span>,
        width: "88px",
        sortable: true,
      },
      {
        name: "City",
        selector: (row) => row.City,
        cell: (row) => <span>{row.City}</span>,
        width: "88px",
        sortable: true,
      },
      {
        name: "Fund Category",
        selector: (row) => row.FundCategory,
        cell: (row) => <span>{row.FundCategory}</span>,
        width: "88px",
        sortable: true,
      },

      {
        name: "Fund Sub Category",
        selector: (row) => row.FundSubCategory,
        cell: (row) => <span>{row.FundSubCategory}</span>,
        width: "88px",
        sortable: true,
      },

      {
        name: "Case Status",
        selector: (row) => row.CaseStatus,
        cell: (row) => <span>{row.CaseStatus}</span>,
        width: "88px",
        sortable: true,
      },
      {
        name: "Total Approved Amount",
        selector: (row) => row.CaseTotalAmountApproved,
        cell: (row) => <span>{row.CaseTotalAmountApproved}</span>,
        width: "88px",
        sortable: true,
      },
      {
        name: "Balance Amount",
        selector: (row) => row.CaseTotalAmountBalance,
        cell: (row) => <span>{row.CaseTotalAmountBalance}</span>,
        width: "88px",
        sortable: true,
      },
      {
        name: "Installment Amount",
        selector: (row) => row.Case_Current_InstallmentAmount,
        cell: (row) => <span>{row.Case_Current_InstallmentAmount}</span>,
        width: "88px",
        sortable: true,
      },

      {
        name: "Repetition",
        selector: (row) => row.Repitation,
        cell: (row) => <span>{row.Repitation}</span>,
        width: "88px",
        sortable: true,
      },

      {
        name: "Frequency",
        selector: (row) => row.Frequency,
        cell: (row) => <span>{row.Frequency}</span>,
        width: "88px",
        sortable: true,
      },

      {
        name: "Payment Type",
        selector: (row) => row.PaymentType,
        cell: (row) => <span>{row.PaymentType}</span>,
        width: "88px",
        sortable: true,
      },
      {
        name: "Bank",
        selector: (row) => row.BankName,
        cell: (row) => <span>{row.BankName}</span>,
        width: "88px",
        sortable: true,
      },
      {
        name: "Account Title",
        selector: (row) => row.AccountTitle,
        cell: (row) => <span>{row.AccountTitle}</span>,
        width: "88px",
        sortable: true,
      },
      {
        name: "Account No",
        selector: (row) => row.AccountNumber,
        cell: (row) => <span>{row.AccountNumber}</span>,
        width: "88px",
        sortable: true,
      },
      {
        name: "Receiver CNIC No",
        selector: (row) => row.PaymentCNICNo,
        cell: (row) => <span>{row.PaymentCNICNo}</span>,
        width: "88px",
        sortable: true,
      },
      {
        name: "Electronic Payment",
        selector: (row) => row.PaymentGateway,
        cell: (row) => <span>{row.PaymentGateway}</span>,
        width: "88px",
        sortable: true,
      },
      // {
      //   name: "PaymentScheduleId",
      // },
      {
        name: "View Case",
        selector: (row, i) => i + 1,
        cell: (row) => (
          <Button
            color="danger"
            outline
            size="sm"
            onClick={() => {
              localStorage.setItem("ACIid", row.ApplicantCase_InvestigationId)
              localStorage.setItem("role", 0)
              localStorage.setItem('PaymentListStatusId', row?.PaymentListStatusId) // line added
              window.open(
                "/admin/ApplicantDetail/" + row.ApplicantCase_InvestigationId,
                "_blank"
              )
            }}
          >
            View
          </Button>
        ),
        width: "160px",
        sortable: true,
      },

      {
        name: "Action",
        selector: (row, i) => row.IsChecked,
        cell: (row, i) =>
          id == 0 ? (
            <input
              type="checkbox"
              name="IsChecked"
              checked={row.IsChecked}
              // disabled={role}
              onChange={(e) =>
                handleCheckedValue(
                  {
                    target: {
                      name: e.target.name,
                      value: e.target.checked,
                    },
                  },
                  i
                )
              }
            />
          ) : null,
        width: "88px",
        sortable: true,
      },
    ]
  }, [paymentList])

  const GetCategory = async (e) => {
    var catValues = await GetSetupMaster(SetupMasterIds.Category, 0, "", 0)
    setCategoryDDL(catValues.data)
  }

  const GetFundSubCategory = async (CategoryId) => {
    var fundsubcat = await GetSetupMaster(
      SetupMasterIds.FundCategory,
      CategoryId == "0" ? "-1" : CategoryId,
      "",
      0
    )
    setFundSubCatDDL(fundsubcat.data)
    //return data;
  }

  const GetPayment = async (e) => {
    try {
      var data = await GetSetupMaster(
        SetupMasterIds.PaymentTypeDetail,
        0,
        "",
        0
      )
      if (data != null) {
        if (data.response === true && data.data != null) {
          setPaymentDDL(data.data.filter((data1) => data1.ParentId === 0))
          return data
        } else {
          return []
        }
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }

  const generatePayment = async (e, opId) => {
    setLoading(true)
    try {
      //
      var isValidate = false

      if (e !== null) {
        e.preventDefault()

        var date1 =
          formFields.CreatedDateFrom !== ""
            ? new Date(formFields.CreatedDateFrom)
            : ""
        var date2 =
          formFields.CreatedDateTo !== ""
            ? new Date(formFields.CreatedDateTo)
            : ""

        if (
          formFields.CreatedDateFrom !== "" &&
          formFields.CreatedDateTo !== ""
        ) {
          if (date1 <= date2) {
            isValidate = true
          } else {
            Swal.fire({
              title: "Error",
              text: "Please Select Proper Dates",
              icon: "error",
            })
            return
          }
        } else {
          Swal.fire({
            title: "Error",
            text: "Please Select Date From & Date To",
            icon: "error",
          })
          return
        }
      } else {
        isValidate = true
      }

      if (isValidate) {
        var obj = {
          ...formFields,
          PaymentType:
            formFields.PaymentType == "" ? 0 : formFields.PaymentType,
          PrimaryFundCategoryId:
            formFields.PrimaryFundCategoryId == ""
              ? 0
              : formFields.PrimaryFundCategoryId,
          FundSubCategoryId:
            formFields.FundSubCategoryId == ""
              ? 0
              : formFields.FundSubCategoryId,
          OperationId: opId,
        }

        var data = await api.postRecord(`Payment`, `Payment_List_Table`, obj)
        //
        if (data?.data?.Response === true) {
          if (data?.data?.DataSet?.Table?.length > 0) {
            setPaymentListSummary(data?.data?.DataSet?.Table)
            setPaymentList(data?.data?.DataSet?.Table1)
            setStatusList(data?.data?.DataSet?.Table3)
            if (opId === 6) {
              var obj1 = {
                ...formFields,
                CreatedDateFrom: data?.data?.DataSet?.Table1[0].FromDate,
                CreatedDateTo: data?.data?.DataSet?.Table1[0].ToDate,
              }
              setFormFields({
                ...formFields,
                ...obj1,
              })
              setPaymentHistory(data?.data?.DataSet?.Table2)
            }
          } else {
            setPaymentListSummary([])
            setPaymentList([])
            setStatusList([])
            setPaymentHistory([])
            Swal.fire({
              title: "Error",
              text: "No Data Found",
              icon: "error",
            })
          }
        } else {
          Swal.fire({
            title: "Error",
            text: data?.data?.ResponseMessage,
            icon: "error",
          })
          return
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      return []
    }
  }
console.log('paymentList',paymentList);
  const submitPayment = async (e) => {
    e.preventDefault()
    console.log('handleSubmit paymentList',paymentList);
    if (formFields.PaymentListStatusId > 0) {
      Swal.fire({
        customClass: {
          container: "my-swal",
        },
        text: "Are you sure to submit the record?",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: `Cancel`,
        cancelButtonColor: "#2f4050",
        confirmButtonText: `Confirm`,
        confirmButtonColor: "#bf1e2e",
      }).then((result) => {
        if (result.isConfirmed) {
          SaveGetStatus_().then((data) => {
            if (data?.data?.Response === true) {
              if (data?.data?.DataSet?.Table?.length > 0) {
                if (data?.data?.DataSet?.Table[0]?.HasError > 0) {
                  Swal.fire({
                    title: "Error",
                    text: data?.data?.DataSet?.Table[0]?.Error_Message,
                    icon: "error",
                  })
                  return
                } else {
                  Swal.fire({
                    title: "Success",
                    text: data?.data?.DataSet?.Table[0]?.Message,
                    icon: "success",
                  })
                  history.push("/admin/PaymentMaster")
                }
              }
            }
          })
        }
      })
    } else {
      Swal.fire({
        title: "Error",
        text: "Please Select Payment List Status",
        icon: "error",
      })
    }
  }

  const SaveGetStatus_ = async () => {
    //;
    try {
      var arr = []
      paymentList.forEach(function (obj) {
        if (obj.IsChecked) {
          arr.push({
            ApplicantCaseId: obj.ApplicantCaseId,
            PaymentSchedule_Date: obj.PaymentSchedule_Date,
            PayableAmount: obj.Case_Current_InstallmentAmount,
            Remarks: "",
            FundCategoryId: obj.FundCategoryId,
            FundSubCategoryId: obj.FundSubCategoryId,
            PaymentTypeid: obj.PaymentTypeId,
            PaymentScheduleId: obj.PaymentScheduleId,
            BalanceAmount: obj.CaseTotalAmountBalance,
          })
        }
      })

      if (arr.length > 0) {
        // 0 2
        // <0 3
        // if (formFields.PaymentListStatusId > 0) {

        return await api.postRecord(`Payment`, `Payment_List_Table`, {
          ...formFields,
          OperationId: id == 0 ? 2 : 3,
          ArrayPaymentList: arr,
        })

        // } else {
        //   Swal.fire({
        //     title: "Error",
        //     text: "Please Select Payment List Status",
        //     icon: "error",
        //   });
        // }
      } else {
        Swal.fire({
          title: "Error",
          text: "No Payment Found",
          icon: "error",
        })
      }
    } catch (error) {
      return []
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    let _values = e.target.value
    setFormFields({
      ...formFields,
      [name]: _values,
    })
  }
  const handleInputChange = (e) => {
    let { name, value } = e.target

    setFormFields({
      ...formFields,
      [name]: value,
    })
  }

  const handleInputChange_Fundcat = (e) => {
    let { name, value } = e.target

    setFormFields({
      ...formFields,
      [name]: value,
    })

    GetFundSubCategory(e.target.value)
  }

  const PaymentStatus = async () => {
    try {
      var data = await GetSetupMaster(SetupMasterIds.PaymentList, 0, "", 0)
      console.log('PaymentStatus',data);
      if (data != null) {
        if (data.response === true && data.data != null) {
          setStatusList(data.data)
          return data
        } else {
          return []
        }
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }

  useEffect(() => {
    GetCategory()
    GetPayment()
    PaymentStatus(); // add void
    if (id > 0) {
      generatePayment(null, 6)
    }
    // return ()=>{  // new added
    //   localStorage.removeItem('PaymentListStatusId')  
    // }
  }, [])

  // useEffect(()=>{
    
  //   const handleVisibilityChange = () => {
  //     let refetch = localStorage.getItem('refetch')
  //     if(document.visibilityState == 'visible' && refetch){
  //       generatePayment(null, 6)
  //     }
  //   };

  //   // Add a listener to detect when the tab's visibility changes
  //   document.addEventListener('visibilitychange', handleVisibilityChange);

  //   // Cleanup: Remove the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // },[])


  // useEffect(()=>{
  //   localStorage.removeItem('refetch')
  // },[window.location.pathname]);

  const handleSubmit = (e) => {
    e.preventDefault()
    submitPayment(e)
  }

  const handleCheckedValue = (e, index) => {
    paymentList[index][e.target.name] = e.target.value

    setPaymentList([...paymentList])
  }

  const AllDateSet = (event, type) => {
    if (type === "CreatedDateFrom") {
      setFormFields({
        ...formFields,
        CreatedDateFrom: event,
      })
    } else if (type === "CreatedDateTo") {
      setFormFields({
        ...formFields,
        CreatedDateTo: event,
      })
    } else if (type === "FromApprovalDate") {
      setFormFields({
        ...formFields,
        FromApprovalDate: event,
        ToApprovalDate: event,
      })
    } else if (type === "ToApprovalDate") {
      setFormFields({
        ...formFields,
        ToApprovalDate: event,
      })
    }
  }

  if (
    id == 0 &&
    parseInt(RoleId) !== parseInt(Roles.Accounts) &&
    parseInt(RoleId) !== parseInt(Roles.HOD)
  ) {
    return <UnAuthorized />
  }


  return (
    // @ApplicantCaseCode  	as  nvarchar(max)	= '',

    // @Cnic                   as  nvarchar(max)	= '',
    // @FundCategoryId			as int  = 0,

    <div className="content">
      <Row>
        <Col lg={12} md={12}>
          <Card className="card-user">
            <CardHeader>
              <Row>
                <Col lg={6} md={6}>
                  Payment
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <form>
                <Row>
                  <Col md={3}>
                    <label>Date From</label>

                    <DatePicker
                      value={getDate(formFields.CreatedDateFrom, "/")}
                      dateFormat={dateFormat}
                      onChange={(e) => AllDateSet(e, "CreatedDateFrom")}
                      className="form-control"
                      name="CreatedDateFrom"
                      placeholderText={dateFormatPlaceholder}
                      disabled={id == 0 ? false : true}
                      required={true}
                      showYearDropdown
                    />
                  </Col>

                  <Col md={3}>
                    <label>Date To</label>
                    <DatePicker
                      value={getDate(formFields.CreatedDateTo, "/")}
                      dateFormat={dateFormat}
                      onChange={(e) => AllDateSet(e, "CreatedDateTo")}
                      className="form-control"
                      name="CreatedDateTo"
                      placeholderText={dateFormatPlaceholder}
                      disabled={id == 0 ? false : true}
                      required={true}
                      showYearDropdown
                    />
                  </Col>
                  <Col md={3}>
                    <label>From Approval Date</label>

                    <DatePicker
                      value={getDate(formFields.FromApprovalDate, "/")}
                      dateFormat={dateFormat}
                      onChange={(e) => AllDateSet(e, "FromApprovalDate")}
                      className="form-control"
                      name="FromApprovalDate"
                      placeholderText={dateFormatPlaceholder}
                      disabled={id == 0 ? false : true}
                      required={true}
                      showYearDropdown
                    />
                  </Col>
                  <Col md={3}>
                    <label>To Approval Date</label>

                    <DatePicker
                      value={getDate(formFields.ToApprovalDate, "/")}
                      dateFormat={dateFormat}
                      onChange={(e) => AllDateSet(e, "ToApprovalDate")}
                      className="form-control"
                      name="ToApprovalDate"
                      placeholderText={dateFormatPlaceholder}
                      disabled={id == 0 ? false : true}
                      required={true}
                      showYearDropdown
                    />
                  </Col>
                  {id == 0 ? (
                    <>
                      <Col md={3}>
                        <label>Applicant Case Code</label>
                        <Input
                          name="ApplicantCaseCode"
                          value={formFields.ApplicantCaseCode}
                          onChange={handleInputChange}
                          disabled={id == 0 ? false : true}
                        />
                      </Col>
                      <Col md={3}>
                        <label>Applicant CNIC</label>
                        <Input
                          name="ApplicantCNIC"
                          value={formFields.ApplicantCNIC}
                          onChange={handleInputChange}
                          disabled={id == 0 ? false : true}
                        />
                      </Col>
                      <Col md={3}>
                        <FormGroupSelect
                          label="Fund Category"
                          name="PrimaryFundCategoryId"
                          value={formFields.PrimaryFundCategoryId}
                          onChange={handleInputChange_Fundcat}
                          list={categoryDDL}
                          fieldId="SetupDetailId"
                          fieldName="SetupDetailName"
                          disabled={id == 0 ? false : true}
                        />
                      </Col>
                      <Col md={3}>
                        <FormGroupSelect
                          label="Fund Sub Category"
                          name="FundSubCategoryId"
                          value={formFields.FundSubCategoryId}
                          onChange={handleInputChange}
                          list={FundSubCatDDL}
                          fieldId="SetupDetailId"
                          fieldName="SetupDetailName"
                          disabled={id == 0 ? false : true}
                        />
                      </Col>
                      <Col md={3}>
                        <FormGroupSelect
                          label="Payment Type"
                          name="PaymentType"
                          value={formFields.PaymentType}
                          onChange={handleInputChange}
                          list={paymentDDL}
                          fieldId="SetupDetailId"
                          fieldName="SetupDetailName"
                          disabled={id == 0 ? false : true}
                        />
                      </Col>
                    </>
                  ) : null}
                </Row>
                {id == 0 ? (
                  <Row className="float-right mr-3">
                    <FormGroupButton
                      title="Generate"
                      onClick={(e) => generatePayment(e, 5)}
                      type="button"
                    />
                  </Row>
                ) : null}
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12}>
          <Card className="card-user">
            <CardHeader>
              <Row>
                <Col lg={6} md={6}>
                  Payment Summary
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "60px",
                  }}
                >
                  <Spinner
                    style={{
                      width: "10rem",
                      height: "10rem",
                    }}
                    color="danger"
                  />
                </div>
              ) : (
                <Row>
                  <FormGroupTable
                    columns={columnsSummary}
                    rows={paymentListSummary}
                    hideAction={true}
                  />
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg={6} md={6}>
                  Payment List
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                {console.log('paymentList',paymentList)}
                <Col>
                  <DataTableExtensions
                    data={paymentList}
                    columns={DataTableColumns}
                    exportHeaders={true}
                    fileName="Payment List"
                  >
                    <DataTable
                      dense
                      direction="auto"
                      defaultSortField="StudentName"
                      fixedHeader
                      striped
                      defaultSortAsc={false}
                      pagination
                      highlightOnHover
                      expandOnRowClicked
                      progressPending={loading}
                      fixedHeaderScrollHeight="auto"
                      subHeaderAlign="right"
                      subHeaderWrap
                      customStyles={DataTableCustomStyles}
                      expandOnRowDoubleClicked={true}
                    />
                  </DataTableExtensions>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg={6} md={6}>
                  Payment History
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {id > 0 ? (
                <Row>
                  <FormGroupTable
                    columns={columnsHistory}
                    rows={paymentHistory}
                    hideAction={true}
                  />
                </Row>
              ) : null}

              {statusList.length > 0 ? (
                <>
                  <Row>
                    <Col md="2">
                      <FormGroupSelect
                        label="Payment List Status"
                        required={true}
                        list={statusList}
                        value={formFields.PaymentListStatusId}
                        name="PaymentListStatusId"
                        onChange={handleChange}
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                      />
                    </Col>
                    <Col md="10">
                      <label>Remarks</label>
                      <Input
                        name="Remarks"
                        onChange={(e) =>
                          handleChange({
                            target: {
                              name: e.target.name,
                              value: e.target.value,
                            },
                          })
                        }
                        label="Remarks"
                        required={true}
                        value={formFields.Remarks}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-right">
                      <FormGroupButton
                        onClick={handleSubmit}
                        color="primary2"
                        title="Submit"
                      />
                    </Col>
                  </Row>{" "}
                </>
              ) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default PaymentListing
