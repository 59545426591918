import React, { useState, useEffect } from "react"
import {
  Col,
  Container,
  Nav,
  Row,
  Card,
  Progress,
  Spinner,
  CardFooter,
} from "reactstrap"
import { Link } from "react-router-dom"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import {
  FastForex_FetchOnlyOne,
  ConvertNumricToComaSeparate,
  objCurrrency,
  Get_All_FeaturedNGOs,
  NGOController,
  Case_Of_The_Day,
} from "utils/CommonMethods"
import { baseImageUrl } from "utils/Api"
import CenteredLoader from "components/GeneralComponent/CenteredLoader"
import { DefaultImgPath, SetupMasterIds } from "utils/Constants"
import FeaturedNGOsCard from "components/FeaturedNGOsCard"
import { Img } from "react-image"
const Categories = (props) => {
  const [loading, setLoading] = useState(false)
  const [allCasesddl, setAllCasesddl] = useState([])
  const [allCards, setAllCards] = useState([])
  const [selectedcurrencyValues, setselectedcurrencyValues] =
    useState(objCurrrency)
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 501 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  }

 

  useEffect(() => {

    const load = async () => {
      let _SessionData = JSON.parse(
        sessionStorage.getItem("globalSelectedCurrency")
      )
      let _CurrencyData = await FastForex_FetchOnlyOne(
        _SessionData.Flex1,
        "PKR"
      )

      if (_CurrencyData.Response) {
        let _parseData = JSON.parse(_CurrencyData.Data)
        selectedcurrencyValues.ConversionRate = _parseData.result.PKR
        selectedcurrencyValues.CurrencyFromSymbol = _parseData.base

        setselectedcurrencyValues({ ...selectedcurrencyValues })
      } else {
      }
    }
    load()
  }, [])


  const arr = ["Education","Food","Healthcare","Disaster Relief","Water","Widows & Orphans"];
  const ids = [192,324,285,1531,1561,1562];
  return (
    <section className="section section-meals pt-4 pb-4">
      <Container>
        <Row>
          <Col>
            <h2 className="meals-title mb-4">
              <span className="title-bg">How You Can Make a Difference</span>
            </h2>
          </Col>
        </Row>
        <div className="IndicatorCarousel" style={{padding:'0px 20px 0px 20px'}}>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={7500}
            shouldResetAutoplay={false}
            keyBoardControl={true}
            autoPlayInterval={7500}
            interval={7500}
            transitionDuration={500}
            containerClass="carousel-container row"
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            removeArrowOnDeviceType={["tablet"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px  "
            pauseOnHover={true}
          >
            {props?.categories &&
              ids.map(id => props?.categories?.find(d => d?.SetupDetailId == id)).map((items, i) => {
                // props?.categories?.map((items, i) => {
                return (
                  <Card body key={i} style={{background:'#EDF2FA'}}>
                  <div className="donation-box text-center">
                    <div className="" style={{ minHeight: "280px" }}>
                      <div className="dimg-box">
                        <Link
                         to={{
                          pathname:`/categories/${items?.SetupDetailId}`,
                          state: {...items,SetupDetailName:arr[i],Flex2:items?.Flex2},
                        }}
                        rel="noreferrer"
                        >
                        <Img
                          // src={[
                          //   items?.DocAttachment
                          //     ? baseImageUrl + items?.DocAttachment
                          //     : baseImageUrl + items?.AppealImages,
                          //   DefaultImgPath,
                          // ]}
                          src={[
                            items?.Flex1
                              ? baseImageUrl + items?.Flex1
                              : baseImageUrl + items?.AppealImages,
                            DefaultImgPath,
                          ]}
                          
                          loader={<CenteredLoader />}
                          alt=""
                        />
                        </Link>
                      </div>

                      <h3 className="text-center mt-2 mb-2 card-tile-heading one-line-heading ">
                        {/* {items?.CaseTitle
                          ? items?.CaseTitle
                          : items?.SetupDetailName}{" "} */}
                          {arr[i]}
                      </h3>
                      <p className="text-center qurbari-booking mb-0 two-lines-paragraph">
                        {/* {items?.ShortDesc ? items?.ShortDesc : items?.tagline} */}
                        {items?.Flex2 ? items?.Flex2 : ""}
                        {/* <br /> */}
                        {/* {data?.ShortDesc.substring(0, 66) + ".."} */}
                      </p>
                    </div>
                    <CardFooter>
                      <Link
                        to={{
                          pathname:`/categories/${items?.SetupDetailId}`,
                          state: {...items,SetupDetailName:arr[i],Flex3:items?.Flex3},
                        }}
                        className="btn btn-primary w-50"
                        rel="noreferrer"
                        style={{background:'#062150'}}
                      >
                        Donate Now
                      </Link>
                    </CardFooter>
                  </div>
                </Card>
                )
              })}
          </Carousel>
        </div>
      </Container>
    </section>
  )
}

export default Categories
