import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Row,
  Col,
  Label,
} from "reactstrap";
//import {header} from "reactstrap";
import "../../assets/css/homestyles.css";

import hsimagel from "../../assets/img/SS FINAL LOGO 2.png";

import {
  Get_Active_Reserved_Cases,
  SaveSingleDataToLocalStorage,
  GetLocalStorageValue,
  GetSetupMaster,
  NGOController,
} from "utils/CommonMethods";
import Swal from "sweetalert2";
import TawkTo from "tawkto-react";
import { useLocation } from "react-router-dom";
import Login from "views/Login";
import { fetchData } from "utils/Api";
//import DemoNavbar from "../../components/Navbars/DemoNavbar.js";
import DemoNavbar from "../../components/Navbars/DemoNavbar.js";
import Sidebar from "../../components/Sidebar/Sidebar";
import routes from "routes";
import DemoNavbar_Home from "components/Navbars/DemoNavbar_Home";
import { SetupMasterIds } from "utils/Constants";
import Select from "react-select";

const HomeHeader = (props) => {
  var username = localStorage.getItem("Name");
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    document.scrollingElement.scrollTop = 0;

    const load = async () => {
      await GetCurrency();
    };
    load();
  }, [location]);
  const [isOpen, setIsOpen] = useState(false);
  const [currencyddl, setCurrencyddl] = useState([]);
  const [globalCurrency, setglobalCurrency] = useState(null);
  const [ngos, setNgos] = useState([]);
  const [globalCurrencyISOCode, setglobalCurrencyISOCode] = useState("");
  const [caseCount, setCaseCount] = useState([]);
  const [caseCountry, setCaseCountry] = useState([]);

  // add new changes according to sabsaath 
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 767px)").matches
  );
  const propertyId = "625d4033b0d10b6f3e6e14ce";
  const tawkId = "1g0u45j65";
  useEffect(() => {
    if (
      localStorage.getItem("RoleId") != null && // Means if user is logged in
      localStorage.getItem("RoleId") != 37 // And if user is not a guest user
    ) {
      window.localStorage.clear(); //clear all localstorage

      window.sessionStorage.clear();
      const load = async () => {
        await GetCurrency();
      };
      load();
      history.push("/Login");
    }
    // GetCasesCount()
    GetFeaturedNGOsDetails();
  }, []);

  const handleInputChange = (event) => {
    // if(event.target.value > 0){
    setglobalCurrency(event);

    sessionStorage.setItem("globalSelectedCurrency", JSON.stringify(event));
    global.sessionUpdate = !global.sessionUpdate;

    window.location.reload();
  };
  // add new changes according to sabsaath 
  useEffect(() => {
    window
      .matchMedia("(max-width: 767px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, [matches]);

  const GetCasesCount = async () => {
    try {
      var data = await Get_Active_Reserved_Cases(0, 0);
      if (data != null) {
        setCaseCount({ ...data.DataSet.Table1[0] });
        setCaseCountry(data.DataSet.Table2);
        return data;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  const GetFeaturedNGOsDetails = async () => {
    try {
      var data = await NGOController(
        0,
        4,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );
      if (data) {
        setNgos(data.Table);
        if (props?.pickNGOsData) {
          props.pickNGOsData(data);
        }
        return data;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  // const latest = {
  //   currencies: {
  //     AED: "United Arab Emirates Dirham",
  //     AFN: "Afghan Afghani",
  //     ALL: "Albanian Lek",
  //     AMD: "Armenian Dram",
  //     ANG: "Dutch Guilders",
  //     AOA: "Angolan Kwanza",
  //     ARS: "Argentine Peso",
  //     AUD: "Australian Dollar",
  //     AWG: "Aruban Florin",
  //     AZN: "Azerbaijani Manat",
  //     BAM: "Bosnia-Herzegovina Convertible Mark",
  //     BBD: "Barbadian Dollar",
  //     BDT: "Bangladeshi Taka",
  //     BGN: "Bulgarian Lev",
  //     BHD: "Bahraini Dinar",
  //     BIF: "Burundian Franc",
  //     BMD: "Bermudian Dollar",
  //     BND: "Bruneian Dollar",
  //     BOB: "Bolivian Boliviano",
  //     BRL: "Brazilian Real",
  //     BSD: "Bahamian Dollar",
  //     BTN: "Bhutanese Ngultrum",
  //     BWP: "Botswanan Pula",
  //     BZD: "Belizean Dollar",
  //     CAD: "Canadian Dollar",
  //     CDF: "Congolese Franc",
  //     CHF: "Swiss Franc",
  //     CLF: "Chilean Unit of Account UF",
  //     CLP: "Chilean Peso",
  //     CNH: "Chinese Yuan Offshore",
  //     CNY: "Chinese Yuan",
  //     COP: "Colombian Peso",
  //     CUP: "Cuban Peso",
  //     CVE: "Cape Verdean Escudo",
  //     CZK: "Czech Republic Koruna",
  //     DJF: "Djiboutian Franc",
  //     DKK: "Danish Krone",
  //     DOP: "Dominican Peso",
  //     DZD: "Algerian Dinar",
  //     EGP: "Egyptian Pound",
  //     ERN: "Eritrean Nakfa",
  //     ETB: "Ethiopian Birr",
  //     EUR: "Euro",
  //     FJD: "Fijian Dollar",
  //     FKP: "Falkland Islands Pound",
  //     GBP: "British Pound Sterling",
  //     GEL: "Georgian Lari",
  //     GHS: "Ghanaian Cedi",
  //     GIP: "Gibraltar Pound",
  //     GMD: "Gambian Dalasi",
  //     GNF: "Guinean Franc",
  //     GTQ: "Guatemalan Quetzal",
  //     GYD: "Guyanaese Dollar",
  //     HKD: "Hong Kong Dollar",
  //     HNL: "Honduran Lempira",
  //     HRK: "Croatian Kuna",
  //     HTG: "Haitian Gourde",
  //     HUF: "Hungarian Forint",
  //     IDR: "Indonesian Rupiah",
  //     ILS: "Israeli New Sheqel",
  //     INR: "Indian Rupee",
  //     IQD: "Iraqi Dinar",
  //     IRR: "Iranian Rial",
  //     ISK: "Icelandic Krona",
  //     JMD: "Jamaican Dollar",
  //     JOD: "Jordanian Dinar",
  //     JPY: "Japanese Yen",
  //     KES: "Kenyan Shilling",
  //     KGS: "Kyrgystani Som",
  //     KHR: "Cambodian Riel",
  //     KMF: "Comorian Franc",
  //     KPW: "North Korean Won",
  //     KRW: "South Korean Won",
  //     KWD: "Kuwaiti Dinar",
  //     KYD: "Caymanian Dollar",
  //     KZT: "Kazakhstani Tenge",
  //     LAK: "Laotian Kip",
  //     LBP: "Lebanese Pound",
  //     LKR: "Sri Lankan Rupee",
  //     LRD: "Liberian Dollar",
  //     LSL: "Basotho Maloti",
  //     LYD: "Libyan Dinar",
  //     MAD: "Moroccan Dirham",
  //     MDL: "Moldovan Leu",
  //     MGA: "Malagasy Ariary",
  //     MKD: "Macedonian Denar",
  //     MMK: "Myanma Kyat",
  //     MNT: "Mongolian Tugrik",
  //     MOP: "Macanese Pataca",
  //     MRU: "Mauritanian Ouguiya",
  //     MUR: "Mauritian Rupee",
  //     MVR: "Maldivian Rufiyaa",
  //     MWK: "Malawian Kwacha",
  //     MXN: "Mexican Peso",
  //     MYR: "Malaysian Ringgit",
  //     MZN: "Mozambican Metical",
  //     NAD: "Namibian Dollar",
  //     NGN: "Nigerian Naira",
  //     NOK: "Norwegian Krone",
  //     NPR: "Nepalese Rupee",
  //     NZD: "New Zealand Dollar",
  //     OMR: "Omani Rial",
  //     PAB: "Panamanian Balboa",
  //     PEN: "Peruvian Nuevo Sol",
  //     PGK: "Papua New Guinean Kina",
  //     PHP: "Philippine Peso",
  //     PKR: "Pakistani Rupee",
  //     PLN: "Polish Zloty",
  //     PYG: "Paraguayan Guarani",
  //     QAR: "Qatari Rial",
  //     RON: "Romanian Leu",
  //     RSD: "Serbian Dinar",
  //     RUB: "Russian Ruble",
  //     RWF: "Rwandan Franc",
  //     SAR: "Saudi Arabian Riyal",
  //     SCR: "Seychellois Rupee",
  //     SDG: "Sudanese Pound",
  //     SEK: "Swedish Krona",
  //     SGD: "Singapore Dollar",
  //     SHP: "Saint Helena Pound",
  //     SLL: "Sierra Leonean Leone",
  //     SOS: "Somali Shilling",
  //     SRD: "Surinamese Dollar",
  //     SYP: "Syrian Pound",
  //     SZL: "Swazi Emalangeni",
  //     THB: "Thai Baht",
  //     TJS: "Tajikistani Somoni",
  //     TMT: "Turkmenistani Manat",
  //     TND: "Tunisian Dinar",
  //     TOP: "Tongan Pa'anga",
  //     TRY: "Turkish Lira",
  //     TTD: "Trinidad and Tobago Dollar",
  //     TWD: "Taiwan New Dollar",
  //     TZS: "Tanzanian Shilling",
  //     UAH: "Ukrainian Hryvnia",
  //     UGX: "Ugandan Shilling",
  //     USD: "United States Dollar",
  //     UYU: "Uruguayan Peso",
  //     UZS: "Uzbekistan Som",
  //     VND: "Vietnamese Dong",
  //     VUV: "Ni-Vanuatu Vatu",
  //     WST: "Samoan Tala",
  //     XAF: "CFA Franc BEAC",
  //     XCD: "East Caribbean Dollar",
  //     XDR: "Special Drawing Rights",
  //     XOF: "CFA Franc BCEAO",
  //     XPF: "CFP Franc",
  //     YER: "Yemeni Rial",
  //     ZAR: "South African Rand",
  //     ZMW: "Zambian Kwacha",
  //   },
  //   ms: 1,
  // };

  // const arr2 = Object.keys(latest.currencies);
  // const arr1 = currencyddl;
  // const remain = arr2.filter((x) => arr1.find(y=> x == y.Flex1));
  // const arr3 = [ 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BZD', 'CAD', 'CDF', 'CHF', 'CLF', 'CLP', 'CNH', 'CNY', 'COP', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'EUR', 'FJD', 'FKP', 'GBP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HRK', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRU', 'MUR', 'MVR', 'MWK', 'MXN', 'MYR', 'MZN', 'NAD', 'NGN', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL', 'SOS', 'SRD', 'SYP', 'SZL', 'THB', 'TJS', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'USD', 'UYU', 'UZS', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XDR', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMW' ];
  // console.log("remain", remain);
  // console.log("remain lenght from 150", remain.length);
  // console.log("currencies", arr2.length);
  // console.log('new length', arr3.length);

  const GetCurrency = async () => {
    try {
      var data = await GetSetupMaster(SetupMasterIds.Currency, 0, "", 0);
      if (data != null) {
        if (data.response === true && data.data != null) {
          var sortings = data.data.sort(function (a, b) {
            var keyA = a.Flex2;
            var keyB = b.Flex2;
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          });

          setCurrencyddl(sortings);

          if (
            sessionStorage.getItem("globalSelectedCurrency") === undefined ||
            sessionStorage.getItem("globalSelectedCurrency") === null
          ) {
            global.sessionUpdate = true;

            let selectedCurrencyAccordingRegionIp = sortings.filter(
              (x) =>
                x.Flex1 == sessionStorage.getItem("geoLocationcurrencyCode") ||
                "PKR"
            );
            if (selectedCurrencyAccordingRegionIp.length > 0) {
              sessionStorage.setItem(
                "globalSelectedCurrency",
                JSON.stringify(selectedCurrencyAccordingRegionIp[0])
              );
              setglobalCurrency(
                JSON.parse(sessionStorage.getItem("globalSelectedCurrency"))
              );
            }
          } else {
            setglobalCurrency(
              JSON.parse(sessionStorage.getItem("globalSelectedCurrency"))
            );
          }

          return data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };
  function toggle() {
    setIsOpen(!isOpen);
  }

  async function funLogOut(e) {
    e.preventDefault();
    var data = {
      OperationTypeId: 6,
      UserId: localStorage.getItem("UserId"),
    };
    var result = await fetchData("User", "LogOut", data);

    if (result?.Response === true) {
      if (result?.Data[0]?.HasError === 0) {
        window.localStorage.clear(); //clear all localstorage
        history.push("/Login");
      }
    } else {
      Swal.fire({
        title: "Error",
        text: result.data[0].MESSAGE,
        icon: "error",
      });
    }
  }
  const [backgroundColor, setBackgroundColor] = React.useState("black");
  const [activeColor, setActiveColor] = React.useState("info");

  return (
    <>
      <div
        className="top-bar"
        onClick={() => {
          props.toggleCurrencySelect("close");
        }}
      >
        <div className="container">
          <Row>
            <Col md={6}>
              <ul className="topbar-list">
                <li>
                  <span>
                    <i className="fa fa-phone"> </i> <a>Tel: 042-111-222-500</a>
                  </span>
                </li>
                <li>
                  <span>
                    <i className="fa fa-envelope"> </i>{" "}
                    <a href="mailto:info@sabsaath.org">info@sabsaath.org</a>
                  </span>
                </li>
              </ul>
            </Col>

            <Col md={6}>
              <ul className="top-social-icons">
                {/* <li className="social-title">Stay informed: </li> */}
                <li>
                  <a
                    href="https://www.facebook.com/SabSaath.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://instagram.com/sabsaathpk?utm_medium=copy_link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                {/* <li>
                  <a
                    href="https://www.linkedin.com/company/zamanfoundation/ "
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li> */}
              </ul>
            </Col>
          </Row>
        </div>
      </div>
      <Container>
        <Navbar
          expand="md"
          className="navbar-custom p-0"
          onClick={() => {
            props.toggleCurrencySelect && props.toggleCurrencySelect("close");
          }}
        >
          {/* <Container> */}
          <Link to="/home">
            <img
              className="navbar-brand"
              height={90}
              src={hsimagel}
              alt="SabSaath"
            />
          </Link>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <ul className="nav main-menu" 
            // add new changes according to sabsaath
            style={{justifyContent: matches && 'center'  }}>
              <li className="nav-item" >
                <Link className="nav-link" to="/home">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Nav className="ml-auto">
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      About Us
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        href="/about-us#who-we-are"
                      >
                        Who We Are
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        href="/about-us#how-we-started"
                      >
                        How We Started
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        href="/about-us#vision-mission"
                      >
                        Our Vision & Values
                      </DropdownItem>
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        href="/about-us#why-trust"
                      >
                        Why Trust Sab Saath?
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        href="/about-us#our-partner"
                      >
                        Our Partners
                      </DropdownItem>
                      {/* <DropdownItem divider />
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        href="/about-us#our-values"
                      >
                        Our Values
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        href="/about-us#what-we-do"
                      >
                        What We Do
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        href="/about-us#how-we-work"
                      >
                        How We Work
                      </DropdownItem>

                      <DropdownItem divider />

                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        href="/about-us#what-makes-us-unique"
                      >
                        What Makes us Unique
                      </DropdownItem>
                      <DropdownItem divider />

                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        href="/about-us#our-partner"
                      >
                        Our Partners
                      </DropdownItem> */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </li>
              {/* <li className="nav-item">
                <Nav className="ml-auto">
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Impact
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        tag={Link}
                        to="/flood-impact"
                      >
                        Pakistan Floods Relief
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        tag={Link}
                        to="/covid-impact"
                      >
                        Covid-19
                      </DropdownItem>
                      <DropdownItem divider />
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </li> */}
              <li className="nav-item">
                <Nav className="ml-auto">
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Donate
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        tag={Link}
                        to={
                          "/subscription-quick-donate?id=314&name=General%20Donation"
                        }
                      >
                        Quick Donate
                      </DropdownItem>
                      <DropdownItem divider />
                      {/* {ngos &&
                        ngos.length > 0 &&
                        // Rearrange the order of the NGOs and put Zaman Foundation at the top
                        ngos
                          .sort((a, b) => {
                            if (
                              a.NGOFeatureID ===
                                SetupMasterIds.ZamanFoundation ||
                              a.NGOFeatureID ===
                                SetupMasterIds.ZamanFoundationNGO
                            ) {
                              return -1;
                            }
                            if (
                              b.NGOFeatureID ===
                                SetupMasterIds.ZamanFoundation ||
                              b.NGOFeatureID ===
                                SetupMasterIds.ZamanFoundationNGO
                            ) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((ngo, index) => {
                            return (
                              <div key={index}>
                                <DropdownItem
                                  type="button"
                                  className="dropdown-item"
                                  tag={Link}
                                  to={
                                    ngo?.NGOFeatureID ===
                                      SetupMasterIds.ZamanFoundation ||
                                    ngo?.NGOFeatureID ===
                                      SetupMasterIds.ZamanFoundationNGO
                                      ? "/ramazancampaign"
                                      : `/cases-list/${ngo.NGOFeatureID}`
                                  }
                                >
                                  {ngo?.NGOFeatureID ===
                                    SetupMasterIds.ZamanFoundation ||
                                  ngo?.NGOFeatureID ===
                                    SetupMasterIds.ZamanFoundationNGO
                                    ? ngo.Heading
                                    : ngo.NGOInitials}
                                </DropdownItem>
                                <DropdownItem divider />
                              </div>
                            );
                          })} */}
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        tag={Link}
                        // to="/categories/192"
                        to={{
                          pathname: "/categories/192",
                          state: { SetupDetailName: "Education" },
                        }}
                      >
                        Education
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        tag={Link}
                        // to="/categories/285"
                        to={{
                          pathname: "/categories/285",
                          state: { SetupDetailName: "Healthcare" },
                        }}
                      >
                        Healthcare
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        tag={Link}
                        // to="/categories/324"
                        to={{
                          pathname: "/categories/324",
                          state: { SetupDetailName: "Food" },
                        }}
                      >
                        Food
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        tag={Link}
                        // to="/categories/1561"
                        to={{
                          pathname: "/categories/1561",
                          state: { SetupDetailName: "Water" },
                        }}
                      >
                        Water
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        tag={Link}
                        // to="/categories/1562"
                        to={{
                          pathname: "/categories/1562",
                          state: { SetupDetailName: "Widows & Orphans" },
                        }}
                      >
                        Widows & Orphans
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        tag={Link}
                        // to="/categories/1531"
                        to={{
                          pathname: "/categories/1531",
                          state: { SetupDetailName: "Disaster Relief" },
                        }}
                      >
                        Disaster Relief
                      </DropdownItem>
                      <DropdownItem divider />

                      <DropdownItem
                        type="button"
                        className="dropdown-item"
                        tag={Link}
                        to="/home#services&fundraiser"
                      >
                        Services & Fundraisers
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </li>
              {/* <li className="nav-item">
                <Nav className="ml-auto">
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Donate
                    </DropdownToggle>
                    <div className="menu-drop">
                      <Row>
                        <Col>
                          <DropdownMenu right className="dropdown-custom">
                            <div className="child-menu">
                              <div className="inner-dropdown">
                                <DropdownItem>
                                  <Link
                                    className="dropdown-link"
                                    to="/cases-list"
                                    style={{
                                      // padding: "0px",
                                      color: "#fff !important",
                                    }}
                                  >
                                    ZF Cases
                                  </Link>
                                </DropdownItem>
                                <span>
                                  (
                                  {caseCount && caseCount.AllCases !== null
                                    ? caseCount.AllCases
                                    : "0"}
                                  )
                                </span>
                              </div>
                              <div className="inner-dropdown pl-4">
                                <DropdownItem>
                                  <a href="/cases-list#health">Health</a>
                                </DropdownItem>
                                <span>
                                  (
                                  {caseCount && caseCount.Medical !== null
                                    ? caseCount.Medical
                                    : "0"}
                                  )
                                </span>
                              </div>
                              <div className="inner-dropdown pl-4">
                                <DropdownItem>
                                  <a href="/cases-list#education">Education</a>
                                </DropdownItem>
                                <span>
                                  (
                                  {caseCount && caseCount.Education !== null
                                    ? caseCount.Education
                                    : "0"}
                                  )
                                </span>
                              </div>
                              <div className="inner-dropdown pl-4 ">
                                <DropdownItem>
                                  <a href="/cases-list#relief">Relief</a>
                                </DropdownItem>
                                <span>
                                  (
                                  {caseCount && caseCount.Relief !== null
                                    ? caseCount.Relief
                                    : "0"}
                                  )
                                </span>
                              </div>
                              <div className="inner-dropdown pl-4 ">
                                <DropdownItem>
                                  <a href="/cases-list#meal">Meal</a>
                                </DropdownItem>
                                <span>
                                  (
                                  {caseCount && caseCount.Meal !== null
                                    ? caseCount.Meal
                                    : "0"}
                                  )
                                </span>
                              </div>
                            </div>
                            <DropdownItem divider />
                            <DropdownItem
                              style={{ color: "#d60b11", fontWeight: "600" }}
                            >
                              Donations
                            </DropdownItem>
                          </DropdownMenu>
                        </Col>
                        <Col>
                          <DropdownMenu right className="second-menu">
                            <DropdownItem
                              style={{ color: "#d60b11", fontWeight: "600" }}
                            >
                              {caseCountry[0] && caseCountry[0].Country != ""
                                ? caseCountry[0].Country
                                : "Pakistan"}
                            </DropdownItem>
                            <DropdownItem divider />
                            {caseCountry &&
                              caseCountry
                                .filter((data) => data.Country === "Pakistan")
                                .map((data, key) => (
                                  <div className="child-menu" key={key}>
                                    <div className="inner-dropdown">
                                      <DropdownItem>{data.City}</DropdownItem>
                                      <span>({data.Count})</span>
                                    </div>
                                  </div>
                                ))}
                          </DropdownMenu>
                        </Col>
                      </Row>
                    </div>
                  </UncontrolledDropdown>
                </Nav>
              </li> */}
              {/* <li className="nav-item">
                <Link className="nav-link" to="/volunteer">
                  Volunteer
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/testimonials">
                  Testimonials
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>

            <div className="d-flex align-items-center">
              <div className="show-mobile">
                {props.isShow && (
                  <Label
                    className="label mr-3 fw-bold currency-label-desktop"
                    style={{
                      fontSize: "16px",
                      display: props.isShow ? "block" : "none",
                      color: "#6E6E6E",
                    }}
                  >
                    Currency
                  </Label>
                )}
              </div>

              <div
                className="country-select show-mobile"
                style={{
                  width: "100px",
                  zIndex: 10,
                  display: props.isShow ? "block" : "none",
                  
                }}
              >
                {props.isShow && (
                  <Label
                    className="label mr-3 fw-bold currency-l show-mobile"
                    style={{
                      fontSize: "16px",
                      display: props.isShow ? "block" : "none",
                      color: "#6E6E6E",
                    }}
                  >
                    Currency
                  </Label>
                )}
                <div
                  className="show-mobile"
                  onClick={(e) => {
                    e.stopPropagation();
                    props.toggleCurrencySelect();
                  }}
                >
                  <Select
                    placeholder="Select Currency"
                    closeMenuOnSelect
                    id="currency-select"
                    getOptionValue={(option) => option.SetupDetailId} // changes here!!!
                    value={globalCurrency}
                    options={currencyddl}
                    onChange={handleInputChange}
                    isDisabled={props.Disableds ? props.Disableds : false}
                    getOptionLabel={(e) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          zIndex: "10",
                        }}
                      >
                        <span class={e.Flex3}></span>
                        <span style={{ marginLeft: 5 }}>{e.Flex1}</span>
                      </div>
                    )}
                  />
                </div>
              </div>
              {!props.hide && (
                <div className="ngorel show-mobile">
                  <DemoNavbar_Home />
                </div>
              )}
            </div>
          </Collapse>

          <div className="hide-mobile">
            {props.isShow && (
              <Label
                className="label mr-3 fw-bold currency-label-desktop"
                style={{
                  fontSize: "16px",
                  display: props.isShow ? "block" : "none",
                  color: "#6E6E6E",
                }}
              >
                Currency
              </Label>
            )}
          </div>

          <div
            className="country-select hide-mobile"
            style={{
              width: "100px",
              zIndex: 10,
              display: props.isShow ? "block" : "none",
            }}
          >
            {props.isShow && (
              <Label
                className="label mr-3 fw-bold currency-label-mobile"
                style={{
                  fontSize: "16px",
                  display: props.isShow ? "block" : "none",
                  color: "#6E6E6E",
                }}
              >
                Currency
              </Label>
            )}
            <div
              className="hide-mobile"
              onClick={(e) => {
                e.stopPropagation();
                props.toggleCurrencySelect();
              }}
            >
              <Select
                placeholder="Select Currency"
                closeMenuOnSelect
                id="currency-select"
                getOptionValue={(option) => option.SetupDetailId} // changes here!!!
                value={globalCurrency}
                options={currencyddl}
                onChange={handleInputChange}
                isDisabled={props.Disableds ? props.Disableds : false}
                getOptionLabel={(e) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      zIndex: "10",
                    }}
                  >
                    <span class={e.Flex3}></span>
                    <span style={{ marginLeft: 5 }}>{e.Flex1}</span>
                  </div>
                )}
              />
            </div>
          </div>
          {!props.hide && (
            <div className="ngorel hide-mobile">
              <DemoNavbar_Home />
            </div>
          )}

          {/* </Container> */}
        </Navbar>
      </Container>
    </>
  );
};
export default HomeHeader;
