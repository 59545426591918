import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Label,
  Input,
} from "reactstrap";

import { fetchData } from "utils/Api";
import {
  ApiMethods,
  ControllerName,
  DataTableCustomStyles,
  SetupMasterIds,
} from "utils/Constants";
import {
  GetSetupMaster,
  AllowAlphabatic,
  dateFormatPlaceholder,
  dateFormat,
  getDate,
  Fill_Followup,
  GetSetupMasterApplicantList,
} from "utils/CommonMethods";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import moment from "moment";
import FormGroupSelect from "components/GeneralComponent/FormGroupSelect";
import styled from "styled-components";

const Report_Applicant_Case__List = (props) => {
  const StyledTable = styled(DataTable)`
    .rdt_TableCol div div {
      overflow: visible;
      white-space: pre-wrap;
      padding-left: 20px;
    }
    .rdt_TableRow {
      padding-top: 10px;
    }
  `;
  let defaultFromDate = new Date();
  defaultFromDate.setDate(defaultFromDate.getDate() - 27);

  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());
  const searchingValues = {
    FromDate: defaultFromDate.toLocaleDateString("en-CA"),
    ToDate: defaultDate.toLocaleDateString("en-CA"),
    ApplicantCaseCode: "",
    ApplicantName: "",
    CNIC: "",
    GenderId: -1,
    CityId: -1,
    CaseNatureId: -1,
    CategoryId: -1,
    FundCategoryId: -1,
    ReferralTypeId: -1,
    ReferralName: '',
    CaseStatusId: 480,
    IsViewBlackList: -1,
    IsDeceased: -1,
    MartialStatusId: -1,
    IsZakatEligible: -1,
    IsFitranaEligible: -1,
    AreaID: -1,
    InvestigatorID: -1,
    TrusteeID: -1,
    HoDID: -1,
  };
  const resetValues = {
    FromDate: defaultFromDate.toLocaleDateString("en-CA"),
    ToDate: defaultDate.toLocaleDateString("en-CA"),
    ApplicantCaseCode: null,
    ApplicantName: null,
    CNIC: null,
    GenderId: null,
    CityId: null,
    CaseNatureId: null,
    CategoryId: null,
    FundCategoryId: null,
    ReferralTypeId: null,
    ReferralName: null,
    CaseStatusId: 480,
    IsViewBlackList: null,
    IsDeceased: null,
    MartialStatusId: null,
    IsZakatEligible: null,
    IsFitranaEligible: null,
    AreaID: null,
    InvestigatorID: null,
    TrusteeID: null,
    HoDID: null,
  };
  const [searchValues, setSearchVlues] = useState(searchingValues);
  const [reportList, setreportList] = useState([]);

  const [genderddl, setgenderddl] = useState([]);
  const [cityddl, setcityddl] = useState([]);
  const [areaddl, setareaddl] = useState([]);
  const [investigatingOfficerddl, setInvestigatingOfficerddl] = useState([]);
  const [trusteeddl, setTrusteeddl] = useState([]);
  const [HODddl, setHODddl] = useState([]);
  const [casenatureddl, setcasenatureddl] = useState([]);
  const [categoryddl, setcategoryddl] = useState([]);
  const [fundCategroyddl, setfundCategroyddl] = useState([]);
  const [referralTypeddl, setreferralTypeddl] = useState([]);
  const [caseStatusddl, setcaseStatusddl] = useState([]);
  const [finalData, setFinalData] = useState({});

  const [MartialStatusddl, setMartialStatusddl] = useState([]);
  const columns = [
    {
      name: "Date",
      selector: "Date",
      sortable: true,
    },
    {
      name: "Applicant Case Code",
      selector: "ApplicantCaseCode",
      sortable: true,
      wrap: true,
    },
    {
      name: "Applicant Name",
      selector: "ApplicantName",
      sortable: true,
      wrap: true,
    },
    {
      name: "Contact Number",
      selector: "ApplicantPrimaryContactNumber",
      sortable: true,
    },
    {
      name: "Cnic No",
      selector: "CnicNo",
      sortable: true,
      width: "120px",
    },

    {
      name: "Gender",
      selector: "Gender",
      sortable: true,
    },
    {
      name: "City Name",
      selector: "CityName",
      sortable: true,
    },
    {
      name: "Area",
      selector: "Area",
      sortable: true,
    },
    {
      name: "Nature of Case",
      selector: "CaseOfNature",
      sortable: true,
    },
    {
      name: "Category",
      selector: "Category",
      sortable: true,
      wrap: true,
    },
    {
      name: "Fund Category Name",
      selector: "FundCategoryName",
      sortable: true,
      wrap: true,
    },
    {
      name: "Frequency",
      selector: "Frequency",
      sortable: true,
      wrap: true,
    },
    {
      name: "Repetition",
      selector: "Repetition",
      sortable: true,
    },
    // {
    //   name: "Duration",
    //   selector: "Duration",
    //   sortable: true,
    // },
    {
      name: "Referral Name",
      selector: "ReferralName",
      sortable: true,
      wrap: true,
    },
    {
      name: "Refferal Type Name",
      selector: "RefferalTypeName",
      sortable: true,
      wrap: true,
    },
    {
      name: "Case Status",
      selector: "CaseStatus",
      sortable: true,
      wrap: true,
    },
    {
      name: "Fund Required",
      selector: "FundRequired",
      sortable: true,
    },
    {
      name: "Fund Approved",
      selector: "FundApproved",
      sortable: true,
    },
    {
      name: "Is Black List",
      selector: "IsBlackList",
      sortable: true,
    },
    {
      name: "Is Zakat Eligbile",
      selector: "IsZakatEligbile",
      sortable: true,
    },
    {
      name: "Is Fitrana Eligible",
      selector: "IsFitranaEligible",
      sortable: true,
    },
    {
      name: "Is Sadaqa Eligible",
      selector: "IsSadaqaEligible",
      sortable: true,
    },
    {
      name: "Martial Status",
      selector: "MartialStatus",
      sortable: true,
    },
    {
      name: "Is Deceased",
      selector: "IsDeceased",
      sortable: true,
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let _values = e.target.value;

    if (e.target.getAttribute("isalphabetic") === "true") {
      //
      _values = AllowAlphabatic(e.target.value);
    } else if (e.target.getAttribute("isnumber") == "true")
      _values = e.target.value.replace(/\D/g, "");

    setSearchVlues({
      ...searchValues,
      [name]: _values,
    });

    //const { name, value } = e.target;

    //seatSearchVlues({
    //  ...searchValues,
    //  [name]: value,
    //});
  };
  const [pending, setPending] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(reportList);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);
  React.useEffect(() => {
    // need to define the function and call it separately
    const load = async () => {
      // resetFormelement();
      let ddlGender = await GetGender();
      let ddlCity = await GetCity();
      let ddlArea = await GetArea();
      let ddlCaseNature = await GetCaseNature();
      let ddlCategory = await GetCategory();
      let ddlReferralType = await GetReferralType();
      let ddlCaseStatus = await GetCaseStatus();
      let fundCategoryddl = await GetFundCategory(-1);
      const MD_Response = await GetSetupMasterApplicantList(
        SetupMasterInitialValues.OperationId,
        SetupMasterInitialValues.SetupMasterId,
        SetupMasterInitialValues.ParentId,
        SetupMasterInitialValues.SetupDetailName,
        SetupMasterInitialValues.SetupDetailId
      );
      let ddlMartialStatus = await GetMartialStatus();

      setgenderddl(ddlGender.data);
      setcityddl(ddlCity.data);
      setareaddl(ddlArea?.data);
      setInvestigatingOfficerddl(MD_Response?.DataSet?.Table6);
      setTrusteeddl(MD_Response?.DataSet?.Table9);
      setHODddl(MD_Response?.DataSet?.Table8);
      setcasenatureddl(ddlCaseNature.data);
      setcategoryddl(ddlCategory.data);
      setreferralTypeddl(ddlReferralType.data);
      setcaseStatusddl(ddlCaseStatus.data);
      setfundCategroyddl(fundCategoryddl.data);

      setMartialStatusddl(ddlMartialStatus.data);
      await ReBindGrid();
    };
    load();
  }, []);
  const SetupMasterInitialValues = {
    OperationId: 5,
    SetupMasterId: 0,
    ParentId: 0,
    SetupDetailName: "",
    SetupDetailId: 0,
    Flex1: "",
    Flex2: "",
    Flex3: "",
  };
  const resetFormelement = async () => {
    setSearchVlues(searchingValues);
    // let ddlGender = await GetGender();
    // let ddlCity = await GetCity();
    // let ddlArea = await GetArea();
    // let ddlCaseNature = await GetCaseNature();
    // let ddlCategory = await GetCategory();
    // let ddlReferralType = await GetReferralType();
    // let ddlCaseStatus = await GetCaseStatus();
    // let fundCategoryddl = await GetFundCategory(-1);
    // const MD_Response = await GetSetupMasterApplicantList(
    //   SetupMasterInitialValues.OperationId,
    //   SetupMasterInitialValues.SetupMasterId,
    //   SetupMasterInitialValues.ParentId,
    //   SetupMasterInitialValues.SetupDetailName,
    //   SetupMasterInitialValues.SetupDetailId
    // );
    // let ddlMartialStatus = await GetMartialStatus();

    // setgenderddl(ddlGender.data);
    // setcityddl(ddlCity.data);
    // setareaddl(ddlArea?.data);
    // setInvestigatingOfficerddl(MD_Response?.DataSet?.Table6);
    // setTrusteeddl(MD_Response?.DataSet?.Table9);
    // setHODddl(MD_Response?.DataSet?.Table8);
    // setcasenatureddl(ddlCaseNature.data);
    // setcategoryddl(ddlCategory.data);
    // setreferralTypeddl(ddlReferralType.data);
    // setcaseStatusddl(ddlCaseStatus.data);
    // setfundCategroyddl(fundCategoryddl.data);

    // setMartialStatusddl(ddlMartialStatus.data);
    // await ReBindGrid('reset');
  };

  const GetGender = async (e) => {
    var data = await GetSetupMaster(SetupMasterIds.Gender, 0, "", 0);

    return data;
  };

  const GetCity = async (e) => {
    var data = await GetSetupMaster(SetupMasterIds.City, 0, "", 0);

    return data;
  };

  const GetArea = async (e) => {
    var data = await GetSetupMaster(SetupMasterIds?.Village_Muhalla, 0, "", 0);

    return data;
  };

  const GetCaseNature = async (e) => {
    var data = await GetSetupMaster(SetupMasterIds.NatureOfCase, 0, "", 0);

    return data;
  };

  const GetCategory = async (e) => {
    var data = await GetSetupMaster(SetupMasterIds.Category, 0, "", 0);

    return data;
  };

  const GetReferralType = async (e) => {
    var data = await GetSetupMaster(SetupMasterIds.ReferrerType, 0, "", 0);

    return data;
  };

  const GetCaseStatus = async (e) => {
    var data = await GetSetupMaster(SetupMasterIds.CaseStatus, 0, "", 0);

    return data;
  };
  const GetFundCategory = async (CategoryId) => {
    var data = await GetSetupMaster(
      SetupMasterIds.FundCategory,
      CategoryId == "0" ? "-1" : CategoryId,
      "",
      0
    );

    return data;
  };

  const GetMartialStatus = async (e) => {
    var data = await GetSetupMaster(SetupMasterIds.MaritalStatus, 0, "", 0);

    return data;
  };
  const handleCategoryChange = async (e) => {
    handleInputChange(e);
    let fundCategoryddl = await GetFundCategory(e.target.value);

    setfundCategroyddl(fundCategoryddl.data);
  };

  const handleSearchClick = async (e) => {
    e.preventDefault();
    if (searchValues.FromDate != "") {
      if (searchValues.ToDate != "") {
        ReBindGrid();
      } else {
        Swal.fire({
          title: "Warning",
          text: "Select To Date",
          icon: "warning",
        });
      }
    } else {
      Swal.fire({
        title: "Warning",
        text: "Select From Date",
        icon: "warning",
      });
    }
  };

  const handleCancelClick = async (e) => {
    e.preventDefault();
    resetFormelement();
  };

  const GetApplicantCaseListReport = async (reset) => {
    try {
      searchValues.GenderId =
        searchValues.GenderId == -1 || searchValues.GenderId == ""
          ? null
          : searchValues.GenderId;

      searchValues.CityId =
        searchValues.CityId == -1 || searchValues.CityId == ""
          ? null
          : searchValues.CityId;

      searchValues.CaseNatureId =
        searchValues.CaseNatureId == -1 || searchValues.CaseNatureId == ""
          ? null
          : searchValues.CaseNatureId;

      searchValues.CategoryId =
        searchValues.CategoryId == -1 || searchValues.CategoryId == ""
          ? null
          : searchValues.CategoryId;

      searchValues.FundCategoryId =
        searchValues.FundCategoryId == -1 || searchValues.FundCategoryId == ""
          ? null
          : searchValues.FundCategoryId;

      searchValues.ReferralTypeId =
        searchValues.ReferralTypeId == -1 || searchValues.ReferralTypeId == ""
          ? null
          : searchValues.ReferralTypeId;

      searchValues.CaseStatusId =
        searchValues.CaseStatusId == -1 || searchValues.CaseStatusId == ""
          ? null
          : searchValues.CaseStatusId;

      searchValues.IsViewBlackList =
        searchValues.IsViewBlackList == -1
          ? null
          : searchValues.IsViewBlackList;

      searchValues.MartialStatusId =
        searchValues.MartialStatusId == -1 || searchValues.MartialStatusId == ""
          ? null
          : searchValues.MartialStatusId;

      searchValues.IsDeceased =
        searchValues.IsDeceased == -1
          ? null
          : JSON.parse(searchValues.IsDeceased);

      // new lines added
      searchValues.IsZakatEligible =
        searchValues.IsZakatEligible == -1
          ? null
          : JSON.parse(searchValues.IsZakatEligible);

      searchValues.IsFitranaEligible =
        searchValues.IsFitranaEligible == -1
          ? null
          : JSON.parse(searchValues.IsFitranaEligible);

      searchValues.AreaID =
        searchValues.AreaID == -1 || searchValues.AreaID == ""
          ? null
          : searchValues.AreaID;

      searchValues.InvestigatorID =
        searchValues.InvestigatorID == -1 || searchValues.InvestigatorID == ""
          ? null
          : searchValues.InvestigatorID;

      searchValues.TrusteeID =
        searchValues.TrusteeID == -1 || searchValues.TrusteeID == ""
          ? null
          : searchValues.TrusteeID;

      searchValues.HoDID =
        searchValues.HoDID == -1 || searchValues.HoDID == ""
          ? null
          : searchValues.HoDID;
      // new lines added end

      searchValues.FromDate = !searchValues.FromDate
        ? null
        : moment(searchValues.FromDate).format("YYYY-MM-DD");
      searchValues.ToDate = !searchValues.ToDate
        ? null
        : moment(searchValues.ToDate).format("YYYY-MM-DD");
      searchValues.ApplicantCaseCode = searchValues.ApplicantCaseCode
        ? searchValues.ApplicantCaseCode
        : null;
      searchValues.ReferralName = searchValues.ReferralName
        ? searchValues.ReferralName
        : null;
      searchValues.CNIC = searchValues.CNIC ? searchValues.CNIC : null;
      searchValues.ApplicantName = searchValues.ApplicantName
        ? searchValues.ApplicantName
        : null;
      searchValues.ApplicantCaseCode = searchValues.ApplicantCaseCode
        ? searchValues.ApplicantCaseCode
        : null;

      const data = await fetchData(
        ControllerName.Reporting,
        ApiMethods.Report_Applicant_Case_List,
        reset ? resetValues : searchValues
      );

      if (data != null) {
        setPending(false);
        // setSearchVlues({
        //   ...searchValues,
        //   IsDeceased: -1,
        //   IsFitranaEligible: -1,
        //   IsZakatEligible: -1,
        // });
        if (data.Response === true && data.DataSet != null) {
          return data.DataSet.Table;
        } else {
          return [];
        }
      } else {
        setPending(false);
        // setSearchVlues({
        //   ...searchValues,
        //   IsDeceased: -1,
        //   IsFitranaEligible: -1,
        //   IsZakatEligible: -1,
        // });

        return [];
      }
    } catch (error) {
      setPending(false);
      // setSearchVlues({
      //   ...searchValues,
      //   IsDeceased: -1,
      //   IsFitranaEligible: -1,
      //   IsZakatEligible: -1,
      // });
      return [];
    }
  };

  const ReBindGrid = async (reset) => {
    setPending(true);
    var data = await GetApplicantCaseListReport(reset);
    setreportList(data);
    setFinalData({ columns, data });
  };
  const AllDateSet = (event, type) => {
    //...formFields,
    //CreatedDateTo: event

    if (type === "FromDate") {
      setSearchVlues({
        ...searchValues,
        FromDate: event,
        // familyDateOfBirth: event,
      });
    } else if (type === "ToDate") {
      setSearchVlues({
        ...searchValues,
        ToDate: event,
        // familyDateOfBirth: event,
      });
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg={12} md={12}>
            <Card className="card-user">
              <CardBody>
                <Form>
                  <Row form>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">From Date</Label>
                        <DatePicker
                          value={getDate(searchValues.FromDate, "/")}
                          dateFormat={dateFormat}
                          onChange={(e) => AllDateSet(e, "FromDate")}
                          className="form-control"
                          name="FromDate"
                          placeholderText={dateFormatPlaceholder}
                          showYearDropdown
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">To Date</Label>
                        <DatePicker
                          value={getDate(searchValues.ToDate, "/")}
                          // value={getDate(Date.now(), "/")}
                          dateFormat={dateFormat}
                          onChange={(e) => AllDateSet(e, "ToDate")}
                          className="form-control"
                          name="ToDate"
                          placeholderText={dateFormatPlaceholder}
                          showYearDropdown
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Applicant Case Code</Label>
                        <Input
                          type="text"
                          placeholder="Applicant Case Code"
                          onChange={handleInputChange}
                          name="ApplicantCaseCode"
                          maxLength="50"
                          value={searchValues.ApplicantCaseCode}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Applicant Name</Label>
                        <Input
                          type="text"
                          placeholder="Applicant Name"
                          onChange={handleInputChange}
                          name="ApplicantName"
                          isalphabetic="true"
                          maxLength="50"
                          value={searchValues.ApplicantName}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">CNIC</Label>
                        <Input
                          type="text"
                          onChange={handleInputChange}
                          isnumber="true"
                          placeholder="XXXXXXXXXXXXX"
                          name="CNIC"
                          maxLength="13"
                          value={searchValues.CNIC}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Gender</Label>
                        <Input
                          id="exampleSelect"
                          name="GenderId"
                          type="select"
                          value={searchValues.GenderId}
                          onChange={handleInputChange}
                        >
                          <option key={-1} value={-1}>
                            Select
                          </option>

                          {genderddl.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Marital Status</Label>
                        <Input
                          id="exampleSelect"
                          name="MartialStatusId"
                          type="select"
                          value={searchValues.MartialStatusId}
                          onChange={handleInputChange}
                        >
                          <option key={-1} value={-1}>
                            Select
                          </option>

                          {MartialStatusddl.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">City</Label>
                        <Input
                          id="exampleSelect"
                          name="CityId"
                          type="select"
                          value={searchValues.CityId}
                          onChange={handleInputChange}
                        >
                          <option key={-1} value={-1}>
                            Select
                          </option>

                          {cityddl.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Case Nature</Label>
                        <Input
                          id="exampleSelect"
                          name="CaseNatureId"
                          type="select"
                          value={searchValues.CaseNatureId}
                          onChange={handleInputChange}
                        >
                          <option key={-1} value={-1}>
                            Select
                          </option>

                          {casenatureddl.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Fund Category</Label>
                        <Input
                          id="exampleSelect"
                          name="CategoryId"
                          type="select"
                          value={searchValues.CategoryId}
                          onChange={handleCategoryChange}
                        >
                          <option key={-1} value={-1}>
                            Select
                          </option>

                          {categoryddl.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Fund Sub Category</Label>
                        <Input
                          id="exampleSelect"
                          name="FundCategoryId"
                          type="select"
                          value={searchValues.FundCategoryId}
                          onChange={handleInputChange}
                        >
                          <option key={-1} value={-1}>
                            Select
                          </option>

                          {fundCategroyddl.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Case Status</Label>
                        <Input
                          id="exampleSelect"
                          name="CaseStatusId"
                          type="select"
                          value={searchValues.CaseStatusId}
                          onChange={handleInputChange}
                        >
                          <option key={-1} value={-1}>
                            Select
                          </option>

                          {caseStatusddl.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Referral Type</Label>
                        <Input
                          id="exampleSelect"
                          name="ReferralTypeId"
                          type="select"
                          value={searchValues.ReferralTypeId}
                          onChange={handleInputChange}
                        >
                          <option key={-1} value={-1}>
                            Select
                          </option>

                          {referralTypeddl.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Referral Name</Label>
                        <Input
                          type="text"
                          placeholder="Referral Name"
                          onChange={handleInputChange}
                          name="ReferralName"
                          maxLength="50"
                          isalphabetic="true"
                          value={searchValues.ReferralName}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Is View Black List</Label>
                        <Input
                          id="exampleSelect"
                          name="IsViewBlackList"
                          type="select"
                          value={searchValues.IsViewBlackList}
                          onChange={handleInputChange}
                        >
                          <option key={-1} value={-1}>
                            All
                          </option>

                          <option key={1} value={1}>
                            Black List
                          </option>

                          {/* <option key={0} value={0}>
                                                       White List
                                                    </option>  */}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Is Deceased</Label>
                        <Input
                          id="exampleSelect"
                          name="IsDeceased"
                          type="select"
                          value={searchValues.IsDeceased}
                          onChange={handleInputChange}
                        >
                          <option key={-1} value={-1}>
                            Select
                          </option>

                          <option key={1} value={true}>
                            Yes
                          </option>
                          <option key={0} value={false}>
                            No
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Is Zakat Eligible</Label>
                        <Input
                          id="exampleSelect"
                          name="IsZakatEligible"
                          type="select"
                          value={searchValues.IsZakatEligible}
                          onChange={handleInputChange}
                        >
                          <option key={-1} value={-1}>
                            Select
                          </option>

                          <option key={1} value={true}>
                            Yes
                          </option>
                          <option key={0} value={false}>
                            No
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Is Fitrana Eligible</Label>
                        <Input
                          id="exampleSelect"
                          name="IsFitranaEligible"
                          type="select"
                          value={searchValues.IsFitranaEligible}
                          onChange={handleInputChange}
                        >
                          <option key={-1} value={-1}>
                            Select
                          </option>

                          <option key={1} value={true}>
                            Yes
                          </option>
                          <option key={0} value={false}>
                            No
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Area</Label>
                        <Input
                          id="exampleSelect"
                          name="AreaID"
                          type="select"
                          value={searchValues?.AreaID}
                          onChange={handleInputChange}
                        >
                          <option key={-1} value={-1}>
                            Select
                          </option>

                          {areaddl.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroupSelect
                        label="Investigator Officer"
                        name="InvestigatorID"
                        value={searchValues.InvestigatorID}
                        onChange={handleInputChange}
                        list={investigatingOfficerddl}
                        fieldId="UserId"
                        fieldName="Name"
                        // onKeyPress={handleKeyPress}
                      />
                      {/* <FormGroup>
                        <Label for="">Investigator officer</Label>
                        <Input
                          id="exampleSelect"
                          name="Investigator officer"
                          type="select"
                          value={searchValues.ReferralTypeId}
                          onChange={handleInputChange}
                        >
                          <option key={-1} value={-1}>
                            Select
                          </option>

                          {investigatingOfficerddl.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup> */}
                    </Col>
                    <Col md={3}>
                      <FormGroupSelect
                        label="Trustee"
                        name="TrusteeID"
                        value={searchValues.TrusteeID}
                        onChange={handleInputChange}
                        list={trusteeddl}
                        fieldId="UserId"
                        fieldName="Name"
                        // onKeyPress={handleKeyPress}
                      />
                      {/* <FormGroup>
                        <Label for="">Trustee</Label>
                        <Input
                          id="exampleSelect"
                          name="Trustee"
                          type="select"
                          value={searchValues.ReferralTypeId}
                          onChange={handleInputChange}
                        >
                          <option key={-1} value={-1}>
                            Select
                          </option>

                          {referralTypeddl.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup> */}
                    </Col>
                    <Col md={3}>
                      <FormGroupSelect
                        label="HOD"
                        name="HoDID"
                        value={searchValues.HoDID}
                        onChange={handleInputChange}
                        list={HODddl}
                        fieldId="UserId"
                        fieldName="Name"
                        // onKeyPress={handleKeyPress}
                      />
                      {/* <FormGroup>
                        <Label for="">HOD</Label>
                        <Input
                          id="exampleSelect"
                          name="HOD"
                          type="select"
                          value={searchValues.ReferralTypeId}
                          onChange={handleInputChange}
                        >
                          <option key={-1} value={-1}>
                            Select
                          </option>

                          {referralTypeddl.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup> */}
                    </Col>
                    <Col md={12} className="text-right">
                      <Button
                        color="primary"
                        size="sm"
                        className="mr-2"
                        onClick={handleSearchClick}
                      >
                        Search
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12}>
            <Card>
              <CardHeader>
                <Row>
                  <Col lg={6} md={6}>
                    Applicant Approved Cases Report
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <DataTableExtensions
                  {...finalData}
                  exportHeaders={true}
                  fileName="ApplicantCaseListReport"
                >
                  <StyledTable
                    dense
                    direction="auto"
                    defaultSortField="ApplicantName"
                    fixedHeader
                    striped
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    expandOnRowClicked
                    progressPending={pending}
                    responsive
                    fixedHeaderScrollHeight="auto"
                    subHeaderAlign="right"
                    subHeaderWrap={true}
                    customStyles={DataTableCustomStyles}
                  />
                  {/* <DataTable
                    dense
                    direction="auto"
                    defaultSortField="ApplicantName"
                    fixedHeader
                    striped
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    progressPending={pending}
                    customStyles={DataTableCustomStyles}
                    responsive
                    fixedHeaderScrollHeight="auto"
                    subHeaderAlign="right"
                    subHeaderWrap
                  /> */}
                </DataTableExtensions>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Report_Applicant_Case__List;
