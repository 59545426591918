import { Roles } from "utils/Constants"

const useIsAccounts = () => {
  const RoleId = localStorage.getItem("RoleId")
  const isAccounts = RoleId === Roles.Accounts.toLocaleString()

  return isAccounts
}

export default useIsAccounts
