import { Roles } from "utils/Constants";

const useIsMarketing = () => {
  // const RoleId = localStorage.getItem("RoleId");
  // const isMaketing = RoleId === Roles.Marketing.toLocaleString();
  const RoleId = localStorage.getItem("RoleId");
  const tabname = localStorage.getItem("TabName");

  const role = RoleId === Roles.Marketing.toLocaleString();
  const isMaketing = tabname == "ViewAllCases" ? false : role ? true : false;
  return isMaketing;
};

export default useIsMarketing;
