import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Label,
  Input,
} from "reactstrap";
import * as api from "../../utils/Api.js";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { fetchData } from "utils/Api";
import {
  ApiMethods,
  ControllerName,
  DataTableCustomStyles,
  OperationTypeId,
  SetupMasterIds,
} from "utils/Constants";
import { GetSetupMaster, AllowAlphabatic } from "utils/CommonMethods";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import Swal from "sweetalert2";
import styled from "styled-components";

const Report_Restricted_Funds = (props) => {
  const StyledTable = styled(DataTable)`
    .rdt_TableCol div div {
      overflow: visible;
      white-space: pre-wrap;
      padding-left: 20px;
    }
    .rdt_TableRow {
      padding-top: 10px;
    }
  `;
  const searchingValues = {
    ApplicantCaseCode: "",
    ApplicantName: "",
    ApplicantCNIC: "",
    Mobile: "",
    Category: "",
    SubCategory: "",
    RestrictedFundsDonatedBy: "",
  };
  const initialValues = {
    OperationId: 3,
    ApplicantCase_InvestigationId: 0,
    CaseStatusId: 0,
    Remarks: "",
    UserIP: "",
    UserId: 0,
    ArrayApplicantCaseSupportHistory: [],
    IsBlackList: false,
    CaseStartDate: "",
    PhysicalAudit: false,
    Physical_Audit_Assign: 0,
    IsRestrictedFunds: false,
    DonatedBy: "",
    DonatedByRemarks: "",
    IsEligibility: false,
    Category: null,
  };

  const [searchValues, setSearchVlues] = useState(searchingValues);
  const [reportList, setreportList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [finalData, setFinalData] = useState({});
  const columns = [
    {
      name: "Case Code",
      selector: "CaseCode",
      sortable: true,
      wrap: true,
      width: "120px",
    },
    {
      name: "Applicant Name",
      selector: "ApplicantName",
      sortable: true,
      wrap: true,
    },
    {
      name: "Applicant CNIC",
      selector: "ApplicantCNIC",
      sortable: true,
      width: "125px",
    },
    {
      name: "Mobile No",
      selector: "Mobile",
      sortable: true,
      width: "110px",
    },
    {
      name: "Category",
      selector: "Category",
      sortable: true,
      width: "90px",
    },
    {
      name: "Sub Category",
      selector: "SubCategory",
      sortable: true,
      wrap: true,
      width: "120px",
    },
    {
      name: "Approval Remark",
      selector: "ApprovalRemark",
      sortable: true,
    },
    {
      // name: "Donated By",
      name: "Donor Name",
      selector: "RestrictedFundsDonatedBy",
      sortable: true,
      wrap: true,
    },
    {
      // name: "Donated By Remarks",
      name: "Amount with Remarks",
      selector: "RestrictedFundsRemarks",
      sortable: true,
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let _values = e.target.value;

    if (e.target.getAttribute("isalphabetic") === "true") {
      _values = AllowAlphabatic(e.target.value);
    } else if (e.target.getAttribute("isnumber") == "true")
      _values = e.target.value.replace(/\D/g, "");

    if (name === "Category") {
      setSearchVlues({
        ...searchValues,
        [name]: value,
      });
      GetSubCategories(value);
      return;
    }
    setSearchVlues({
      ...searchValues,
      [name]: _values,
    });
  };
  const [pending, setPending] = React.useState(true);

  React.useEffect(() => {
    // need to define the function and call it separately
    const load = async () => {
      // resetFormelement()
      ReBindGrid();
    };
    load();
  }, []);

  const resetFormelement = async () => {
    setSearchVlues(searchingValues);
    // ReBindGrid()
  };

  const handleSearchClick = async (e) => {
    e.preventDefault();
    ReBindGrid();
  };

  const handleCancelClick = async (e) => {
    e.preventDefault();

    resetFormelement();
  };

  const SaveGetStatus = async (opId) => {
    return await api.postRecord(`applicant`, `ApplicantCase_StatusHistory`, {
      ...initialValues,
      ...searchValues,
      Category: searchValues.Category ? searchValues.Category : null,
      SubCategory: searchValues.SubCategory ? searchValues.SubCategory : null,
    });
  };

  const GetSubCategories = async (category) => {
    const { data } = await api.postRecord(
      `applicant`,
      `ApplicantCase_StatusHistory`,
      {
        ...initialValues,
        OperationId: 4,
        Category: category ? category : null,
      }
    );
    setSubCategories(data.DataSet.Table);
  };

  const ReBindGrid = async () => {
    setPending(true);
    var { data } = await SaveGetStatus();
    setCategories(data.DataSet.Table);
    setreportList(data.DataSet.Table1);
    setFinalData({ columns, data: data.DataSet.Table1 });
    setPending(false);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg={12} md={12}>
            <Card className="card-user">
              <CardBody>
                <Form>
                  <Row form>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Applicant Case Code</Label>
                        <Input
                          type="text"
                          placeholder="Applicant Case Code"
                          onChange={handleInputChange}
                          name="ApplicantCaseCode"
                          maxLength="50"
                          value={searchValues.ApplicantCaseCode}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Applicant Name</Label>
                        <Input
                          type="text"
                          placeholder="Applicant Name"
                          onChange={handleInputChange}
                          name="ApplicantName"
                          maxLength="50"
                          value={searchValues.ApplicantName}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Applicant CNIC</Label>
                        <Input
                          type="text"
                          onChange={handleInputChange}
                          isnumber="true"
                          placeholder="XXXXXXXXXXXXX"
                          name="ApplicantCNIC"
                          maxLength="13"
                          value={searchValues.ApplicantCNIC}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Mobile</Label>
                        <Input
                          type="text"
                          placeholder="Mobile"
                          onChange={handleInputChange}
                          name="Mobile"
                          isnumber="true"
                          maxLength="13"
                          value={searchValues.Mobile}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Category</Label>
                        <Input
                          id="exampleSelect"
                          name="Category"
                          type="select"
                          value={searchValues.Category}
                          onChange={handleInputChange}
                        >
                          <option key={null} value={""}>
                            Select
                          </option>

                          {categories.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Sub Category</Label>
                        <Input
                          id="exampleSelect"
                          name="SubCategory"
                          type="select"
                          value={searchValues.SubCategory}
                          onChange={handleInputChange}
                        >
                          <option key={null} value={""}>
                            Select
                          </option>

                          {subCategories.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Donor Name</Label>
                        <Input
                          type="text"
                          placeholder="Donar Name"
                          onChange={handleInputChange}
                          name="RestrictedFundsDonatedBy"
                          maxLength="50"
                          value={searchValues.RestrictedFundsDonatedBy}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={12} className="text-right">
                      <Button
                        color="primary"
                        size="sm"
                        className="mr-2"
                        onClick={handleSearchClick}
                      >
                        Search
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12}>
            <Card>
              <CardHeader>
                <Row>
                  <Col lg={6} md={6}>
                    Restricted Fund Report
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <DataTableExtensions
                  {...finalData}
                  exportHeaders={true}
                  fileName="Report Restricted Funds"
                >
                  <StyledTable
                    dense
                    direction="auto"
                    defaultSortField="FirstName"
                    fixedHeader
                    striped
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    progressPending={pending}
                    responsive
                    customStyles={DataTableCustomStyles}
                    fixedHeaderScrollHeight="auto"
                    subHeaderAlign="right"
                    subHeaderWrap={true}
                  />
                  {/* <DataTable
                    dense
                    direction="auto"
                    defaultSortField="FirstName"
                    fixedHeader
                    striped
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    progressPending={pending}
                    responsive
                    customStyles={DataTableCustomStyles}
                    fixedHeaderScrollHeight="auto"
                    subHeaderAlign="right"
                    subHeaderWrap
                    
                  /> */}
                </DataTableExtensions>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Report_Restricted_Funds;
