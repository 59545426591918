import React, { useState, Link, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Label,
  Input,
} from "reactstrap";
import ModalApplHistory from "../../components/modal/ModalApplHistory.js";
import { AllowAlphabatOnly, GetSetupMaster, updateDataGeneric } from "utils/CommonMethods.js";
import { ApiMethods, ControllerName, SetupMasterIds } from "utils/Constants.js";
import { fetchData } from "utils/Api.js";
import FormGroupSelect from "components/GeneralComponent/FormGroupSelect.jsx";
import Swal from "sweetalert2";
import FormGroupInput from "components/GeneralComponent/FormGroupInput.jsx";
import FormGroupButton from "components/GeneralComponent/FormGroupButton.jsx";
import useEditRole from "hooks/useEditRole.js";
import FormGroupTable from "components/GeneralComponent/FormGroupTable.jsx";

const initialValues = {
  ApplicantCase_InvestigationId: 0,
  ApplicantGuardianDetailId: 0,
  GuardianName: "",
  GuardianCnic: "",
  GuardianContactNo: "",
  Occupation: "",
  Relation: "",
  CompanyName: "",
  UserId: localStorage.getItem("UserId"),
  UserIP: localStorage.getItem("UserIP"),
};

const initialSelectLists = {
  RelationList: [],
};

const AdditionalDetails = (props) => {
  const [role, appId] = useEditRole();
  const [formFields, setFormFields] = useState({
    ...initialValues,
    // ApplicantCase_InvestigationId: appId,
  });
  // const [guardianDetail, setGuardianDetail] = useState({});
  const [guardianDetail, setGuardianDetail] = useState([]); //test
  const [selectionLists, setSelectionLists] = useState(initialSelectLists);
  const [formLoading, setFormLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const fetchApplicantId = () => {
    // testing
    setTableLoading(true);
    fetchData("Applicant", "Crud_Guardian_Detail", {
      OperationId: 1,
      ...formFields,
      ApplicantCase_InvestigationId: appId,
    }).then((result) => {
      if (result?.ResponseMessage !== "Success") {
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
        });
        return;
      }
      // setFormFields({ ...formFields, ...result.DataSet.Table[0] });
      // setGuardianDetail({ ...result.DataSet.Table[0] });
      // setFormFields({ ...formFields, ...result.DataSet.Table[result.DataSet.Table.length - 1] }); // testing
      // setGuardianDetail({ ...result.DataSet.Table[result.DataSet.Table.length - 1] });   // testing
      // setFormFields({ ...formFields, ...result.DataSet.Table[result.DataSet.Table.length - 1] }); // testing
      setGuardianDetail(result.DataSet.Table); // testing
      setTableLoading(false);
    });
  };
  const requestCall = (opId, payload) => {
    setFormLoading(true);
    fetchData("Applicant", "Crud_Guardian_Detail", {
      OperationId: opId,
      ...payload,
    }).then((result) => {
      if (result?.DataSet?.Table[0]?.HasError == 1) {
        Swal.fire({
          title: "Error",
          text: result?.DataSet?.Table[0]?.Message,
          icon: "error",
        });
        setFormLoading(false);
        return;
      }
      if (result?.DataSet?.Table[0]?.HasError == 2) {
        Swal.fire({
          title: "Warning",
          text: result?.DataSet?.Table[0]?.Message,
          icon: "warning",
        });
        setFormLoading(false);
        return;
      }
      Swal.fire({
        title: "Success",
        text: result?.DataSet?.Table[0]?.Message,
        icon: "success",
      });
      setGuardianDetail([...result?.DataSet?.Table1]);
      setFormFields({ ...initialValues });
      setFormLoading(false);
    });
  };

  useEffect(() => {
    fetchApplicantId();

    const fetchDropDownList = async () => {
      let relationList = await GetSetupMaster(
        SetupMasterIds.Relation,
        0,
        "",
        0
      );
      setSelectionLists({
        ...setSelectionLists,
        RelationList: relationList.data,
      });
    };
    fetchDropDownList();
    fetchApplicantId();
  }, []);

  const handleInputChange = (event) => {
    const {name,value} = event.target;
    if(name == 'GuardianName'){
      const sanitized = AllowAlphabatOnly(value);
      setFormFields({
        ...formFields,
        [event.target.name]: sanitized,
      });
    }else{
      setFormFields({
        ...formFields,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formFields?.GuardianCnic?.length > 13 || formFields?.GuardianCnic?.length < 13 ) {
      Swal.fire({
        title:'Warning',
        text: "Invalid CNIC Number. Please enter a 13 digits number",
        icon: "warning",
      });
      return;
    }

    if(formFields?.GuardianContactNo?.length > 0){
      if (formFields?.GuardianContactNo?.length > 11 || formFields?.GuardianContactNo?.length < 11 ) {
        Swal.fire({
          title:'Warning',
          text: "Invalid Contact Number. Please enter 11 digits number",
          icon: "warning",
        });
        return;
      }
    }
    let opId = formFields.ApplicantCase_InvestigationId === 0 ? 2 : 3;
    let swelmsg = "";
    if (opId === parseInt(3)) {
      swelmsg = "Are you sure to edit the record?";
    } else {
      swelmsg = "Are you sure to add the record?";
    }

    if (opId == 2) {
      let exist = guardianDetail.find(
        (item) => item.GuardianCnic == formFields.GuardianCnic
      );
      if (exist) {
        // testing
        Swal.fire({
          title: "Warning",
          text: `Duplicate data not allowed for same CNIC`,
          icon: "warning",
        });
        setFormLoading(false);
        return;
      }
      Swal.fire({
        customClass: {
          container: "my-swal",
        },
        text: swelmsg,
        icon: "success",
        showCancelButton: true,
        cancelButtonText: `Cancel`,
        cancelButtonColor: "#2f4050",
        confirmButtonText: `Confirm`,
        confirmButtonColor: "#bf1e2e",
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            ...formFields,
            ApplicantCase_InvestigationId: appId,
          };
          requestCall(opId, payload);
        }
      });
      return;
    } else {
      let exist = guardianDetail.find(
        (item) =>
          item.GuardianCnic == formFields.GuardianCnic &&
          item?.ApplicantGuardianDetailId !=
            formFields?.ApplicantGuardianDetailId
      );
      if (exist) {
        // testing
        Swal.fire({
          title: "Warning",
          text: `Duplicate data not allowed for same CNIC`,
          icon: "warning",
        });
        setFormLoading(false);
        return;
      }
      Swal.fire({
        customClass: {
          container: "my-swal",
        },
        text: swelmsg,
        icon: "success",
        showCancelButton: true,
        cancelButtonText: `Cancel`,
        cancelButtonColor: "#2f4050",
        confirmButtonText: `Confirm`,
        confirmButtonColor: "#bf1e2e",
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = { ...formFields };
          requestCall(opId, payload);
        }
      });
    }
  };

  const onEdit = (index) => {
    // let copyList = guardianDetail;
    // let obj = copyList[index];
    // let remaining = copyList.filter(
    //   (item) => item.ApplicantGuardianDetailId != obj.ApplicantGuardianDetailId
    // );
    setFormFields({
      ...formFields,
      ...guardianDetail[index],
      Relation: guardianDetail[index].RelationID,
      ApplicantCase_InvestigationId: appId,
    });
    // setGuardianDetail([...remaining]);
  };
  const onDelete = (index) => {
    let obj = guardianDetail[index];
    Swal.fire({
      customClass: {
        container: "my-swal",
      },
      text: "Are you sure to delete the record?",
      icon: "success",
      showCancelButton: true,
      cancelButtonText: `Cancel`,
      cancelButtonColor: "#2f4050",
      confirmButtonText: `Confirm`,
      confirmButtonColor: "#bf1e2e",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          ...obj,
          ApplicantCase_InvestigationId: appId,
          UserId: localStorage.getItem("UserId"),
          UserIP: localStorage.getItem("UserIP"),
        };
        requestCall(4, payload);
      }
    });
  };
  const columns = [
    {
      field: "GuardianCnic",
      name: "Guardian Cnic",
    },
    {
      field: "GuardianName",
      name: "Guardian Name",
    },

    {
      field: "GuardianContactNo",
      name: "Guardian Contact No",
    },

    {
      field: "Occupation",
      name: "Occupation",
    },

    {
      field: "Relation",
      name: "Relation",
    },

    {
      field: "CompanyName",
      name: "Company Name",
    },
    {
      field: "CreatedDate",
      name: "Created Date",
      type: "date",
    },
  ];
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Card className="mb-3">
          <CardHeader>
            <h6 className="font-weight-bold mb-0">Guardian Details</h6>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={12}>
                <h2 className="h6">Guardian Details</h2>
              </Col>
            </Row>
            <Row className="mb-5">
              {console.log('guardianDetail',guardianDetail)}
              <Col md={12}>
                <FormGroupTable
                  loading={tableLoading}
                  rows={guardianDetail}
                  columns={columns}
                  hideAction={role} //line added
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="mb-3">
          <CardHeader>
            <h6 className="font-weight-bold mb-0">Guardian Information</h6>
          </CardHeader>
          <CardBody>
            <Row form={true}>
              <Col md={3}>
                <FormGroupInput
                  label="Guardian CNIC*"
                  name="GuardianCnic"
                  value={formFields.GuardianCnic}
                  onChange={handleInputChange}
                  required={true}
                  isNumber="true"
                  maxLength="13"
                  minLength='13'
                  disabled={role}
                />
              </Col>
              <Col md={3}>
                <FormGroupInput
                  label="Guardian Name*"
                  name="GuardianName"
                  value={formFields.GuardianName}
                  onChange={handleInputChange}
                  required={true}
                  disabled={role}
                  minLength='3'
                  maxLength="50"
                />
              </Col>
              <Col md={3}>
                <FormGroupInput
                  label="Contact Number*"
                  name="GuardianContactNo"
                  value={formFields.GuardianContactNo}
                  onChange={handleInputChange}
                  required={true}
                  isNumber="true"
                  maxLength="11"
                  minLength='11'
                  disabled={role}
                />
              </Col>
              <Col md={3}>
                <FormGroupInput
                  label="Occupation"
                  name="Occupation"
                  value={formFields.Occupation}
                  onChange={handleInputChange}
                  //required={true}
                  disabled={role}
                />
              </Col>
              <Col md={3}>
                <FormGroupSelect
                  label="Relation*"
                  name="Relation"
                  value={formFields?.Relation}
                  onChange={handleInputChange}
                  list={selectionLists.RelationList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  required={true}
                  disabled={role}
                />
              </Col>

              <Col md={3}>
                <FormGroupInput
                  label="Company"
                  name="CompanyName"
                  value={formFields.CompanyName}
                  onChange={handleInputChange}
                  //required={true}
                  disabled={role}
                />
              </Col>
            </Row>
            <Row form className="text-right">
              <Col md={12}>
                {role ? null : (
                  <FormGroupButton
                    title="Save Guardian"
                    type="submit"
                    loading={formLoading}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </form>
    </div>
  );
};

export default AdditionalDetails;
