import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  Container,
  Row,
} from "reactstrap";
import HomeHeader from "../../components/Header/HomeHeader.js";
import HomeFooter from "../../components/Footer/HomeFooter.js";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { baseImageUrl } from "utils/Api.js";
import quotes from "../../assets/img/home/quotes.png";
import { TestimonialController } from "utils/CommonMethods.js";
import ReactPlayer from "react-player";
import video from "../../assets/img/how-it-work-video.mp4";
import bgvideo from "../../assets/img/home/icon2/bg-video.png";
const Testinomial = (props) => {
  const [testimonials, setTestimonials] = useState([]);
  const GetTestimonials = async () => {
    //
    try {
      var data = await TestimonialController(1);
      console.log("data", data);
      if (data != null) {
        setTestimonials(data);
        return data;
      } else {
        // Swal.fire({ title: "Error", text: "Data not found", icon: "error" });
        return [];
      }
    } catch (error) {
      // Swal.fire({ title: "Error", text: "Data not found", icon: "error" });
      return [];
    }
  };
  useEffect(() => {
    GetTestimonials();
  }, []);

  return (
    <div className="maincontent">
      <HomeHeader isShow={false} />

      <div className="content mt-4">
        <section id="inner-banner" className="section">
          <div className="container">
            <h1 className="mb-0">Testimonials</h1>
          </div>
        </section>
        <section className="section-success mt-5 mb-4">
          <Container>
            <Row className="mb-5">
              {testimonials &&
                testimonials.map((item, key) =>
                  !item.TestimonialType ? (
                    <Col md="12" lg="6" sm="12" key={key}>
                      <Card
                        className="p-3"
                        style={{ border: "5px solid #D62C41" }}
                      >
                        <Row>
                          <Col lg="4" md="4" sm="4" xs="4">
                            <img
                              src={`${baseImageUrl}/${item?.ImageURL}`}
                              alt=""
                              style={{ width:'100%' }}
                            />
                          </Col>
                          <Col lg="8" md="8" sm="8" xs="8">
                          <h4 className="testimonee-name mb-2" style={{color:'black'}} dangerouslySetInnerHTML={{__html:item.Name}}>
                              {/* {item.Name} */}
                            </h4>
                            <p className="testimonialText">
                              {`${item.TestimonialsDesc}`}
                            </p>
                          </Col>
                        </Row>
                        {/* <div className="d-flex">
                          <div
                            style={{
                              height: "200px",
                              width: "200px",
                              border: "2px solid blue",
                            }}
                          >
                            <img
                              src={`${baseImageUrl}/${item?.ImageURL}`}
                              alt=""
                              style={{ height: "100%",objectFit:'cover' }}
                            />
                          </div>
                          <div className="ml-3">
                            <h4 className="testimonee-name mb-2">
                              {item.Name}
                            </h4>
                            <p className="testimonialText">
                              {`"${item.TestimonialsDesc}"`}
                            </p>
                          </div>
                        </div> */}
                      </Card>

                      {/* <Card body>
                        <div className="quotesss p-4">
                          <img src={quotes} alt="" />
                          <p className="testimonialText">{item.TestimonialsDesc}</p>
                          <h4 className="testimonee-name">{item.Name}</h4>
                        </div>
                      </Card> */}
                    </Col>
                  ) : (
                    <Col lg="6" md="12" sm="12" xs="12">
                      <Card
                        body
                        style={{
                          padding: "0px",
                        }}
                      >
                        <div className="quotesss">
                          <div className="videoss">
                            <ReactPlayer
                              url={video}
                              playing={true}
                              controls={true}
                              loop={true}
                              muted={true}
                              playsinline={false}
                              width={"100%"}
                              height={230}
                              light={bgvideo}
                              className="rebuilding-video"
                            />
                          </div>
                        </div>
                      </Card>
                    </Col>
                  )
                )}
            </Row>
          </Container>
        </section>
      </div>

      <HomeFooter />
    </div>
  );
};

export default Testinomial;
