import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import HomeHeader from "../../components/Header/HomeHeader.js";
import HomeFooter from "../../components/Footer/HomeFooter.js";
import about01 from "assets/img/about-us/SS-01.jpg";
import about02 from "assets/img/about-us/SS-02 (1).jpg";
import about03 from "assets/img/about-us/SS-03 (1).jpg";
import about04 from "assets/img/about-us/SS-04.jpg";
import about05 from "assets/img/home/about04.png";
import processimg1 from "../../assets/img/home/processimg1.jpg";
import Ourpartners from "./Ourpartners.js";

const AboutUs = (props) => {
  return (
    <div className="maincontent">
      <HomeHeader isShow={false} />
      <div className="content mt-4">
        <section id="inner-banner" className="section">
          <div className="container">
            <h1 className="mb-0">About Us</h1>
          </div>
        </section>
        <section id="who-we-are" className="section">
          <Container>
            <Col
              lg="12"
              md="12"
              style={{
                background: "#EDF2FA",
                padding: "20px",
                fontStyle: "italic",
                fontWeight: "bold",
                fontSize: "16px",
                textAlign: "justify",
              }}
            >
              Sab Saath (literally “All Together”) is a non-profit platform. We
              bring registered and accredited NGOs together to coordinate relief
              efforts across Pakistan. We stand as the bridge that connects
              individuals, donors, corporations, and governments, recognizing
              that true change arises from collaboration. We showcase
              meticulously vetted cases spanning across education, healthcare,
              water, disaster relief, and beyond so that you, the donor, can
              donate with peace of mind towards a cause of your choice. In
              essence, we help you help others.
            </Col>
          </Container>
        </section>
        <section id="who-we-are" className="section">
          <Container>
            <h2 className="works-title-top mb-4">
              <span className="title-bg"> Our Model </span>
            </h2>
            <Container>
              {/* <Row className="d-flex justify-content-around"> */}
              <Row className="d-flex justify-content-around">
                <Col lg="3" md="3" sm="3" xs="3" className="model-card">
                  WE VET
                </Col>
                <Col
                  md="1"
                  lg="1"
                  sm="1"
                  xs="1"
                  className="d-flex justify-content-center align-items-center  model-card-arrow"
                >
                  {/* <i className="fa fa-arrow-right"></i> */}
                  {`>>>>>`}
                </Col>
                <Col lg="3" md="3" sm="3" xs="3" className="model-card">
                  YOU DONATE
                </Col>
                <Col
                  md="1"
                  lg="1"
                  sm="1"
                  xs="1"
                  className="d-flex justify-content-center align-items-center model-card-arrow"
                >
                  {/* <i className="fa fa-arrow-right"></i> */}
                  {`>>>>>`}
                </Col>
                <Col lg="3" md="3" sm="3" xs="3" className="model-card">
                  TOGETHER, WE TRANSFORM
                </Col>
              </Row>
            </Container>
          </Container>
        </section>
        <section id="how-we-started" className="section">
          <Container>
            <h2 className="works-title-top mb-4">
              <span className="title-bg"> How We Started </span>
            </h2>
            <Container>
              <Row className="d-flex justify-content-around">
                <Col
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  style={{ textAlign: "justify" }}
                >
                  Born from the urgency of the COVID-19 pandemic, Sab Saath, a
                  project of Zaman Foundation, emerged as a beacon of hope in
                  Pakistan. Co-founders Babar Rashid, Abdullah Khan, and Omar
                  Badi-uz-Zaman, with invaluable contributions from Sarfraz
                  Niazi, envisioned a different kind of relief effort. As the
                  virus swept across our nation in March 2020, we recognized the
                  need for a nimble, coordinated response. Traditional relief
                  organizations, designed for calmer times, couldn't keep pace
                  with the rapidly evolving crisis. In the face of COVID-19's
                  multifaceted challenges, Sab Saath was born - a single
                </Col>
                <Col
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  style={{ textAlign: "justify" }}
                >
                  platform that united diverse organizations to efficiently
                  deliver aid where it was most needed. Our advantage? Speed. We
                  provided immediate assistance to those in crisis faster than
                  larger counterparts. This experience showed us that a
                  transparent, dynamic platform, where individuals and
                  organizations collaborate to become more than the sum of their
                  parts, was the need of the hour. Today, Sab Saath stands as a
                  trusted platform for verified NGOs across Pakistan, bringing
                  them together to coordinate relief efforts and make a
                  meaningful impact.
                </Col>
              </Row>
            </Container>
          </Container>
          {/* <div className="container">
            <Row>
              <Col md={12}>
                <h2 className="mb-2">How We Started</h2>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <h4 className="mb-4">
                  Sab Saath is a project of{" "}
                  <a href="http://www.zamanfoundation.pk/" target="blank">
                    <u>Zaman Foundation</u>
                  </a>
                  . It was launched in response to the pandemic by co-founders
                  Babar Rashid, Abdullah Khan and Omar Badi-uz-Zaman with
                  notable contributions by Sarfraz Niazi.
                </h4>
                <p>
                  Sab Saath is a platform for verified NGOs across Pakistan. We
                  help you help others.
                </p>

                <p>
                  Sab Saath started when Covid-19 hit Pakistan in March of 2020.
                  We felt that most relief organizations were large,
                  multi-layered and primarily built to provide help in
                  conventional circumstances.
                </p>
                <p>
                  In contrast, Covid-19 relief work demanded a wartime response.
                  A continuous stream of rapid, immediate and nimble responses
                  to a situation that was changing daily.
                </p>
                <p>
                  Additionally, the multi-pronged nature of the response (food,
                  cash, medical and protective kits) meant that this could
                  potentially become an uncoordinated effort since no single
                  organization could handle all 4 of these efforts.
                </p>
                <p>
                  This led to the formation of Sab Saath -onee single platform
                  that coordinated relief across organizations and routed help
                  to where it was most needed. More importantly, our platform
                  itself dispensed immediate and rapid assistance to people much
                  quicker than most, larger organizations.
                </p>
                <p>
                  In our fight against Covid-19, we realized that such a
                  transparent and dynamic platform - where individuals and
                  organizations could come together to become so much more than
                  the sum of their parts - is the need of the hour.
                </p>
                <p>
                  Today, Sab Saath is a platform for verified NGOs across
                  Pakistan. We bring registered and accredited NGOs together to
                  coordinate relief efforts across Pakistan.
                </p>
              </Col>
              <Col md={6}>
                <img src={about01} alt="" style={{ width: "100%" }} />
              </Col>
            </Row>
          </div> */}
        </section>

        <section id="vision-mission" className="section">
          <div className="container">
            <h2 className="works-title-top mb-4">
              <span className="title-bg"> Our Vision & Values </span>
            </h2>
            <Row>
              <Col md={6}>
                <img src={about02} alt="" style={{ width: "100%" }} />
              </Col>
              <Col md={6}>
                <h6 className="mb-2" style={{ textTransform: "capitalize" }}>
                  Mission
                </h6>
                <p>
                  <strong>
                    To become 'the' Crowd-Funded 'super hub' for Charity.
                    <br />
                    What does this mean? It means we aim to:
                  </strong>
                </p>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      Ensure swift, transparent and efficient distribution of
                      funds to deserving families and communities
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      To become a verifying and certifying body for NGOs
                      operating in Pakistan
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      To create a digital help desk for those who want to help
                      and those who are in need
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      To provide a platform for individuals (donors, volunteers,
                      social workers etc.) and organizations to use for
                      collaboration on social welfare and relief projects
                    </span>
                  </li>

                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      To build a robust and dynamic network of philanthropists
                      and philanthropic organizations across the globe
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      To provide consultancy and guidance for budding NGOs and
                      charitable organizations
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
            {/* <Row>
              <Col md={6}>
                <img src={about02} alt="" style={{ width: "100%" }} />
              </Col>
              <Col md={6}>
                <h2 className="mb-2">Vision</h2>

                <ul className="list-group list-group-flush">
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      To create and mobilize a dynamic coalition for a
                      poverty-free Pakistan.
                    </span>
                  </li>
                </ul>
                <h2 className="mb-2">Mission</h2>
                <p>
                  <strong>
                    To become 'the' Crowd-Funded 'super hub' for Charity
                  </strong>
                </p>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      To become a verifying and certifying body for NGOs
                      operating in Pakistan
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      To ensure swift, transparent and efficient distribution of
                      funds to deserving families
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      To create a digital help desk for those who want to help
                      and those who are in need
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      To provide a platform for individuals (donors, volunteers,
                      social workers etc.) and organizations to use for
                      collaboration on social welfare and relief projects
                    </span>
                  </li>

                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      To build a robust and dynamic network of philanthropists
                      and philanthropic organizations across the globe
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      To provide consultancy and guidance for budding NGOs and
                      charitable organizations.
                    </span>
                  </li>
                </ul>
              </Col>
            </Row> */}
          </div>
        </section>
        <section id="our-values" className="section">
          <div className="container" style={{ background: "#EDF2FA" }}>
            <Row>
              <Col md={12}></Col>
            </Row>
            {/* <Container style={{background:'#EDF2FA'}}> */}
            <Col md="8" style={{ marginLeft: "auto", marginRight: "auto" }}>
              <div className="p-4">
                <h4 className="mb-2">Vision</h4>
                <p>
                  To create and mobilize a dynamic coalition for a poverty-free
                  Pakistan.
                </p>
                <h4 className="mb-2">Our Values</h4>

                <ul className="list-group list-group-flush">
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      <strong>Compassion:</strong> alleviate misery and spread
                      joy
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      <strong>Integrity:</strong> to be honest and transparent
                      in all that we do
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      <strong>Sustainability:</strong> to take the torch from
                      the older generation, carry it well and pass it on to the
                      next
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      <strong>Ingenuity:</strong> to leverage technology to
                      maximize our impact
                    </span>
                  </li>

                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      <strong>Excellence:</strong> to adopt and utilize the best
                      global practices
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
            {/* <Col md={6}>
                <img src={about03} alt="" style={{ width: "100%" }} />
              </Col> */}
            {/* </Container> */}
            {/* <Row>
              <Col md={6}>
                <div className="p-4">
                  <h2 className="mb-2">Our Values</h2>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item p-2 d-flex pl-0">
                      {" "}
                      <i className="fa fa-check fa-1x text-red"></i>
                      <span className="pl-3">
                        {" "}
                        <strong>Compassion:</strong> alleviate misery and spread
                        joy
                      </span>
                    </li>
                    <li className="list-group-item p-2 d-flex pl-0">
                      {" "}
                      <i className="fa fa-check fa-1x text-red"></i>
                      <span className="pl-3">
                        {" "}
                        <strong>Integrity:</strong> to be honest and transparent
                        in all that we do
                      </span>
                    </li>
                    <li className="list-group-item p-2 d-flex pl-0">
                      {" "}
                      <i className="fa fa-check fa-1x text-red"></i>
                      <span className="pl-3">
                        {" "}
                        <strong>Sustainability:</strong> to take the torch from
                        the older generation, carry it well and pass it on to
                        the next
                      </span>
                    </li>
                    <li className="list-group-item p-2 d-flex pl-0">
                      {" "}
                      <i className="fa fa-check fa-1x text-red"></i>
                      <span className="pl-3">
                        {" "}
                        <strong>Ingenuity:</strong> to leverage technology to
                        maximize our impact
                      </span>
                    </li>

                    <li className="list-group-item p-2 d-flex pl-0">
                      {" "}
                      <i className="fa fa-check fa-1x text-red"></i>
                      <span className="pl-3">
                        <strong>Excellence:</strong> to adopt and utilize the
                        best global practices
                      </span>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={6}>
                <img src={about03} alt="" style={{ width: "100%" }} />
              </Col>
            </Row> */}
          </div>
        </section>

        <section id="why-trust" className="section">
          <div className="container">
            <Row>
              <Col md={12}></Col>
            </Row>
            <span className="title-bg mb-3"> Why Trust Sab Saath? </span>
            <Col md="9" className="ml-auto mr-auto">
              {/* <h4 className="text-center">
                There are many factors that make Sab Saath stand out as a
                unique, trustworthy and holistic platform to host your
                philanthropic efforts:
              </h4> */}
              <h4 className="text-center">
                At Sab Saath, we uphold the same rigorous standards for
                ourselves as we do for the NGOs on our platform, ensuring that
                all featured organizations maintain the required accreditation
                and compliances:
              </h4>
            </Col>

            <Col md="8" style={{ marginLeft: "auto", marginRight: "auto" }}>
              <div className="p-4">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3"> Incorporation Deed</span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      Tax Exemption Certificate, Federal Board of Revenue
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      Economic Affairs Division Certificate, GoP
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      Provincial/National Commission Certificate, GoP
                    </span>
                  </li>
                </ul>
                {/* <ul className="list-group list-group-flush">
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      100% of your donations go to charity.
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      Private donors cover all administrative costs.
                      in all that we do
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      Our thorough investigation policies ensure the genuineness of every case.
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      We utilize omni-channel money transfer solutions across Pakistan.
                      maximize our impact
                    </span>
                  </li>

                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                    We design holistic, tailored approaches for every individual/family.
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                    We practice equality using an unbiased, first-come-first-serve basis.
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                    We maintain direct correspondence with relief organizations and institutions to guarantee that payments are used as intended. 
                    </span>
                  </li>
                </ul> */}
              </div>
            </Col>
            {/* <Col md="8" style={{ marginLeft: "auto", marginRight: "auto" }}>
              <div className="p-4">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      <strong>Compassion:</strong> alleviate misery and spread
                      joy
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      <strong>Integrity:</strong> to be honest and transparent
                      in all that we do
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      <strong>Sustainability:</strong> to take the torch from
                      the older generation, carry it well and pass it on to the
                      next
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      {" "}
                      <strong>Ingenuity:</strong> to leverage technology to
                      maximize our impact
                    </span>
                  </li>

                  <li className="list-group-item p-2 d-flex pl-0">
                    {" "}
                    <i className="fa fa-check fa-1x text-red"></i>
                    <span className="pl-3">
                      <strong>Excellence:</strong> to adopt and utilize the best
                      global practices
                    </span>
                  </li>
                </ul>
              </div>
            </Col> */}
            {/* <Col md={6}>
                <img src={about03} alt="" style={{ width: "100%" }} />
              </Col>  */}
            {/* </Container>  */}
            {/* <Row>
              <Col md={6}>
                <div className="p-4">
                  <h2 className="mb-2">Our Values</h2>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item p-2 d-flex pl-0">
                      {" "}
                      <i className="fa fa-check fa-1x text-red"></i>
                      <span className="pl-3">
                        {" "}
                        <strong>Compassion:</strong> alleviate misery and spread
                        joy
                      </span>
                    </li>
                    <li className="list-group-item p-2 d-flex pl-0">
                      {" "}
                      <i className="fa fa-check fa-1x text-red"></i>
                      <span className="pl-3">
                        {" "}
                        <strong>Integrity:</strong> to be honest and transparent
                        in all that we do
                      </span>
                    </li>
                    <li className="list-group-item p-2 d-flex pl-0">
                      {" "}
                      <i className="fa fa-check fa-1x text-red"></i>
                      <span className="pl-3">
                        {" "}
                        <strong>Sustainability:</strong> to take the torch from
                        the older generation, carry it well and pass it on to
                        the next
                      </span>
                    </li>
                    <li className="list-group-item p-2 d-flex pl-0">
                      {" "}
                      <i className="fa fa-check fa-1x text-red"></i>
                      <span className="pl-3">
                        {" "}
                        <strong>Ingenuity:</strong> to leverage technology to
                        maximize our impact
                      </span>
                    </li>

                    <li className="list-group-item p-2 d-flex pl-0">
                      {" "}
                      <i className="fa fa-check fa-1x text-red"></i>
                      <span className="pl-3">
                        <strong>Excellence:</strong> to adopt and utilize the
                        best global practices
                      </span>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={6}>
                <img src={about03} alt="" style={{ width: "100%" }} />
              </Col>
            </Row>  */}
          </div>
        </section>
        {/* <section id="what-we-do" className="section bg-lred">
          <div className="container">
            <Row>
              <Col md={6}>
                <img src={about04} alt="" style={{ width: "100%" }} />
              </Col>
              <Col md={6} className="p-5">
                <h2 className="mb-2">What We Do</h2>
                <p>
                  We are a platform that connects those who want to help with
                  those who need it. Moreover, we ensure all cases are
                  thoroughly investigated and verified by our team of
                  investigation officers. In places where we don’t have boots on
                  the ground, our certified partner organizations do the
                  verifications for us.
                </p>
              </Col>
            </Row>
          </div>
        </section> */}

        {/* <section id="how-we-work" className="section">
          <div className="container">
            <Row>
              <Col md={12} className="text-center">
                <h2 className="mb-2">How We Work</h2>
              </Col>
            </Row>
            <div className="text-center">
              <img src={processimg1} alt="" />
            </div>
          </div>
        </section> */}

        {/* <section id="what-makes-us-unique" className="section bg-red">
          <div className="container">
            <Row>
              <Col md={6} className="p-2">
                <h2 className="mb-2">What Makes Us Unique</h2>
                <ul className="list-group list-group-flush list-glunique">
                  <li className="list-group-item p-2 d-flex pl-0">
                    <i className="fa fa-check fa-2x text-light"></i>
                    <span className="pl-3">
                      <h4>100% of your donations go to charity</h4> Private
                      donors cover all administrative costs.
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    <i className="fa fa-check fa-2x text-light"></i>
                    <span className="pl-3">
                      <h4>Thorough investigation policy</h4> We ensure
                      genuineness of each case.
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    <i className="fa fa-check fa-2x text-light"></i>
                    <span className="pl-3">
                      <h4>
                        Omni-channel money transfer solutions across Pakistan
                      </h4>
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    <i className="fa fa-check fa-2x text-light"></i>
                    <span className="pl-3">
                      <h4>Holistic approach</h4> Tailor-made solutions for every
                      individual/family.
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    <i className="fa fa-check fa-2x text-light"></i>
                    <span className="pl-3">
                      <h4>We practice equality</h4> All cases are tended to on a
                      first-come-first-serve basis.
                    </span>
                  </li>
                  <li className="list-group-item p-2 d-flex pl-0">
                    <i className="fa fa-check fa-2x text-light"></i>
                    <span className="pl-3">
                      <h4>Direct engagement with institutions </h4> To guarantee
                      that payments are used as intended, we liaison with
                      institutions for direct correspondence.
                    </span>
                  </li>
                </ul>
              </Col>
              <Col md={6}>
                <img src={about05} alt="" style={{ width: "100%" }} />
              </Col>
            </Row>
          </div>
        </section> */}

        <section id="our-partner" className="section ">
          <div className="container">
            <Row>
              <Col md={12} className="p-2">
                <Ourpartners />
              </Col>
            </Row>
          </div>
        </section>

        {/* <section className="section bg-lred">
          <div className="container">
            <Row>
              <Col></Col>
              <Col md={8} className="text-center">
                <div className="volunteer-box">
                  <h2 className="mb-2">Become a Volunteer</h2>
                  <Link to="/volunteer" className="btn btn-secondary">
                    Join Now
                  </Link>
                </div>
              </Col>
              <Col></Col>
            </Row>
          </div>
        </section> */}
      </div>

      <HomeFooter />
    </div>
  );
};

export default AboutUs;
