import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import Swal from "sweetalert2"
import { balf_baseUrl, fetchData, merchantID } from "utils/Api"
import BAFL_Security from "assets/img/ba-pay-securely.png"
import pcidss from "assets/img/pcidss-img.png"
import Threedsimg from "assets/img/3ds-img.png"
import moment from "moment"
import { ConvertNumricToComaSeparate } from "utils/CommonMethods"

const CheckoutCard = ({
  setTransactionID,
  amount,
  currency,
  query,
  loading,
  setLoading,
  setFormLoading,
  payBalfPayment,
  BasicInfoValues,
  setBasicInfoValues,
  CheckOutMarkPaymentStatus,
  currentCurrencyFromSymbol,
  currentCurrencyAmount,
  AmountInPKR,
  pay,
}) => {
  const [returnedResponse, setReturnedResponse] = useState(null)
  const [sessionID, setSessionID] = useState(null);

  const getSessionID = async () => {
    try {
      let _requestData = {
        DonationDetail: {
          TotalAmount: Math.round(AmountInPKR),
        },
      }
      const data = await fetchData("WebSite", "BAFL_Session_API", _requestData)
      if (data == "-1") {
        return Swal.fire({
          title: "Something went wrong",
          icon: "info",
          allowEscapeKey: false,
          backdrop: false,
          text: "Please try again",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          } else {
            window.location.reload()
          }
        })
      }
      console.log('bankTransactionID',data);
      setBasicInfoValues({...BasicInfoValues,BankTransactionID:data?.bankTransactionID})
      setSessionID(data.sessionID);
      setTransactionID(data.bankTransactionID);
      localStorage.setItem('trid',data.bankTransactionID);

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const sID = query.get("sessionID")
    const oID = query.get("orderID")
    const amnt = query.get("amount")
    const currency = query.get("currency")
    if (sID && oID) {
      setReturnedResponse({
        sessionID: sID,
        orderID: oID,
      })
      CheckOutMarkPaymentStatus(oID, sID, amnt, currency)
    } else {
      setTimeout(() => {
        getSessionID()
      }, 2000)
    }
  }, [])

  useEffect(() => {
    const fn = async () => {
      if (!returnedResponse && sessionID) {
        window?.PaymentSession?.configure({
          session: sessionID,
          fields: {
            // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
            card: {
              number: "#card-number",
              securityCode: "#security-code",
              expiryMonth: "#expiry-month",
              expiryYear: "#expiry-year",
              nameOnCard: "#cardholder-name",
            },
          },
          //SPECIFY YOUR MITIGATION OPTION HERE
          frameEmbeddingMitigation: ["javascript"],
          callbacks: {
            initialized: function (response) {
              // HANDLE INITIALIZATION RESPONSE
              console.log("initialize response", response)
              // axios.get(
              //   `https://test-bankalfalah.gateway.mastercard.com/form/version/60/merchant/ZAMAN/session/${newID}`
              // )
              setLoading(false);
              setFormLoading(false);
            },

            formSessionUpdate: function (response) {
              // HANDLE RESPONSE FOR UPDATE SESSION
              if (response.status) {
                if ("ok" == response.status) {
                  console.log("Session updated with data:a ", response)

                  setLoading(true)
                  // initAuth()
                  payBalfPayment(response.session.id)

                  //check if the security code was provided by the user
                  if (response.sourceOfFunds.provided.card.securityCode) {
                    console.log("Security code was provided. ")
                  }

                  //check if the user entered a Mastercard credit card
                  if (
                    response.sourceOfFunds.provided.card.scheme == "MASTERCARD"
                  ) {
                    console.log("The user entered a Mastercard credit card.")
                  }
                } else if ("fields_in_error" == response.status) {
                  console.log("Session update failed with field errors.")
                  if (response.errors.cardNumber) {
                    console.log("Card number invalid.")
                    setLoading(false);
              setFormLoading(false);

                    Swal.fire({
                      title: "Error",
                      text: "Invalid card number.",
                      icon: "error",
                      allowOutsideClick: false,
                    });
                    return
                  }
                  if (response.errors.expiryMonth) {
                    console.log("Expiry month invalid or missing.")
                    setLoading(false)
                    setFormLoading(false);

                    return Swal.fire({
                      title: "Error",
                      text: "Invalid expiry month.",
                      icon: "error",
                      allowOutsideClick: false,
                    })
                  }
                  if (response.errors.expiryYear) {
                    console.log("Expiry year invalid or missing.")
                    setLoading(false)
                    setFormLoading(false);

                    return Swal.fire({
                      title: "Error",
                      text: "Invalid expiry year.",
                      icon: "error",
                      allowOutsideClick: false,
                    });
                    
                  }
                  
                  if (response.errors.securityCode) {
                    console.log("Security code invalid.")
                    setLoading(false)
                    setFormLoading(false);

                    return Swal.fire({
                      title: "Error",
                      text: "Invalid Security code.",
                      icon: "error",
                      allowOutsideClick: false,
                    })
                  }
                } else if ("request_timeout" == response.status) {
                  console.log(
                    "Session update failed with request timeout: " +
                      response.errors.message
                  )
                  setLoading(false)
                  setFormLoading(false);

                  return Swal.fire({
                    title: "Error",
                    text: "Session update failed. " + response.errors.message,
                    icon: "error",
                    allowOutsideClick: false,
                  })
                } else if ("system_error" == response.status) {
                  console.log(
                    "Session update failed with system error: " +
                      response.errors.message
                  )
                  setLoading(false)
                  setFormLoading(false);

                  return Swal.fire({
                    title: "Error",
                    text: "Session update failed. " + response.errors.message,
                    icon: "error",
                    allowOutsideClick: false,
                  })
                }
              } else {
                console.log("Session update failed: " + response)
                setLoading(false)
                setFormLoading(false);

                return Swal.fire({
                  title: "Error",
                  text: "Session update failed.",
                  icon: "error",
                  allowOutsideClick: false,
                })
              }
            },
          },
          interaction: {
            displayControl: {
              formatCard: "EMBOSSED",
              invalidFieldCharacters: "REJECT",
            },
          },
        })
      }
    }
    fn()
  }, [sessionID])
  useEffect(() => {
    const script = document.createElement("script")
    script.src = `${balf_baseUrl}/form/version/74/merchant/${merchantID}/session.js`
    script.async = true

    document.body.appendChild(script)

    const styleElement = document.createElement("style")
    styleElement.id = "antiClickjack"

    // Set the CSS rules for the element
    const cssText = `
      body {
        display: none !important;
      }
    `
    styleElement.appendChild(document.createTextNode(cssText))

    // Append the <style> element to the <head> of the document
    document.head.appendChild(styleElement)

    // Create a new <script> element
    const scriptElement = document.createElement("script")
    scriptElement.type = "text/javascript"

    // Set the JavaScript code for the element
    const jsCode = `
      if (self === top) {
        var antiClickjack = document.getElementById("antiClickjack");
        antiClickjack.parentNode.removeChild(antiClickjack);
      } else {
        top.location = self.location; 
      }
    `
    scriptElement.appendChild(document.createTextNode(jsCode))

    // Append the <script> element to the <head> of the document
    document.head.appendChild(scriptElement)
  }, [])

  return (
    <>
      {
        <div className="payment-form">
          <h4 className="pt-2 pb-2">Payment Info </h4>
          <p style={{ fontSize: "12px" }}>
            If you are not a <b>Visa</b> or <b>Mastercard</b> holder, scroll
            down for Direct Bank Transfer details. Having trouble donating?{" "}
            <a href="https://wa.me/3018444959" target="_blank" rel="noreferrer">
              Chat with us
            </a>
          </p>
          <div>
            {/* <label className="form-label">Card Number:</label> */}
            <input
              type="text"
              id="card-number"
              className="input-field form-control"
              placeholder="Card Number"
              title="card number"
              aria-label="enter your card number"
              defaultValue
              tabIndex={1}
              readOnly
            />
          </div>
          <Row>
            <Col md={6}>
              {/* <label className="form-label">Expiry Month:</label> */}
              <input
                type="text"
                placeholder="Expiry Month"
                id="expiry-month"
                className="input-field form-control me-2"
                title="expiry month"
                aria-label="two digit expiry month"
                defaultValue
                tabIndex={2}
                readOnly
              />
            </Col>
            <Col md={6}>
              {/* <label className="form-label">Expiry Year:</label> */}
              <input
                type="text"
                placeholder="Expiry Year"
                id="expiry-year"
                className="input-field form-control"
                title="expiry year"
                aria-label="two digit expiry year"
                defaultValue
                tabIndex={3}
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              {/* <label className="form-label">CVV:</label> */}
              <input
                type="text"
                placeholder="CVV"
                id="security-code"
                className="input-field form-control"
                title="security code"
                aria-label="three digit CCV security code"
                defaultValue
                tabIndex={4}
                readOnly
              />
            </Col>
            <Col md={8}>
              {/* <label className="form-label">Cardholder Name:</label> */}
              <input
                type="text"
                placeholder="Cardholder Name"
                id="cardholder-name"
                className="input-field form-control"
                title="cardholder name"
                aria-label="enter name on card"
                defaultValue
                tabIndex={5}
                readOnly
              />
            </Col>
          </Row>
          <div>
            <button
              id="payButton"
              className="btn btn-primary btn-block"
              onClick={pay}
            >
              {"PAY " +
                (currentCurrencyAmount === undefined
                  ? 0
                  : ConvertNumricToComaSeparate(
                      currentCurrencyAmount.toFixed(2)
                    ))}{" "}
              {currentCurrencyFromSymbol === undefined
                ? ""
                : currentCurrencyFromSymbol}
            </button>
          </div>
          <div className="row mb-1 mt-3">
            <div className="col-lg-12 text-center">
              <img src={BAFL_Security} alt="SabSaath" height={"62%"} />
            </div>
          </div>
          <div className="row mb-2 mt-2">
            <div className="col-lg-6 text-center">
              <img height="50" src={pcidss} alt="SabSaath" />
            </div>
            <div className="col-lg-6 text-center">
              <img height="50" src={Threedsimg} alt="SabSaath" />
            </div>
          </div>
          <p className="text-center">
            We strictly care for your privacy and never store your card
            information.{" "}
            <a
              href="/TermsAndConditions#Payment-Card-Security"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </p>
        </div>
      }
    </>
  )
}

export default CheckoutCard


// import React, { useEffect, useState } from "react"
// import { Col, Row } from "reactstrap"
// import Swal from "sweetalert2"
// import { balf_baseUrl, fetchData, merchantID } from "utils/Api"
// import BAFL_Security from "assets/img/ba-pay-securely.png"
// import pcidss from "assets/img/pcidss-img.png"
// import Threedsimg from "assets/img/3ds-img.png"
// import moment from "moment"
// import { ConvertNumricToComaSeparate } from "utils/CommonMethods"

// const CheckoutCard = ({
//   amount,
//   currency,
//   query,
//   loading,
//   setLoading,
//   setFormLoading,
//   payBalfPayment,
//   BasicInfoValues,
//   CheckOutMarkPaymentStatus,
//   currentCurrencyFromSymbol,
//   currentCurrencyAmount,
//   AmountInPKR,
//   pay,
// }) => {
//   const [returnedResponse, setReturnedResponse] = useState(null)
//   const [sessionID, setSessionID] = useState(null)

//   const getSessionID = async () => {
//     try {
//       let _requestData = {
//         DonationDetail: {
//           TotalAmount: Math.round(AmountInPKR),
//         },
//       }
//       const data = await fetchData("WebSite", "BAFL_Session_API", _requestData)
//       if (data == "-1") {
//         return Swal.fire({
//           title: "Something went wrong",
//           icon: "info",
//           allowEscapeKey: false,
//           backdrop: false,
//           text: "Please try again",
//         }).then((result) => {
//           if (result.isConfirmed) {
//             window.location.reload()
//           } else {
//             window.location.reload()
//           }
//         })
//       }
//       setSessionID(data)
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   useEffect(() => {
//     const sID = query.get("sessionID")
//     const oID = query.get("orderID")
//     const amnt = query.get("amount")
//     const currency = query.get("currency")
//     if (sID && oID) {
//       setReturnedResponse({
//         sessionID: sID,
//         orderID: oID,
//       })
//       CheckOutMarkPaymentStatus(oID, sID, amnt, currency)
//     } else {
//       setTimeout(() => {
//         getSessionID()
//       }, 2000)
//     }
//   }, [])

//   useEffect(() => {
//     const fn = async () => {
//       if (!returnedResponse && sessionID) {
//         window?.PaymentSession?.configure({
//           session: sessionID,
//           fields: {
//             // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
//             card: {
//               number: "#card-number",
//               securityCode: "#security-code",
//               expiryMonth: "#expiry-month",
//               expiryYear: "#expiry-year",
//               nameOnCard: "#cardholder-name",
//             },
//           },
//           //SPECIFY YOUR MITIGATION OPTION HERE
//           frameEmbeddingMitigation: ["javascript"],
//           callbacks: {
//             initialized: function (response) {
//               // HANDLE INITIALIZATION RESPONSE
//               console.log("initialize response", response)
//               // axios.get(
//               //   `https://test-bankalfalah.gateway.mastercard.com/form/version/60/merchant/ZAMAN/session/${newID}`
//               // )
//               setLoading(false);
//               setFormLoading(false);
//             },

//             formSessionUpdate: function (response) {
//               // HANDLE RESPONSE FOR UPDATE SESSION
//               if (response.status) {
//                 if ("ok" == response.status) {
//                   console.log("Session updated with data:a ", response)

//                   setLoading(true)
//                   // initAuth()
//                   payBalfPayment(response.session.id)

//                   //check if the security code was provided by the user
//                   if (response.sourceOfFunds.provided.card.securityCode) {
//                     console.log("Security code was provided. ")
//                   }

//                   //check if the user entered a Mastercard credit card
//                   if (
//                     response.sourceOfFunds.provided.card.scheme == "MASTERCARD"
//                   ) {
//                     console.log("The user entered a Mastercard credit card.")
//                   }
//                 } else if ("fields_in_error" == response.status) {
//                   console.log("Session update failed with field errors.")
//                   if (response.errors.cardNumber) {
//                     console.log("Card number invalid.")
//                     setLoading(false);
//               setFormLoading(false);

//                     Swal.fire({
//                       title: "Error",
//                       text: "Invalid card number.",
//                       icon: "error",
//                       allowOutsideClick: false,
//                     });
//                     return
//                   }
//                   if (response.errors.expiryMonth) {
//                     console.log("Expiry month invalid or missing.")
//                     setLoading(false)
//                     setFormLoading(false);

//                     return Swal.fire({
//                       title: "Error",
//                       text: "Invalid expiry month.",
//                       icon: "error",
//                       allowOutsideClick: false,
//                     })
//                   }
//                   if (response.errors.expiryYear) {
//                     console.log("Expiry year invalid or missing.")
//                     setLoading(false)
//                     setFormLoading(false);

//                     return Swal.fire({
//                       title: "Error",
//                       text: "Invalid expiry year.",
//                       icon: "error",
//                       allowOutsideClick: false,
//                     });
                    
//                   }
                  
//                   if (response.errors.securityCode) {
//                     console.log("Security code invalid.")
//                     setLoading(false)
//                     setFormLoading(false);

//                     return Swal.fire({
//                       title: "Error",
//                       text: "Invalid Security code.",
//                       icon: "error",
//                       allowOutsideClick: false,
//                     })
//                   }
//                 } else if ("request_timeout" == response.status) {
//                   console.log(
//                     "Session update failed with request timeout: " +
//                       response.errors.message
//                   )
//                   setLoading(false)
//                   setFormLoading(false);

//                   return Swal.fire({
//                     title: "Error",
//                     text: "Session update failed. " + response.errors.message,
//                     icon: "error",
//                     allowOutsideClick: false,
//                   })
//                 } else if ("system_error" == response.status) {
//                   console.log(
//                     "Session update failed with system error: " +
//                       response.errors.message
//                   )
//                   setLoading(false)
//                   setFormLoading(false);

//                   return Swal.fire({
//                     title: "Error",
//                     text: "Session update failed. " + response.errors.message,
//                     icon: "error",
//                     allowOutsideClick: false,
//                   })
//                 }
//               } else {
//                 console.log("Session update failed: " + response)
//                 setLoading(false)
//                 setFormLoading(false);

//                 return Swal.fire({
//                   title: "Error",
//                   text: "Session update failed.",
//                   icon: "error",
//                   allowOutsideClick: false,
//                 })
//               }
//             },
//           },
//           interaction: {
//             displayControl: {
//               formatCard: "EMBOSSED",
//               invalidFieldCharacters: "REJECT",
//             },
//           },
//         })
//       }
//     }
//     fn()
//   }, [sessionID])
//   useEffect(() => {
//     const script = document.createElement("script")
//     script.src = `${balf_baseUrl}/form/version/60/merchant/${merchantID}/session.js`
//     script.async = true

//     document.body.appendChild(script)

//     const styleElement = document.createElement("style")
//     styleElement.id = "antiClickjack"

//     // Set the CSS rules for the element
//     const cssText = `
//       body {
//         display: none !important;
//       }
//     `
//     styleElement.appendChild(document.createTextNode(cssText))

//     // Append the <style> element to the <head> of the document
//     document.head.appendChild(styleElement)

//     // Create a new <script> element
//     const scriptElement = document.createElement("script")
//     scriptElement.type = "text/javascript"

//     // Set the JavaScript code for the element
//     const jsCode = `
//       if (self === top) {
//         var antiClickjack = document.getElementById("antiClickjack");
//         antiClickjack.parentNode.removeChild(antiClickjack);
//       } else {
//         top.location = self.location; 
//       }
//     `
//     scriptElement.appendChild(document.createTextNode(jsCode))

//     // Append the <script> element to the <head> of the document
//     document.head.appendChild(scriptElement)
//   }, [])

//   return (
//     <>
//       {
//         <div className="payment-form">
//           <h4 className="pt-2 pb-2">Payment Info </h4>
//           <p style={{ fontSize: "12px" }}>
//             If you are not a <b>Visa</b> or <b>Mastercard</b> holder, scroll
//             down for Direct Bank Transfer details. Having trouble donating?{" "}
//             <a href="https://wa.me/3018444959" target="_blank" rel="noreferrer">
//               Chat with us
//             </a>
//           </p>
//           <div>
//             {/* <label className="form-label">Card Number:</label> */}
//             <input
//               type="text"
//               id="card-number"
//               className="input-field form-control"
//               placeholder="Card Number"
//               title="card number"
//               aria-label="enter your card number"
//               defaultValue
//               tabIndex={1}
//               readOnly
//             />
//           </div>
//           <Row>
//             <Col md={6}>
//               {/* <label className="form-label">Expiry Month:</label> */}
//               <input
//                 type="text"
//                 placeholder="Expiry Month"
//                 id="expiry-month"
//                 className="input-field form-control me-2"
//                 title="expiry month"
//                 aria-label="two digit expiry month"
//                 defaultValue
//                 tabIndex={2}
//                 readOnly
//               />
//             </Col>
//             <Col md={6}>
//               {/* <label className="form-label">Expiry Year:</label> */}
//               <input
//                 type="text"
//                 placeholder="Expiry Year"
//                 id="expiry-year"
//                 className="input-field form-control"
//                 title="expiry year"
//                 aria-label="two digit expiry year"
//                 defaultValue
//                 tabIndex={3}
//                 readOnly
//               />
//             </Col>
//           </Row>
//           <Row>
//             <Col md={4}>
//               {/* <label className="form-label">CVV:</label> */}
//               <input
//                 type="text"
//                 placeholder="CVV"
//                 id="security-code"
//                 className="input-field form-control"
//                 title="security code"
//                 aria-label="three digit CCV security code"
//                 defaultValue
//                 tabIndex={4}
//                 readOnly
//               />
//             </Col>
//             <Col md={8}>
//               {/* <label className="form-label">Cardholder Name:</label> */}
//               <input
//                 type="text"
//                 placeholder="Cardholder Name"
//                 id="cardholder-name"
//                 className="input-field form-control"
//                 title="cardholder name"
//                 aria-label="enter name on card"
//                 defaultValue
//                 tabIndex={5}
//                 readOnly
//               />
//             </Col>
//           </Row>
//           <div>
//             <button
//               id="payButton"
//               className="btn btn-primary btn-block"
//               onClick={pay}
//             >
//               {"PAY " +
//                 (currentCurrencyAmount === undefined
//                   ? 0
//                   : ConvertNumricToComaSeparate(
//                       currentCurrencyAmount.toFixed(2)
//                     ))}{" "}
//               {currentCurrencyFromSymbol === undefined
//                 ? ""
//                 : currentCurrencyFromSymbol}
//             </button>
//           </div>
//           <div className="row mb-1 mt-3">
//             <div className="col-lg-12 text-center">
//               <img src={BAFL_Security} alt="SabSaath" height={"62%"} />
//             </div>
//           </div>
//           <div className="row mb-2 mt-2">
//             <div className="col-lg-6 text-center">
//               <img height="50" src={pcidss} alt="SabSaath" />
//             </div>
//             <div className="col-lg-6 text-center">
//               <img height="50" src={Threedsimg} alt="SabSaath" />
//             </div>
//           </div>
//           <p className="text-center">
//             We strictly care for your privacy and never store your card
//             information.{" "}
//             <a
//               href="/TermsAndConditions#Payment-Card-Security"
//               target={"_blank"}
//               rel="noopener noreferrer"
//             >
//               Learn More
//             </a>
//           </p>
//         </div>
//       }
//     </>
//   )
// }

// export default CheckoutCard
