import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Label,
  Input,
} from "reactstrap";
import { fetchData } from "../utils/Api.js";
import {
  ApiMethods,
  ControllerName,
  OperationTypeId,
  SetupMasterIds,
} from "../utils/Constants.js";
import {
  GetSetupMaster,
  DeleteSetupDetail,
  AllowAlphabatic,
} from "../utils/CommonMethods.js";
import ModalCountry from "../components/modal/ModalCountry.js";
import Swal from "sweetalert2";
import ModalHospital from "components/modal/ModalHopital.js";

const initialValues = {
  HospitalName: "",
  Location: "",
  CityId: 0,
  Contact: "",
};

const Country = (props) => {
  const [countryList, setcountryList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [countryEditId, setcountryEditId] = useState(0);
  const [countryvalues, setcountryvalues] = useState("");
  const [formFields, setFormFields] = useState(initialValues);
  const [editData, setEditData] = useState({});
  const [countryDialVal, setcountryDialVal] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  React.useEffect(() => {
    // need to define the function and call it separately
    const load = async () => {
      ReBindGrid(SetupMasterIds.Country, 0, "", 0);
    };
    load();
  }, []);

  const onEdit = (row) => {
    setEditData(row);
    openNewmodal();
  };

  const onDelete = async ({ CountryId }) => {
    var UserId = localStorage.getItem("UserId");
    var UserIp = localStorage.getItem("UserIP");

    var deleteData = await DeleteSetupDetail(CountryId, UserId, UserIp);
    if (deleteData.response === true && deleteData.data != null) {
      if (deleteData.data[0].HasError === 1) {
        //alert(deleteData.data[0].Message);
        Swal.fire({
          title: "Error",
          text: deleteData.data[0].Message,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Success",
          text: "Deleted Successfully",
          icon: "success",
        });
      }
    }
    ReBindGrid(SetupMasterIds.Country, 0, "", 0);
    setcountryvalues("");
    setcountryDialVal("");
  };

  const handleSearchClick = async (e) => {
    e.preventDefault();
    console.log("search", formFields);
    // ReBindGrid(SetupMasterIds.Country, 0, countryvalues, 0, countryDialVal);
  };

  const handleCancelClick = async (e) => {
    e.preventDefault();
    setFormFields(initialValues);
    ReBindGrid(SetupMasterIds.Country, 0, "", 0, countryDialVal);
  };

  const ReBindGrid = async (
    setupMasterId,
    parentId,
    setupDetailName,
    setupDetailId = 0,
    Flex1
  ) => {
    var data = await GetSetupMaster(
      setupMasterId,
      parentId,
      setupDetailName,
      setupDetailId,
      Flex1
    );

    setcountryList(data.data);
  };
  const openNewmodal = () => {
    setOpenModal(true);
  };
  const closeNewmodal = () => {
    setOpenModal(false);
    // setcountryEditId(0);
    setEditData({});
    ReBindGrid(SetupMasterIds.Country, 0, "", 0);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg={12} md={12}>
            <Card className="card-user">
              <CardBody>
                <Form>
                  <Row form>
                    <Col className="px-1" md="6">
                      <FormGroup>
                        <Label>Hospital Name *</Label>
                        <Input
                          placeholder="Hospital Name"
                          type="text"
                          required={true}
                          name="HospitalName"
                          onChange={handleChange}
                          value={formFields?.HospitalName}
                          autoComplete="off"
                          // maxLength="50"
                          //pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="6">
                      <FormGroup>
                        <Label>Location</Label>
                        <Input
                          placeholder="Location"
                          type="text"
                          required={true}
                          name="Location"
                          onChange={handleChange}
                          value={formFields?.Location}
                          autoComplete="off"
                          // maxLength="50"
                          //pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="6">
                      <FormGroup>
                        <Label for="">City</Label>
                        <Input
                          id="exampleSelect"
                          name="CityId"
                          type="select"
                          value={formFields?.CityId}
                          onChange={handleChange}
                        >
                          <option key={-1} value={-1}>
                            Select
                          </option>
                          {[1, 2, 3].map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                          {/* {categoryddl.map((item, key) => (
                                        <option key={key} value={item.SetupDetailId}>
                                            {item.SetupDetailName}
                                        </option>
                                    ))} */}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="6">
                      <FormGroup>
                        <Label>Contact</Label>
                        <Input
                          placeholder="Contact"
                          type="text"
                          required={true}
                          name="Contact"
                          onChange={handleChange}
                          value={formFields?.Contact}
                          autoComplete="off"
                          // maxLength="50"
                          //pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"
                        />
                      </FormGroup>
                    </Col>

                    <Col md={12} className="text-right">
                      <Button
                        color="primary"
                        size="sm"
                        className="mr-2"
                        onClick={handleSearchClick}
                      >
                        Search
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12}>
            <Card>
              <CardHeader>
                <Row>
                  <Col lg={6} md={6}>
                    Country List
                  </Col>
                  <Col lg={6} md={6} className="text-right">
                    <Button
                      color="primary2"
                      size="sm"
                      className="m-0"
                      onClick={() => openNewmodal({ CountryId: 0 })}
                    >
                      Add New
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table bordered striped>
                  <thead>
                    <tr>
                      <th>Sr #</th>
                      <th>Name</th>
                      <th>City</th>
                      <th>Location</th>
                      <th>Contact</th>
                      {/* <th>Active</th>
                                            <th>CreatedBy</th>
                                            <th>Last Modified By</th> */}
                      <th className="text-center" style={{ width: 150 }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {countryList &&
                      countryList.map((item, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{item.SetupDetailName}</td>
                          <td>{item.Flex1}</td>
                          {/* <td>{item.IsActive ? "Yes" : "No"}</td>
                                                <td>{item.CreatedBy + ' on ' + item.CreatedDate}</td>
                                                <td>{item.ModifiedBy + " " + (item.ModifiedDate == '' ? "" : "on" + item.ModifiedDate)}</td> */}
                          <td className="text-center">
                            <Button
                              color="primary"
                              className="btn-circle"
                              size="sm"
                              onClick={() => onEdit(item)}
                            >
                              <i className="nc-icon nc-ruler-pencil"></i>
                            </Button>
                            <Button
                              color="danger"
                              className="btn-circle"
                              size="sm"
                              onClick={() =>
                                onDelete({ CountryId: item.SetupDetailId })
                              }
                            >
                              <i className="nc-icon nc-simple-remove"></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {openModal && (
          <ModalHospital
            {...props}
            HeaderText="Add/Edit Hospital"
            Ismodalshow={openModal}
            closeNewmodal={closeNewmodal}
            // CountryId={countryEditId}
            data={editData}
            ReBindGrid={ReBindGrid}
          />
        )}
      </div>
    </>
  );
};

export default Country;
