import FormGroupButton from "components/GeneralComponent/FormGroupButton";
import FormGroupCheckbox from "components/GeneralComponent/FormGroupCheckbox";
import FormGroupDatePicker from "components/GeneralComponent/FormGroupDatePicker";
import FormGroupInput from "components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "components/GeneralComponent/FormGroupTable";
import ModalApplHistory from "components/modal/ModalApplHistory";
import useEditRole from "hooks/useEditRole";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";
import { baseImageUrl, baseUrl, fetchData } from "utils/Api";
import { ControllerName } from "utils/Constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  AllowAlphaNumeric,
  AllowAlphabatOnly,
  AllowFirstAphabetAndNumbers,
  AllowOnlyNumeric,
  dateFormat,
  dateFormatPlaceholder,
  getDate,
} from "utils/CommonMethods";
import axios from "axios";
import moment from "moment";

const initialValues = {
  ApplicantCase_InvestigationId: 0,
  ApplicantFamilyDetailId: 0,
  Name: "",
  Cnic: "",
  Mother_Father_HusbandName: "",
  DateOfBirth: "",
  IsDeceased: false,
  DateOfDeath: "",
  RelationId: "",
  ReligionId: 171,
  GenderId: "",
  MaritalStatusId: "",
  IsPartOfBannedOrg: false,
  IsInvolveInCriminalActivity: false,
  HasMedicalHistory: false,
  ContactTypeId: null,
  ContactNumber: "", // change null to string
  Remarks: "",
  CanRead: false,
  CanWrite: false,
  IsEmployeed: false,
  IsJobList: false,
  JobRemarks: "",
  LastWorkExperience: "",
  UserId: localStorage.getItem("UserId"),
  UserIP: localStorage.getItem("UserIP"),
  Orphan: false,
  FamilyMemberPicture: "",
};

const initialSelectLists = {
  GenderList: [],
  ContactTypeList: [],
  ReligionList: [],
  CountryList: [],
  ProvinceList: [],
  RelationList: [],
  MaritalStatusList: [],
};

const columns = [
  {
    field: "Name",
    name: "Name",
  },
  {
    // field: "RelationId",
    field: "Relation",
    name: "Relation",
  },
  {
    field: "Cnic",
    name: "CNIC",
  },
  {
    field: "DateOfBirth",
    name: "Date Of Birth",
  },
  {
    field: "Religion",
    name: "Religion",
  },
  {
    field: "Gender",
    name: "Gender",
  },
  {
    field: "IsJobList",
    name: "Transfer to Joblist",
  },
  {
    field: "ContactNumber",
    name: "Contact Number",
  },
  {
    field: "JobRemarks",
    name: "Job Remarks",
  },
  {
    field: "MaritalStatus",
    name: "Marital Status",
  },
  {
    field: "Remarks",
    name: "Remarks",
  },
  {
    field: "CreatedDate",
    name: "Created Date",
    type: "date",
  },
  // {
  //   field: "UpdatedDate",
  //   name: "Updated Date",
  //   type: "date",
  // },
];

const FamilyMemberInformation = (props) => {
  const fileInputRef = useRef(null);

  const [role, appId] = useEditRole();
  const [editable, setEditable] = useState(false);
  const [editId, setEditId] = useState(0);
  const [formFields, setFormFields] = useState({
    ...initialValues,
    ApplicantCase_InvestigationId: appId,
  });
  const [selectionLists, setSelectionLists] = useState(initialSelectLists);
  const [formLoading, setFormLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [familyMemberList, setFamilyMemberList] = useState([]);
  const [paymentScheduleExist, setpaymentScheduleExist] = useState(false);
  const [selectfamilymemberImage, setselectfamilymemberImage] = useState();
  console.log("familyMemberList", familyMemberList);
  const fetchApplicantId = () => {
    setTableLoading(true);
    fetchData(ControllerName.Applicant, "Crud_Family_Detail", {
      OperationId: 1,
      ApplicantCase_InvestigationId: formFields.ApplicantCase_InvestigationId,
      ApplicantFamilyDetailId: formFields.ApplicantFamilyDetailId,
    }).then((result) => {
      if (result?.DataSet?.Table[0]?.HasError > 0) {
        Swal.fire({
          title: "Error",
          text: result.DataSet.Table[0].Message,
          icon: "error",
        });
        setTableLoading(false);
        return;
      }
      setFamilyMemberList(result?.DataSet?.Table);
      setTableLoading(false);

      if (result?.DataSet?.Table[0]?.PaymentSchedule_Count > 0) {
        setpaymentScheduleExist(true);
      }
      setSelectionLists({
        ...selectionLists,
        GenderList: result?.DataSet?.Table3,
        ReligionList: result?.DataSet?.Table2,
        MaritalStatusList: result?.DataSet?.Table4,
        RelationList: result?.DataSet?.Table1,
        ContactTypeList: result?.DataSet?.Table5,
      });
    });
  };
  useEffect(() => {
    fetchApplicantId();
  }, []);

  const onChangeHandler = (event) => {
    setselectfamilymemberImage(event.target.files[0]);
  };

  const handleFamilyMemberChange = (event) => {
    const { name, value } = event.target;
    if (name == "Name" || name == "Mother_Father_HusbandName") {
      const sanitized = AllowAlphabatOnly(value);
      setFormFields({
        ...formFields,
        [event.target.name]: sanitized,
      });
    } else if (name == "ContactNumber") {
      const sanitized = value.replace(/\D/g, "");
      setFormFields({
        ...formFields,
        [event.target.name]: sanitized,
      });
    } else if (name == "IsDeceased" && !value) {
      setFormFields({
        ...formFields,
        [event.target.name]: value,
        DateOfDeath: "",
      });
    } 
    else if (name == "Cnic") {
      const sanitized = AllowAlphaNumeric(value);
      setFormFields({
        ...formFields,
        [event.target.name]: sanitized,
      });
    }
    else {
      setFormFields({
        ...formFields,
        [event.target.name]: event.target.value,
      });
    }
  };

  const onEdit = (index, row) => {
    let checkEdit = row.RelationId == 261;
    setEditable(checkEdit);
    setEditId(row.RelationId == 261 ? row.RelationId : 0);
    setFormFields({
      ...formFields,
      ...familyMemberList[index],
      DateOfBirth: familyMemberList[index].DateOfBirth
        ? moment(familyMemberList[index].DateOfBirth, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          )
        : null,
      DateOfDeath: familyMemberList[index].DateOfDeath
        ? moment(familyMemberList[index].DateOfDeath, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          )
        : null,
      ContactTypeId: familyMemberList[index].ContactTypeId
        ? familyMemberList[index].ContactTypeId
        : "",
      ReligionId: familyMemberList[index].ReligionId
        ? familyMemberList[index].ReligionId
        : "",
    });
    setselectfamilymemberImage(null);
  };

  const onDelete = (index) => {
    // let obj = familyMemberList[index];
    let obj = {
      isEducationDetail: "Education Detail",
      isMedicalDetail: null,
      isApplicantSupportInformation: "Applicant Support",
      isEarningDetail: "Earning Detail",
    };
    let tabs = {
      isEducationDetail: "",
      isMedicalDetail: "",
      isApplicantSupportInformation: "",
      isEarningDetail: "",
    };

    Object.keys(tabs).map((item) =>
      obj[item] ? (tabs[item] = obj[item]) : ""
    );

    let check = false;
    if (check) {
      Swal.fire({
        customClass: {
          container: "my-swal",
        },
        text: `This Family Member is linked with 
        ${tabs?.isEducationDetail ? tabs?.isEducationDetail + "," : ""} 
        ${tabs?.isMedicalDetail ? tabs?.isMedicalDetail + "," : ""} 
        ${
          tabs?.isApplicantSupportInformation
            ? tabs?.isApplicantSupportInformation + ","
            : ""
        }
        and 
        ${tabs?.isEarningDetail ? tabs?.isEarningDetail + " " : ""}.
        Are you sure you want to delete?`,
        icon: "success",
        showCancelButton: true,
        cancelButtonText: `Cancel`,
        cancelButtonColor: "#2f4050",
        confirmButtonText: `Proceed`,
        confirmButtonColor: "#bf1e2e",
      }).then((result) => {
        if (result.isConfirmed) {
          // requestCall(4, { ...formFields, ...familyMemberList[index] });
        }
      });
    } else {
      Swal.fire({
        customClass: {
          container: "my-swal",
        },
        text: "Are you sure to delete the record?",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: `Cancel`,
        cancelButtonColor: "#2f4050",
        confirmButtonText: `Confirm`,
        confirmButtonColor: "#bf1e2e",
      }).then((result) => {
        if (result.isConfirmed) {
          requestCall(4, { ...formFields, ...familyMemberList[index] });
        }
      });
    }
  };

  const onDownload = (index, data) => {
    if (data.url !== null) {
      window.open(baseImageUrl + data.url);
    } else {
      Swal.fire({
        customClass: {
          container: "my-swal",
        },
        text: "Please Upload Image",
        icon: "error",
      });
    }
  };

  const onView = (index, data) => {
    if (data.url !== null) {
      window.open(baseImageUrl + data.url);
    } else {
      Swal.fire({
        customClass: {
          container: "my-swal",
        },
        text: "Please Upload Image",
        icon: "error",
      });
    }
  };
  console.log(
    "formFields.ApplicantFamilyDetailId",
    formFields.ApplicantFamilyDetailId
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    let opids = formFields.ApplicantFamilyDetailId === 0 ? 2 : 3;

    const cnic = formFields.Cnic;
    const name = formFields.Name;
    const relationID = formFields.RelationId;
    const selfFound = familyMemberList.find(
      (item) => item?.RelationId == relationID
    );

    if (opids == 2 && relationID == 261 && selfFound) {
      return Swal.fire({
        title: "Error",
        text: "Can't add duplicate self relation",
        icon: "error",
      });
    }

    if (opids == 3 && relationID == 261 && selfFound && relationID != editId) {
      return Swal.fire({
        title: "Error",
        text: "Can't add duplicate self relation",
        icon: "error",
      });
    }

    if (!formFields.RelationId) {
      return Swal.fire({
        title: "Error",
        text: "Please Select Relation",
        icon: "error",
      });
    }

    if (!formFields.Name) {
      return Swal.fire({
        title: "Error",
        text: "Please Enter Name",
        icon: "error",
      });
    }
    if (!formFields.Cnic) {
      return Swal.fire({
        title: "Error",
        text: "Please Enter CNIC",
        icon: "error",
      });
    }
    if (formFields?.Cnic?.length < 5) {
      return Swal.fire({
        title: "Error",
        text: "Invalid CNIC Number. Please enter a 5 digits number",
        icon: "error",
      });
    }
    if (!formFields.Mother_Father_HusbandName) {
      return Swal.fire({
        title: "Error",
        text: "Please Enter Father/Husband Name",
        icon: "error",
      });
    }
    // if (!formFields.ReligionId) {
    //   return Swal.fire({
    //     title: "Error",
    //     text: "Please Select Religion",
    //     icon: "error",
    //   });
    // }
    if (!formFields.GenderId) {
      return Swal.fire({
        title: "Error",
        text: "Please Select Gender",
        icon: "error",
      });
    }
    // if (!formFields.MaritalStatusId) {
    //   return Swal.fire({
    //     title: "Error",
    //     text: "Please Select Marital Status",
    //     icon: "error",
    //   });
    // }

    if (formFields?.ContactNumber?.length > 0) {
      if (
        (formFields?.ContactNumber?.length > 11 ||
          formFields?.ContactNumber?.length < 11) &&
        formFields?.ContactNumber !== "null"
      ) {
        Swal.fire({
          title: "Warning",
          text: "Invalid Contact Number. Please enter 11 digits number",
          icon: "warning",
        });
        return;
      }
    }

    if (formFields?.ContactNumber && !formFields.ContactTypeId) {
      return Swal.fire({
        title: "Warning",
        text: "Please Select Contact Type",
        icon: "warning",
      });
    }

    if (formFields.ContactTypeId && !formFields?.ContactNumber) {
      return Swal.fire({
        title: "Warning",
        text: "Please Enter Contact No",
        icon: "warning",
      });
    }

    // if (!formFields.ContactTypeId) {
    //   return Swal.fire({
    //     title: "Error",
    //     text: "Please Select Contact Type",
    //     icon: "error",
    //   });
    // }

    if (opids !== parseInt(3)) {
      if (cnic) {
        // Check uniqueness
        if (familyMemberList.map((item) => item.Cnic).includes(cnic)) {
          return Swal.fire({
            title: "Error",
            text: "CNIC already exists",
            icon: "error",
          });
        }
      }

      if (name && relationID) {
        const isUnique = familyMemberList.find(
          (item) =>
            item.Name.toLowerCase().trim() === name.toLowerCase().trim() &&
            item.RelationId == relationID
        );
        console.log("isUnique", isUnique);

        if (isUnique) {
          return Swal.fire({
            title: "Error",
            text: "Can't add duplicate name with same relation",
            icon: "error",
          });
        }
      }
    } else {
      let copy = familyMemberList.filter(
        (item) =>
          item.ApplicantFamilyDetailId != formFields.ApplicantFamilyDetailId
      );
      if (copy.find((item) => item.Cnic == cnic)) {
        return Swal.fire({
          title: "Error",
          text: "CNIC already exists",
          icon: "error",
        });
      }
      let exist = copy.find(
        (item) =>
          item.Name.toLowerCase().trim() === name.toLowerCase().trim() &&
          item.RelationId == relationID
      );
      if (exist) {
        Swal.fire({
          title: "Warning",
          text: "Duplicate data not allowed for same name and relation",
          icon: "warning",
        });
        return;
      }
    }
    let msg = "";
    if (opids === parseInt(3)) {
      msg = "Are you sure to edit the record?";
    } else {
      msg = "Are you sure to add the record?";
    }

    Swal.fire({
      customClass: {
        container: "my-swal",
      },
      text: msg,
      icon: "success",
      showCancelButton: true,
      cancelButtonText: `Cancel`,
      cancelButtonColor: "#2f4050",
      confirmButtonText: `Confirm`,
      confirmButtonColor: "#bf1e2e",
    }).then((result) => {
      if (result.isConfirmed) {
        requestCall(
          formFields.ApplicantFamilyDetailId === 0 ? 2 : 3,
          formFields,
          selectfamilymemberImage
        );
      }
    });
  };

  const Insert_FamilyMemberInformation = async (
    opId,
    payload,
    selectfamilymemberImage
  ) => {
    const formData = new FormData();
    formData.append("OperationId", opId);
    formData.append(
      "ApplicantCase_InvestigationId",
      payload.ApplicantCase_InvestigationId
    );
    formData.append("ApplicantFamilyDetailId", payload.ApplicantFamilyDetailId);
    formData.append("Name", payload.Name);
    formData.append("Cnic", payload.Cnic);
    formData.append(
      "Mother_Father_HusbandName",
      payload.Mother_Father_HusbandName
    );
    formData.append(
      "DateOfBirth",
      payload.DateOfBirth
        ? moment(payload.DateOfBirth, ["DD/MM/YYYY", "DD-MM-YYYY"]).format(
            "YYYY-MM-DD"
          )
        : null
    );
    formData.append("IsDeceased", payload.IsDeceased);
    formData.append(
      "DateOfDeath",
      payload.DateOfDeath
        ? moment(payload.DateOfDeath, ["DD/MM/YYYY", "DD-MM-YYYY"]).format(
            "YYYY-MM-DD"
          )
        : null
    );
    formData.append(
      "RelationId",
      payload.RelationId ? payload.RelationId : 171
    );
    formData.append(
      "ReligionId",
      payload.ReligionId ? payload.ReligionId : "0"
    );
    formData.append("GenderId", payload.GenderId);
    formData.append(
      "ContactTypeId",
      payload.ContactTypeId ? payload.ContactTypeId : "0"
    );
    formData.append(
      "MaritalStatusId",
      payload.MaritalStatusId ? payload.MaritalStatusId : "0"
    );
    formData.append("IsPartOfBannedOrg", payload.IsPartOfBannedOrg);
    formData.append(
      "IsInvolveInCriminalActivity",
      payload.IsInvolveInCriminalActivity
    );
    formData.append("HasMedicalHistory", payload.HasMedicalHistory);
    formData.append("Remarks", payload.Remarks);
    formData.append("ContactNumber", payload.ContactNumber);
    formData.append("UserId", payload.UserId);
    formData.append("UserIP", payload.UserIP);
    formData.append("CanRead", payload.CanRead);
    formData.append("CanWrite", payload.CanWrite);
    formData.append("IsEmployeed", payload.IsEmployeed);
    formData.append("IsJobList", payload.IsJobList);
    formData.append("JobRemarks", payload.JobRemarks);
    formData.append("LastWorkExperience", payload.LastWorkExperience);
    formData.append("Orphan", payload.Orphan ? true : false);
    formData.append("FamilyMemberPicture", selectfamilymemberImage);

    // if(formData.get("UserId")){
    //   return Swal.fire({
    //     title: "Warning",
    //     text: "UserId is not in payload",
    //     icon: "warning",
    //   });
    // }

    var baseurl =
      baseUrl +
      "/" +
      ControllerName.Applicant +
      "/" +
      "Crud_Family_Detail_Image";
    axios
      .post(baseurl, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("response.data.DataSet", response);
        if (!response.data.DataSet) {
          return Swal.fire({
            title: "Error",
            icon: "error",
            // text: response.data?.ResponseMessage
            //   ? response.data?.ResponseMessage
            //   : "Something went wrong",
          });
        }
        if (response.data.DataSet.Table[0].HasError == 1) {
          Swal.fire({
            title: "Error",
            text: response.data.DataSet.Table[0].Message,
            icon: "error",
          });
          return;
        } else if (response.data.DataSet.Table[0].HasError == 2) {
          Swal.fire({
            title: "Warning",
            html: response.data.DataSet.Table[0].Message,
            icon: "warning",
          });
          return;
        } else {
          Swal.fire({
            title: "Success",
            text: response.data.DataSet.Table[0].Message,
            icon: "success",
          }).then((result) => {
            setEditable(opId == 3 ? false : false);
            fetchApplicantId();
            setFormFields({
              ...initialValues,
              ApplicantCase_InvestigationId: appId,
              ContactTypeId: "",
            });
            setselectfamilymemberImage(null);
            if (result.isConfirmed) {
              // history.push("/admin/ApplicantListing");
            }
          });
        }
      })
      .catch((error) => {
        //   const errorMsg = error.message;
      });
  };

  /**
   * Request Call Function
   * @param {number} OperationId
   * @param {*} payload
   */
  const requestCall = (opId, payload, selectfamilymemberImage) => {
    var data = Insert_FamilyMemberInformation(
      opId,
      payload,
      selectfamilymemberImage
    );
    //
    // if(payload.Orphan === null)
    //  {
    //   payload.Orphan = false;
    //  }
    // setFormLoading(true);
    // fetchData("Applicant", "Crud_Family_Detail", {
    //   OperationId: opId,
    //   ...payload,
    // }).then((result) => {
    //   if (result?.DataSet?.Table[0]?.hasError > 0) {
    //     Swal.fire({
    //       title: "Error",
    //       text: result?.DataSet?.Table[0]?.Message,
    //       icon: "error",
    //     });
    //     setFormLoading(false);
    //     return;
    //   }
    //   Swal.fire({
    //     title: "Success",
    //     text: result?.DataSet?.Table[0]?.Message,
    //     icon: "success",
    //   });
    //   setFamilyMemberList(result?.DataSet?.Table1);
    //   setFormFields({
    //     ...initialValues,
    //     ApplicantCase_InvestigationId: appId,
    //   });
    //   setFormLoading(false);
    // });
  };
  const AllDateSet = (event, type) => {
    if (type === "DateOfBirth") {
      setFormFields({
        ...formFields,
        DateOfBirth: getDate(event, "/"),
      });
    } else if (type === "DateOfDeath") {
      setFormFields({
        ...formFields,
        DateOfDeath: getDate(event, "/"),
      });
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Card className="mb-3">
          <CardHeader>
            <h6 className="font-weight-bold mb-0">Additional Details</h6>
          </CardHeader>
          <CardBody>
            <Row form>
              <Col md={12}>
                <h2 className="h6">Family Information</h2>
              </Col>
            </Row>
            <Row form>
              {console.log(
                "familyMemberListfamilyMemberList",
                familyMemberList
              )}
              <Col md={12}>
                <FormGroupTable
                  loading={tableLoading}
                  columns={columns}
                  rows={familyMemberList}
                  onEdit={onEdit}
                  hideAction={role}
                  onDelete={onDelete}
                  onView={onView}
                  // onDownload={onDownload}
                  // customDownloadIcon={<i className="nc-icon nc-album-2"></i>}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="mb-3">
          <CardHeader>
            <h6 className="font-weight-bold mb-0">Family Member Information</h6>
          </CardHeader>
          <CardBody>
            <Row form>
              <Col md={3}>
                <FormGroupSelect
                  label="Relation*"
                  name="RelationId"
                  value={formFields.RelationId}
                  onChange={handleFamilyMemberChange}
                  list={selectionLists.RelationList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  required={true}
                  disabled={
                    (editable && formFields.RelationId == 261) ||
                    paymentScheduleExist ||
                    role
                    // ||
                    // formFields.ApplicantFamilyDetailId
                  }
                />
              </Col>
              <Col md={3}>
                <FormGroupInput
                  label="Name*"
                  name="Name"
                  value={formFields.Name}
                  placeholder="Name"
                  onChange={handleFamilyMemberChange}
                  required={true}
                  maxLength="50"
                  minLength="3"
                  disabled={
                    (editable && formFields.RelationId == 261) ||
                    paymentScheduleExist ||
                    role
                    // ||
                    // formFields.ApplicantFamilyDetailId
                  }
                />
              </Col>
              <Col md={3}>
                <FormGroupInput
                  label="CNIC / B-Form Number*"
                  name="Cnic"
                  placeholder="CNIC / B-Form Number"
                  // isNumber="true"
                  value={formFields.Cnic}
                  onChange={handleFamilyMemberChange}
                  required={true}
                  minLength="5"
                  maxLength="13"
                  disabled={
                    (editable && formFields.RelationId == 261) ||
                    paymentScheduleExist ||
                    role
                    // ||
                    // formFields.ApplicantFamilyDetailId
                  }
                />
              </Col>
              <Col md={3}>
                <FormGroupInput
                  label="Father / Husband Name*"
                  name="Mother_Father_HusbandName"
                  value={formFields.Mother_Father_HusbandName}
                  onChange={handleFamilyMemberChange}
                  placeholder="Father / Husband Name"
                  required={true}
                  maxLength="50"
                  minLength="3"
                  disabled={
                    (editable && formFields.RelationId == 261) ||
                    paymentScheduleExist ||
                    role
                  }
                />
              </Col>
              {/* <Col md={3}>
                <FormGroupDatePicker
                  label="Date of Birth"
                  name="DateOfBirth"
                  value={formFields.DateOfBirth}
                  onChange={handleFamilyMemberChange}
                  required={formFields.DateOfBirth}
                  disabled={paymentScheduleExist || role}
                
                />
              </Col> */}
              <Col md={3}>
                <Label for="InputDate">Date of Birth</Label>
                <DatePicker
                  //label="Date of Birth"
                  value={formFields.DateOfBirth}
                  //value={getDate(formFields.DateOfDeath, "/")}
                  dateFormat={dateFormat}
                  onChange={(e) => AllDateSet(e, "DateOfBirth")}
                  className="form-control"
                  name="DateOfBirth"
                  placeholderText={dateFormatPlaceholder}
                  disabled={
                    (editable && formFields.RelationId == 261) ||
                    paymentScheduleExist ||
                    role
                  }
                  required={formFields.DateOfBirth}
                  showYearDropdown
                />
              </Col>

              <Col md={3}>
                <Label for="InputDate">Date of Death</Label>
                <DatePicker
                  //label="Date of Death"
                  value={formFields.DateOfDeath}
                  //  value={getDate(formFields.DateOfDeath, "/")}
                  // selected={personalInformation.DateOfBirth}
                  dateFormat={dateFormat}
                  onChange={(e) => AllDateSet(e, "DateOfDeath")}
                  className="form-control"
                  name="DateOfDeath"
                  placeholderText={dateFormatPlaceholder}
                  disabled={!formFields.IsDeceased || role}
                  required={formFields.DateOfDeath}
                  showYearDropdown
                />
              </Col>

              {/* <Col md={3}>
                <FormGroupDatePicker
                  label="Date of Death*"
                  name="DateOfDeath"
                  value={formFields.DateOfDeath}
                  onChange={handleFamilyMemberChange}
                  required={formFields.IsDeceased}
                  disabled={!formFields.IsDeceased || role}
                />
              </Col> */}
              <Col md={3}>
                <FormGroupCheckbox
                  label="Deceased"
                  name="IsDeceased"
                  value={formFields.IsDeceased}
                  onChange={handleFamilyMemberChange}
                  disabled={paymentScheduleExist || role}
                />
              </Col>
              <Col md={3}>
                <FormGroupSelect
                  label="Religion"
                  name="ReligionId"
                  value={formFields.ReligionId}
                  onChange={handleFamilyMemberChange}
                  list={selectionLists.ReligionList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  disabled={
                    (editable && formFields.RelationId == 261) ||
                    paymentScheduleExist ||
                    role
                  }
                  //required={true}
                />
              </Col>
              <Col md={3}>
                <FormGroupSelect
                  label="Gender*"
                  name="GenderId"
                  value={formFields.GenderId}
                  onChange={handleFamilyMemberChange}
                  list={selectionLists.GenderList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  disabled={
                    (editable && formFields.RelationId == 261) ||
                    paymentScheduleExist ||
                    role
                  }
                  required={true}
                />
              </Col>
              <Col md={3}>
                <FormGroupSelect
                  label="Marital Status"
                  name="MaritalStatusId"
                  value={formFields.MaritalStatusId}
                  onChange={handleFamilyMemberChange}
                  list={selectionLists.MaritalStatusList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  disabled={
                    (editable && formFields.RelationId == 261) ||
                    paymentScheduleExist ||
                    role
                  }
                  // required={true}
                />
              </Col>
              <Col md={3}>
                <FormGroupSelect
                  label="Contact Type"
                  name="ContactTypeId"
                  value={formFields.ContactTypeId}
                  onChange={handleFamilyMemberChange}
                  list={selectionLists.ContactTypeList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  disabled={
                    (editable && formFields.RelationId == 261) ||
                    paymentScheduleExist ||
                    role
                  }
                  // required={true}
                />
              </Col>
              <Col md={3}>
                <FormGroupInput
                  label="Contact Number"
                  name="ContactNumber"
                  placeholder="Contact Number"
                  value={
                    formFields.ContactNumber == "null"
                      ? ""
                      : formFields.ContactNumber
                  }
                  onChange={handleFamilyMemberChange}
                  isNumber="true"
                  maxLength="11"
                  minLength="11"
                  disabled={
                    (editable && formFields.RelationId == 261) ||
                    paymentScheduleExist ||
                    role
                  }
                  // required={true}
                />
              </Col>
              <Col md={6}>
                <FormGroupInput
                  label="Remarks"
                  name="Remarks"
                  value={formFields.Remarks}
                  placeholder="Remarks"
                  onChange={handleFamilyMemberChange}
                  disabled={paymentScheduleExist || role}
                />
              </Col>
              <Col md={3}>
                <FormGroupCheckbox
                  label="Can Read"
                  name="CanRead"
                  value={formFields.CanRead}
                  onChange={handleFamilyMemberChange}
                  disabled={paymentScheduleExist || role}
                />
              </Col>
              <Col md={3}>
                <FormGroupCheckbox
                  label="Can Write"
                  name="CanWrite"
                  value={formFields.CanWrite}
                  onChange={handleFamilyMemberChange}
                  disabled={paymentScheduleExist || role}
                />
              </Col>
              <Col md={3}>
                <FormGroupCheckbox
                  label="Orphan"
                  name="Orphan"
                  value={formFields.Orphan}
                  onChange={handleFamilyMemberChange}
                  disabled={paymentScheduleExist || role}
                />
              </Col>
              <Col md={3}>
                <FormGroupCheckbox
                  label="Employed"
                  name="IsEmployeed"
                  value={formFields.IsEmployeed}
                  onChange={handleFamilyMemberChange}
                  disabled={paymentScheduleExist || role}
                />
              </Col>
              <Col md={3}>
                <FormGroupCheckbox
                  label="Transfer to Job List"
                  name="IsJobList"
                  value={formFields.IsJobList}
                  onChange={handleFamilyMemberChange}
                  disabled={paymentScheduleExist || role}
                />
              </Col>
              <Col md={3}>
                <FormGroupInput
                  label="Job Remarks"
                  name="JobRemarks"
                  placeholder="Job Remarks"
                  value={formFields.JobRemarks}
                  onChange={handleFamilyMemberChange}
                  disabled={paymentScheduleExist || role}
                />
              </Col>
              <Col md={3}>
                <FormGroupInput
                  label="Last Work Experience"
                  name="LastWorkExperience"
                  placeholder="Last Work Experience"
                  value={formFields.LastWorkExperience}
                  onChange={handleFamilyMemberChange}
                  disabled={paymentScheduleExist || role}
                />
              </Col>

              <Col md={3}>
                <FormGroup>
                  <Label for="">Upload Member Picture</Label>
                  <Input
                    type="file"
                    style={{
                      color: "transparent",
                    }}
                    className="form-control"
                    id="FamilyMemberPicture"
                    name="FamilyMemberPicture"
                    ref={fileInputRef}
                    onChange={onChangeHandler}
                    title={
                      !selectfamilymemberImage
                        ? "Select Family Member Picture"
                        : formFields?.FileName
                        ? formFields?.FileName
                        : "Select Family Member Picture"
                    }
                    disabled={paymentScheduleExist || role}
                  />
                </FormGroup>
                {selectfamilymemberImage && (
                  <small className="form-text text-muted">
                    <p>Selected file: {selectfamilymemberImage.name}</p>
                  </small>
                )}
                <small className="form-text text-muted">
                  {formFields?.FileName}
                </small>
              </Col>
            </Row>
            <Row form>
              <Col md={3}>
                <FormGroupCheckbox
                  label="Is Part Of Banned Org"
                  name="IsPartOfBannedOrg"
                  value={formFields.IsPartOfBannedOrg}
                  onChange={handleFamilyMemberChange}
                  disabled={paymentScheduleExist || role}
                />
              </Col>
              <Col md={3}>
                <FormGroupCheckbox
                  label="Is Involved in Criminal Activity"
                  name="IsInvolveInCriminalActivity"
                  value={formFields.IsInvolveInCriminalActivity}
                  onChange={handleFamilyMemberChange}
                  disabled={paymentScheduleExist || role}
                />
              </Col>
              <Col md={3}>
                <FormGroupCheckbox
                  label="Has Medical History"
                  name="HasMedicalHistory"
                  value={formFields.HasMedicalHistory}
                  onChange={handleFamilyMemberChange}
                  disabled={paymentScheduleExist || role}
                />
              </Col>
            </Row>
            <Row form className="text-right">
              <Col md={12}>
                <FormGroup>
                  {role ? null : (
                    <FormGroupButton
                      title="Add Family Member"
                      className="btn-sm"
                      type="submit"
                      loading={formLoading}
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </form>
    </div>
  );
};

export default FamilyMemberInformation;
