import FormGroupButton from "components/GeneralComponent/FormGroupButton";
import FormGroupInput from "components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "components/GeneralComponent/FormGroupTable";
import useEditRole from "hooks/useEditRole";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import { fetchData } from "utils/Api";
import { AllowAlphabatOnly, AllowOnlyNumeric } from "utils/CommonMethods";

const initialValues = {
  ApplicantCase_InvestigationId: 0,
  ApplicantContactDetailId: 0,
  PhoneTypeId: "",
  PhoneType: "",
  PhoneNo: null,
  UserId: localStorage.getItem("UserId"),
  UserIP: localStorage.getItem("UserIP"),
};

const initialSelectLists = {
  ContactTypeList: [],
  ContactList: [],
};

const columns = [
  { field: "PhoneType", name: "Contact Type" },
  { field: "PhoneNo", name: "Contact Number" },
  { field: "CreatedDate", name: "Created Date", type: "date" },
];

const ContactDetails = (props) => {
  const [role, appId] = useEditRole();

  const [formFields, setFormFields] = useState({
    ...initialValues,
    ApplicantCase_InvestigationId: appId,
  });
  const [selectionLists, setSelectionLists] = useState(initialSelectLists);
  const [formLoading, setFormLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    const fetchApplicantId = () => {
      setTableLoading(true);
      fetchData("Applicant", "Crud_Personal_Information_Contact_Detail", {
        OperationId: 1,
        ApplicantCase_InvestigationId: formFields.ApplicantCase_InvestigationId,
      }).then((result) => {
        if (result?.DataSet?.Table[0]?.HasError > 0) {
          Swal.fire({
            title: "Error",
            text: result.DataSet.Table[0].Message,
            icon: "error",
          });
          return;
        }
        setSelectionLists({
          ...selectionLists,
          ContactList: result?.DataSet?.Table,
          ContactTypeList: result?.DataSet?.Table1,
        });
        setTableLoading(false);
      });
    };
    fetchApplicantId();
  }, []);
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // const contactname = event.target.options[event.target.selectedIndex].innerHTML;
    // console.log('event contact detail',event.target.options[event.target.selectedIndex].innerHTML);
    if (name == "PhoneNo") {
      const sanitized = value.replace(/\D/g, "");
      setFormFields({
        ...formFields,
        [event.target.name]: sanitized,
      });
    } else {
      if (event.target.options) {
        const contactname =
          event.target.options[event.target.selectedIndex].innerHTML;
        // if(contactname == 'Alternate Contact' || contactname == 'Primary Contact'){
        //   let check = selectionLists.ContactList.filter(item=> item.PhoneType == contactname);
        //   if(check?.length > 0){
        //     Swal.fire({
        //       title: "Warning",
        //       text: `${contactname} type already exist`,
        //       icon: "warning",
        //     });
        //     return;
        //   }
        // }
        setFormFields({
          ...formFields,
          [event.target.name]: event.target.value,
          ["PhoneType"]: contactname,
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let opids = formFields.ApplicantContactDetailId == 0 ? 2 : 3;

    if (!formFields.PhoneTypeId) {
      Swal.fire({
        title: "Warning",
        text: "Please select contact type",
        icon: "warning",
      });
      return;
    }

    if (!formFields.PhoneNo) {
      Swal.fire({
        title: "Warning",
        text: "Contact number must required",
        icon: "warning",
      });
      return;
    }

    if (formFields?.PhoneNo?.length > 0) {
      if (
        formFields?.PhoneNo?.length > 11 ||
        formFields?.PhoneNo?.length < 11
      ) {
        Swal.fire({
          title: "Warning",
          text: "Invalid Contact Number. Please enter 11 digits number",
          icon: "warning",
        });
        return;
      }
    }

    if (opids == 2) {
      let existingTwo = selectionLists.ContactList.find(
        (item) =>
          (item.PhoneType == formFields?.PhoneType &&
            item.PhoneType == "Primary Contact") ||
          (item.PhoneType == formFields?.PhoneType &&
            item.PhoneType == "Alternate Contact")
      );
      if (existingTwo) {
        Swal.fire({
          title: "Warning",
          text: `Duplicate data not allowed for ${formFields?.PhoneType}`,
          icon: "warning",
        });
        return;
      }
    }

    // if (opids == 3) {
    //   let existingTwo = selectionLists.ContactList.find(
    //     (item) =>
    //       (item.PhoneType == formFields?.PhoneType &&
    //         item.PhoneType == "Primary Contact") ||
    //       (item.PhoneType == formFields?.PhoneType &&
    //         item.PhoneType == "Alternate Contact")
    //   );
    //   if (existingTwo) {
    //     Swal.fire({
    //       title: "Warning",
    //       text: `Duplicate data not allowed for ${formFields?.PhoneType}`,
    //       icon: "warning",
    //     });
    //     return;
    //   }
    // }

    let existing = selectionLists.ContactList.find(
      (item) =>
        item.PhoneTypeId == formFields.PhoneTypeId &&
        item.PhoneNo == formFields.PhoneNo
    );

    if (existing) {
      Swal.fire({
        title: "Warning",
        text: "Duplicate data not allowed for same contact type and contact number",
        icon: "warning",
      });
      return;
    }

    let msg = "";
    if (opids === parseInt(3)) {
      msg = "Are you sure to edit the record?";
    } else {
      msg = "Are you sure to add the record?";
    }

    Swal.fire({
      customClass: {
        container: "my-swal",
      },
      text: msg,
      icon: "success",
      showCancelButton: true,
      cancelButtonText: `Cancel`,
      cancelButtonColor: "#2f4050",
      confirmButtonText: `Confirm`,
      confirmButtonColor: "#bf1e2e",
    }).then((result) => {
      if (result.isConfirmed) {
        requestCall(
          formFields.ApplicantContactDetailId == 0 ? 2 : 3,
          formFields
        );
      }
    });

    // if (formFields.ApplicantContactDetailId != 0) {
    //   //Update record

    //   Swal.fire({
    //     customClass: {
    //       container: "my-swal",
    //     },
    //     text: "Are you sure to edit the record?",
    //     icon: "success",
    //     showCancelButton: true,
    //     cancelButtonText: `Cancel`,
    //     cancelButtonColor: "#2f4050",
    //     confirmButtonText: `Confirm`,
    //     confirmButtonColor: "#bf1e2e",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       requestCall(
    //         formFields.ApplicantContactDetailId === 0 ? 2 : 3,
    //         formFields
    //       );
    //     }
    //   });
    // } else {
    //   //Save Record
    //   Swal.fire({
    //     customClass: {
    //       container: "my-swal",
    //     },
    //     text: "Are you sure to add the record?",
    //     icon: "success",
    //     showCancelButton: true,
    //     cancelButtonText: `Cancel`,
    //     cancelButtonColor: "#2f4050",
    //     confirmButtonText: `Confirm`,
    //     confirmButtonColor: "#bf1e2e",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       requestCall(
    //         formFields.ApplicantContactDetailId === 0 ? 2 : 3,
    //         formFields
    //       );
    //     }
    //   });

    // }
  };

  const onEdit = (index) => {
    // setEditable(true);
    // if (formFields.ApplicantContactDetailId) {
    //   Swal.fire({
    //     customClass: {
    //       container: "my-swal",
    //     },
    //     text: "First edit the exist record",
    //     icon: "info",
    //   });
    //   return;
    // } else {

    setFormFields({ ...formFields, ...selectionLists.ContactList[index] });
    // let copyOfArray = selectionLists.ContactList;
    // let obj = selectionLists.ContactList[index];
    // let remaining = copyOfArray.filter(
    //   (item) => item.ApplicantContactDetailId !== obj.ApplicantContactDetailId
    // );
    // setSelectionLists({ ...selectionLists, ContactList: remaining });
    // }
  };

  const onDelete = (index) => {
    Swal.fire({
      customClass: {
        container: "my-swal",
      },
      text: "Are you sure to delete the record?",
      icon: "success",
      showCancelButton: true,
      cancelButtonText: `Cancel`,
      cancelButtonColor: "#2f4050",
      confirmButtonText: `Confirm`,
      confirmButtonColor: "#bf1e2e",
    }).then((result) => {
      if (result.isConfirmed) {
        requestCall(4, { ...formFields, ...selectionLists.ContactList[index] });
      }
    });
  };

  /**
   * Request Call Function
   * @param {number} OperationId
   * @param {*} payload
   */
  const requestCall = (opId, payload) => {
    fetchData("Applicant", "Crud_Personal_Information_Contact_Detail", {
      OperationId: opId,
      ...payload,
    }).then((result) => {
      if (result?.DataSet?.Table[0]?.HasError == 1) {
        Swal.fire({
          title: "Error",
          text: result?.DataSet?.Table[0]?.Message,
          icon: "error",
        });
        setFormLoading(false);
        return;
      }
      if (result?.DataSet?.Table[0]?.HasError == 2) {
        Swal.fire({
          title: "Warning",
          text: result?.DataSet?.Table[0]?.Message,
          icon: "warning",
        });
        setFormLoading(false);
        return;
      }
      Swal.fire({
        title: "Success",
        text: result?.DataSet?.Table[0]?.Message,
        icon: "success",
      });
      setSelectionLists({
        ...selectionLists,
        ContactList: result?.DataSet?.Table1,
      });
      setFormFields({
        ...initialValues,
        ApplicantCase_InvestigationId: appId,
      });
      setFormLoading(false);
    });
  };

  return (
    <Card className="mb-3">
      <CardHeader>
        <h6 className="font-weight-bold mb-0">Contact Information</h6>
      </CardHeader>
      <CardBody>
        {/* <form onSubmit={handleContactSubmit}> */}
        {/* <Row form onSubmit={handleContactSubmit}> */}

        <Row form>
          <Col md={3}>
            <FormGroupSelect
              label="Contact Type"
              name="PhoneTypeId"
              value={formFields.PhoneTypeId}
              onChange={handleInputChange}
              list={selectionLists.ContactTypeList}
              fieldId="SetupDetailId"
              fieldName="SetupDetailName"
              // required={true}
              disabled={role}
            />
          </Col>

          <Col md={3}>
            <FormGroupInput
              label="Contact Number"
              name="PhoneNo"
              value={formFields.PhoneNo}
              onChange={handleInputChange}
              isNumber="true"
              placeholder="Contact Number"
              // required={true}
              maxLength="11"
              minLength="11"
              disabled={role}
            />
          </Col>
        </Row>

        <Row className="text-right">
          <Col md={12}>
            {role ? null : (
              <FormGroupButton
                type="submit"
                className="btn-sm"
                title="Add Contact"
                loading={formLoading}
                onClick={handleSubmit}
              />
            )}
          </Col>
        </Row>
        {/* </form> */}
        <Row form>
          <Col md={12}>
            <h2 className="h6 pt-3">Contact Details</h2>
          </Col>
          <Col md={12}>
            <FormGroupTable
              loading={tableLoading}
              columns={columns}
              rows={selectionLists.ContactList}
              onEdit={onEdit}
              onDelete={onDelete}
              hideAction={role}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ContactDetails;
