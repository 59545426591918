import { baseImageUrl } from "./Api"
import { InsertData_Followup } from "./CommonMethods"

// export const BaseAPIURL = "https://api.sabsaath.org";
export const BaseAPIURL = "http://localhost:50469"
export const ApiMethods = {
  usp_GetChristmasCampaignDetails:"usp_GetChristmasCampaignDetails",
  CrudUser: "CrudUser",
  ForgetPasswordAutoGenerated: "ForgotPasswordAutoGenerated",
  VerifyLogin: "VerifyLogin",
  ForgotPassword: "ForgotPassword",
  UserRole_Operation: "UserRole_Operation",
  MenuItem_Operation: "MenuItem_Operation",
  Category_Operation: "Category_Operation",
  FundCategory_Operation: "FundCategory_Operation",
  Beneficiary_Operation: "Beneficiary_Operation",
  Company_Operation: "Company_Operation",
  CompanyFamily_Operation: "CompanyFamily_Operation",
  CompanyLocation_Operation: "CompanyLocation_Operation",
  Get_CompanyData: "Get_CompanyData",
  Frequency_Operation: "Frequency_Operation",
  PaymentType_Operation: "PaymentType_Operation",
  MenuItem_Feature_Get: "MenuItem_Feature_Get",
  MasterDetail_Operation: "MasterDetail_Operation",
  MasterDetail_Operation_Applicant_List:
    "MasterDetail_Operation_Applicant_List",
  PayDonation: "PayDonation",
  Fast_Forex_Only_One: "Fast_Forex_Only_One",
  GetFastForexCurrencies: "GetFastForexCurrencies",

  Applicant_Operation: "Applicant_Operation",
  Get_Data_According_To_ReferrerType: "Get_Data_According_To_ReferrerType",
  Register_Applicant_Case: "Register_Applicant_Case",
  Combo_Operation: "Combo_Operation",
  Applicant_MarketingCase: "Applicant_MarketingCase",
  Pet_Details: "usp_crud_ApplicantPetDetail",
  Get_Applicant_Cases: "Get_Applicant_Cases",
  Get_Applicant_List: "Get_Applicant_List",
  Get_Application_Applicant_Data: "Get_Application_Applicant_Data",

  Asset_Detail: "usp_crud_AssetDetail",
  Source_Of_Drinking: "SourceofDrinkingWater",
  Active_Reserved_Cases: "usp_active_resolvedcases_web",
  All_Cases: "usp_DonationCategories_web",
  Insert_Checkout_Details: "usp_DonationCheckOut_web",
  Get_All_Relief: "usp_Food_GetReliefDetails",
  GetRamzanCampaignDetails: "usp_GetRamzanCampaignDetails",
  GetFeaturedNGOsDetails: "usp_GetFeaturedNGOsDetails",
  ContentsUploads: "ContentsUploads",
  SP_Testimonials: "SP_Testimonials",
  Sp_Advertisement: "Sp_Advertisement",
  Get_Success_Stories: "usp_GetSuccessStories",
  Case_Of_The_Day: "usp_GetCaseoftheDay",
  Get_Blogs: "usp_GetBlogs",
  ContactUS: "SP_ContactUs",
  Get_Testimonials: "usp_GetTestimonials",
  Blogs_Opertaion: "SP_Crud_Blogs",
  Testimonial_Operation: "SP_Crud_Testimonials",
  Blogs_List: "sp_Crud_BlogsView",
  Testimonial_List: "sp_Crud_Testimonials_View",
  Delete_Blogs: "sp_Crud_Blogs_Delete",
  Delete_Testimonials: "sp_Crud_Testimonials_Delete",
  Register_Applicant_Case_With_Document:
    "Register_Applicant_Case_With_Document",

  //Testimonial_List: "sp_Crud_Testimonials_View",
  Company_BankDetails: "SP_GetCompmay_BankDetails",

  InsertData_Followup: "Crud_Followup",
  Payment_List_Table: "Payment_List_Table",
  Payment_GatwWay_API: "Payment_GatwWay_API",

  SueessStories_AfteApproved: "SueessStories_AfterApproved",
  sp_Get_CountryCode: "sp_Get_CountryCode",
  Report_Job_List: "Report_Job_List",
  Report_Applicant_Case_List: "Report_Applicant_Case_List",
  Report_Payment_List: "Report_Payment_List",
  Report_Donor_List: "Report_Donor_List",
  Report_Donation_List: "Report_Donation_List",
  Report_Task_Scheduler: "Report_Task_Sechduler",
  Report_Subscription_List_Admin: "Report_Subscription_List",
  Report_Case_Donation_Details: "Report_Case_Donation_Details",
  Report_Referral_List: "Report_Referral_List",
  Report_Company_List: "Report_Company_List",
  Report_Institute_Wise_List: "Report_Institute_Wise_List",
  Report_Patient_List: "Report_Patient_List",
  Report_Payment_Reverse_Disburs: "Report_Payment_Reverse_Disburs",
  Report_DonationHistory: "Report_DonationHistory",

  Report_Subscription_List: "Report_SubscriptionDetail",
  Report_Subscription_List_Detail: "Report_Subscription_List_Detail",
}
export const SIDEBAR_BLOCKED_ROUTES = [
  "ApplicantDetail",
  "ChangePassword",
  "paymentListing",
]

export const ControllerName = {
  User: "User",
  Security: "Security",
  Setup: "Setup",
  Applicant: "Applicant",
  Company: "Company",
  Website: "WebSite",
  Payment: "Payment",
  Reporting: "Reporting",
}
export const OperationTypeId = {
  Select: 1,
  Insert: 2,
  Update: 3,
  Delete: 4,
  Authenticate: 5,
  CompanyOperation: {
    Select: 1,
    Insert_Update: 2,
    Delete: 4,
    Company_With_Bank: 5,
  },
}
export const SetupMasterIds = {
  DocumentParentTypes: 2,
  DocumentSubTypes: 3,
  Province: 4,
  City: 5,
  Union: 6,
  Council: 7,
  Village_Muhalla: 8,
  Gender: 9,
  NatureOfCase: 10,
  MaritalStatus: 11,
  Relation: 12,
  Expense: 13,
  Occupation: 14,
  Referrer: 15,
  Pets: 16,
  SourceOfDrinking: 17,
  LoanType: 18,
  AssetStatus: 19,
  AssetsType: 20,
  SupportingDocuments: 21,
  AcademicLevel: 23,
  AcceptanceOfCharity: 24,
  Diseases: 25,
  DonationType: 26,
  HomeApplaince: 27,
  Disability: 28,
  Country: 29,
  District: 30,
  Religion: 31,
  Bank: 33,
  Category: 34,
  FundCategory: 35,
  ComapnyFamily: 39,
  ReferrerCategory: 41,
  JobStatus: 45,
  Degree: 46,
  CurrentClassSemester: 47,
  FeesPeriod: 48,
  ContactType: 1046,
  FundSubCategory: 36,
  Frequency: 37,
  PaymentType: 38,
  CompanyFamily: 39,
  FollowUp: 40,
  NGO: 41,
  ReferrerType: 1047,
  AssetSubType: 1051,
  GeneralDonation: 1052,
  Currency: 1053,
  LearnAbout: 1055,
  PreferredArea: 1056,
  CaseStatus: 1057,
  SupportStatus: 1063,
  MedicalCard: 1064,
  PaymentStatus: 1068,
  PaymentList: 1069,
  Qualification: 23,
  DontationCategory: 1071,
  PaymentTypeDetail: 1072,
  PKR: 310,
  DonationCat_Donor: 725,
  PaymentType_Cheque: 736,
  CaseSupportStatusApproved: 415,
  CaseSupportStatusReject: 416,
  DEBITCREDITCARDNO: 869,
  CASH: 735,

  Goat: 1313,
  Bull_Cow: 1314,
  Hissa: 1315,
  Multiple_Animals: 1316,
  QurbaniForGaza:1730,
  Cash_Donation: 1317,
  HandPickMyAnimal: 121,
  
  WaterDonation:1742,
  QurbaniConstant: 1604,
  Qurbani: 1318,
  GeneralDonationid: 314,
  Sadaqa:309,
  Zakat:308,
  CategoryWise_Donations: 1499,
  PhysicalAuditor: 1073,

  //Disaster Relief
  DisasterRelif: 1531, //Live
  Disaster_FeedFamily: 1532,
  Disaster_Git: 1533, //live
  Disaster_Livelihood: 1534,
  Disaster_MedicalCamp: 1535,
  Disaster_RebuildHome: 1536,
  generalAid: 1538, //Live
  Rebuil_A_Comunity: 1548,

  // Start Ramazan Compaing
  RamazanCampaign: 1557,
  SponsoraFast: 1558,
  RamazanRationBecomeanAngeltoaFamily: 1559,
  HostanIftar: 1560,
  FundaSadaqaeJaariyaProject: 1561,
  ClothingaMillionSmiles: 1562,
  HelpHealSaveLives: 1563,
  PakistanFloodAppealrenamingRebuildaCommunity: 1564,
  Fidyahfor30daysofRamazan: 1565,
  EducateaChild: 1566,
  ZakatulFitrtobemadeactivein3rdAshra: 1567,

  //End Ramazan Compaing

  // 🔴  START SUBSCRIPTION CONSTANTS
  Daily: 12,
  Monthly: 266,
  // 🔴  END SUBSCRIPTION CONSTANTS

  //FeaturedNGOs
  FeaturedNGOs: 1573,
  RoshniHomes: 1574,

  //  List of Sources
  ZamanFoundation: 1571,
  ZamanFoundationNGO: 1,

  // FeedaFamily : 1504,
  // GitaWinterKit : 1505,
  // Providelivehoddassistance : 1506,
  // SponsoraMedicalcamp : 1507,
  // RebuildaHome: 1508,

  // DisaaterRelief:1503,

  // DisasterReleif_DonationType : 1509

  //DisasterRelif:1529,

  // Disaster_FeedFamily:1530,
  // Disaster_Git:1531,
  // Disaster_Livelihood:1532,
  // Disaster_MedicalCamp:1533,
  // Disaster_RebuildHome:1534,

  //DisasterRelif:1531, //Live
  // generalAid: 1538, //Live
  MeezanBank: 1539, //

  // DisasterRelif:1529, //UATLive
  // generalAid: 1542, //UATLive
  // MeezanBank:1543,

  // Disaster_FeedFamily:1532,
  // Disaster_Git:1533,
  // Disaster_Livelihood:1534,
  // Disaster_MedicalCamp:1535,
  // Disaster_RebuildHome:1536,
}

// export const PhyscialAudit = {
//     Open : 1540,
//     InProgress :  1541,
//     Complete :   1542,
//     Closed :    1543,
// };

export const SetupMasterDetailsConstantsValues = {
  Applicant: 1,
  Employee: 2,
  Company: 3,
  CompanyFamily: 4,
  FrequencyOneTime: 642,
  FrequencyFirstInstallment: 1499, 
  FrequencySecondInstallment : 1500, 
  FrequencyThirdInstallment: 1501, 
  FrequencyFirstSemester: 1521, 
  FrequencySecondSemester: 1522, 
  FrequencyThirdSemester: 1523, 
  FrequencyFourthSemester: 1524, 
  FrequencyFifthSemester: 1525, 
  FrequencySixthSemester: 1526, 
  FrequencySeventhSemester: 1527, 
  FrequencyEighthSemester: 1528, 
}

export const Roles = {
  SuperAdmin: 1,
  InvestigatingOfficer: 3,
  HOD: 11,
  Trustee: 12,
  Audit: 14,
  Accounts: 15,
  FrontDesk: 16,
  Marketing: 17,
  Donor: 37,
  Physical_Auditor: 38,
  EducationalCounsellor: 39,
  Advisor: 5,
}

export const PhysicalAuditor = {
  Open: 1540,
  InProgress: 1541,
  Complete: 1542,
  Closed: 1543,
}

export const TabNo = {
  FirstTab: "1",
  SecondTab: "2",
  ThirdTab: "3",
  FourthTab: "4",
  FifthTab: "5",
  SixthTab: "6",
  SeventhTab: "7",
}
export const SubTabNo = {
  First_FirstTab: "11",
  First_SecondTab: "12",
  First_ThirdTab: "13",
  First_FourthTab: "14",
  Second_FirstTab: "21",
  Second_SecondTab: "22",
  Second_ThirdTab: "23",
  Second_FourthTab: "24",
  Second_FifthTab: "25",
  Second_SixthTab: "26",
  Third_FirstTab: "31",
  Third_SecondTab: "31",
}
export const HomeApplainceConst = {
  AssetTypeId: 5,
  Own: 6,
}
export const PaymentMethod = {
  BankId: 743,
  ElectronicId: 738,
  ReadyToDisbursed: 659,
}

export const CaseStatuses = {
  Assigned_Investigator: 343,
  Unassigned: 344,
  Closed: 409,
  Re_Investigation: 410,
  Terminated: 411,
  Submitted_Investigator: 472,
  Submitted_HOD: 474,
  Objection_HOD: 476,
  Approved_Trustee: 480,
  Objection_Trustee: 481,
  Rejected: 626,
  Case_Hold_Trustee: 746,
  Objection_Auditor: 747,
  Objection_Accounts: 748,
  Physical_Audit_Trustee: 936,
}

export const DonationForTypes = {
  Quick_Donations: 724,
  Case_Wise_Donation: 725,
  Category_Wise_Donation: 1499,
}

export const DefaultImgPath = baseImageUrl + "/UploadImages/default.png"
export const CaseDetailDefaultImgPath =
  baseImageUrl + "/UploadImages/default-case.png"

export const MarketingContentModules = {
  AdsModule: 1583,
  ImpactStrip: 1584,
}

export const DonorQueryStatuses = {
  Pending: 1577,
  InProgress: 1578,
  Closed: 1579,
}

export const DataTableCustomStyles = {
  headCells: {
    style: {
      backgroundColor: "#d60b11",
      placeContent: "center",
      color: "white",
      borderRadius: "3px",
      fontWeight: "bolder",
      paddingLeft: "8px",
      paddingRight: "8px",
      fontSize: "0.9rem",
    },
  },
}

export const QURBANI_DONATE_PATH = "qurbaniDonate"
