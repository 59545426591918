import React, { useState } from "react"
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Label,
  Input,
} from "reactstrap"
import * as api from "../../utils/Api.js"
import { DataTableCustomStyles } from "utils/Constants"
import {
  AllowAlphabatic,
  dateFormat,
  dateFormatPlaceholder,
  getDate,
} from "utils/CommonMethods"
import DataTable from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions"
import "react-data-table-component-extensions/dist/index.css"
import DatePicker from "react-datepicker"
import moment from "moment"

const Report_Student_Probation = (props) => {
  let defaultFromDate = new Date()
  defaultFromDate.setDate(defaultFromDate.getDate() - 27)
  const searchingValues = {
    ApplicantCase_InvestigationId: 0,
    ApplicantFamily_EducationDetailId: 0,
    ApplicantFamilyDetailId: 0,
    AcademicId: 0,
    NameOfInstitute: "",
    Counselling: null,
    ProgramName: null,
    Grade_Percentage_CGPA_Marks: "",
    Location: "",
    Educational_ContactNo: null,
    DegreeId: null,
    YearOfCompletion: null,
    UserId: localStorage.getItem("UserId"),
    UserIP: localStorage.getItem("UserIP"),
    RoleId: localStorage.getItem("RoleId"),
    IsProbationRemarks: "",
    IsProbation: false,
    OperationId: 4,
    ApplicantCaseCode: "",
    ApplicantName: "",
    CNIC: "",
    Mobile: "",
    Category: "",
    Gender: "",
    City: "",
    Area: "",
    // FundRequired: 0,
    // FundApproval: 0,
    Investigator: "",
    Familymembername: "",
    Degree: "",
    Class: "",
    Class_SemesterId: null,
    Grade: "",
    CreatedDate: "",
    // CreatedDate: defaultFromDate.toLocaleDateString("en-CA"),
    GenderId: null,
    CityId: null,
    AreaId: null,
    PrimaryFundCategoryId: null,
    InvestigatorId: null,
  }

  const [searchValues, setSearchVlues] = useState(searchingValues)
  const [genders, setGenders] = useState([])
  const [areas, setAreas] = useState([])
  const [cities, setCities] = useState([])
  const [classes, setClasses] = useState([])
  const [degrees, setDegrees] = useState([])
  const [Investigators, setInvestigators] = useState([])
  const [categories, setCategories] = useState([])
  const [finalData, setFinalData] = useState({})
  const columns = [
    {
      name: "Applicant case code",
      selector: "ApplicantCaseCode",
      width: "180px",
      sortable: true,
    },
    {
      name: "Applicant Name",
      selector: "ApplicantName",
      width: "180px",
      sortable: true,
    },
    {
      name: "CNIC",
      selector: "CNIC",
      width: "180px",
      sortable: true,
    },
    {
      name: "Contact",
      selector: "Mobile",
      width: "180px",
      sortable: true,
    },
    {
      name: "Gender",
      selector: "Gender",
      width: "180px",
      sortable: true,
    },
    {
      name: "City",
      selector: "City",
      width: "180px",
      sortable: true,
    },
    {
      name: "Area",
      selector: "Area",
      width: "180px",
      sortable: true,
    },
    {
      name: "Fund Category",
      selector: "Category",
      width: "180px",
      sortable: true,
    },
    {
      name: "Fund Required",
      selector: "FundRequired",
      width: "180px",
      sortable: true,
    },
    {
      name: "Fund Approval",
      selector: "FundApproval",
      width: "180px",
      sortable: true,
    },
    {
      name: "Investigator",
      selector: "Investigator",
      width: "180px",
      sortable: true,
    },
    {
      name: "Family member name",
      selector: "Familymembername",
      width: "180px",
      sortable: true,
    },
    {
      name: "Programme",
      selector: "Program",
      width: "180px",
      sortable: true,
    },
    {
      name: "Degree",
      selector: "Degree",
      width: "180px",
      sortable: true,
    },
    {
      name: "Class",
      selector: "Class",
      width: "180px",
      sortable: true,
    },
    {
      name: "Grade / CGPA",
      width: "180px",
      selector: "Grade_CGPA",
      sortable: true,
    },
    {
      name: "Created date",
      selector: "CreatedDate",
      cell: (row) => {
        return row.CreatedDate
          ? moment(row.CreatedDate).format("DD/MM/YYYY")
          : ""
      },
      width: "180px",
      sortable: true,
    },
    {
      name: "Probation remarks",
      selector: "ProbationRemarks",
      width: "180px",
      sortable: true,
    },
  ]

  const AllDateSet = (event, type) => {
    //...formFields,
    //CreatedDateTo: event

    if (type === "CreatedDate") {
      setSearchVlues({
        ...searchValues,
        CreatedDate: event,
        // familyDateOfBirth: event,
      })
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    let _values = e.target.value

    if (e.target.getAttribute("isalphabetic") === "true") {
      _values = AllowAlphabatic(e.target.value)
    } else if (e.target.getAttribute("isnumber") == "true")
      _values = e.target.value.replace(/\D/g, "")

    if (name === "Category") {
      setSearchVlues({
        ...searchValues,
        [name]: value,
      })

      return
    }
    setSearchVlues({
      ...searchValues,
      [name]: _values,
    })
  }
  const [pending, setPending] = React.useState(true)

  React.useEffect(() => {
    // need to define the function and call it separately
    const load = async () => {
      resetFormelement()
    }
    load()
  }, [])

  const resetFormelement = async () => {
    setSearchVlues(searchingValues)
    ReBindGrid()
  }

  const handleSearchClick = async (e) => {
    e.preventDefault()
    ReBindGrid(false)
  }

  const handleCancelClick = async (e) => {
    e.preventDefault()
    resetFormelement()
  }

  const SaveGetStatus = async (IsSearchFilters = true) => {
    // 2023 - 08 - 17
    searchValues.CreatedDate = !searchValues.CreatedDate
      ? null
      : moment(searchValues.CreatedDate).format("YYYY-MM-DD")
    return await api.postRecord(`Applicant`, `Crud_Family_Education_Detail`, {
      ...searchValues,
      IsSearchFilters,
    })
  }

  const ReBindGrid = async (IsSearchFilters = true) => {
    setPending(true)
    var { data } = await SaveGetStatus(IsSearchFilters)
    setFinalData({ columns, data: data.DataSet.Table })
    if (IsSearchFilters) {
      setGenders(data.DataSet.Table1)
      setAreas(data.DataSet.Table2)
      setCities(data.DataSet.Table3)
      setCategories(data.DataSet.Table4)
      setDegrees(data.DataSet.Table5)
      setClasses(data.DataSet.Table6)
      setInvestigators(data.DataSet.Table7)
    }
    setPending(false)
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col lg={12} md={12}>
            <Card className="card-user">
              <CardBody>
                <Form>
                  <Row form>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Applicant Case Code</Label>
                        <Input
                          type="text"
                          placeholder="Applicant Case Code"
                          onChange={handleInputChange}
                          name="ApplicantCaseCode"
                          maxLength="50"
                          value={searchValues.ApplicantCaseCode}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Applicant Name</Label>
                        <Input
                          type="text"
                          placeholder="Applicant Name"
                          onChange={handleInputChange}
                          name="ApplicantName"
                          maxLength="50"
                          value={searchValues.ApplicantName}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">CNIC</Label>
                        <Input
                          type="text"
                          onChange={handleInputChange}
                          isnumber="true"
                          placeholder="XXXXXXXXXXXXX"
                          name="CNIC"
                          maxLength="13"
                          value={searchValues.CNIC}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Mobile</Label>
                        <Input
                          type="text"
                          placeholder="Mobile"
                          onChange={handleInputChange}
                          name="Mobile"
                          isnumber="true"
                          maxLength="13"
                          value={searchValues.Mobile}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Gender</Label>
                        <Input
                          id="exampleSelect"
                          name="GenderId"
                          type="select"
                          value={searchValues.GenderId || ""}
                          onChange={handleInputChange}
                        >
                          <option key={null} value={""}>
                            Select
                          </option>

                          {genders?.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">City</Label>
                        <Input
                          id="exampleSelect"
                          name="CityId"
                          type="select"
                          value={searchValues.CityId || ""}
                          onChange={handleInputChange}
                        >
                          <option key={null} value={""}>
                            Select
                          </option>

                          {cities?.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Area</Label>
                        <Input
                          id="exampleSelect"
                          name="AreaId"
                          type="select"
                          value={searchValues.AreaId || ""}
                          onChange={handleInputChange}
                        >
                          <option key={null} value={""}>
                            Select
                          </option>

                          {areas?.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Category</Label>
                        <Input
                          id="exampleSelect"
                          name="PrimaryFundCategoryId"
                          type="select"
                          value={searchValues.PrimaryFundCategoryId || ""}
                          onChange={handleInputChange}
                        >
                          <option key={null} value={""}>
                            Select
                          </option>

                          {categories?.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Investigator</Label>
                        <Input
                          id="exampleSelect"
                          name="InvestigatorId"
                          type="select"
                          value={searchValues.InvestigatorId || ""}
                          onChange={handleInputChange}
                        >
                          <option key={null} value={""}>
                            Select
                          </option>

                          {Investigators?.map((item, key) => (
                            <option key={key} value={item.UserId}>
                              {item.Name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Family Member Name</Label>
                        <Input
                          type="text"
                          placeholder="Family Member Name"
                          onChange={handleInputChange}
                          name="Familymembername"
                          maxLength="50"
                          value={searchValues.Familymembername}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Programme</Label>
                        <Input
                          type="text"
                          placeholder="Programme"
                          onChange={handleInputChange}
                          name="ProgramName"
                          maxLength="50"
                          value={searchValues.ProgramName || ""}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Degree</Label>
                        <Input
                          id="exampleSelect"
                          name="DegreeId"
                          type="select"
                          value={searchValues.DegreeId || ""}
                          onChange={handleInputChange}
                        >
                          <option key={null} value={""}>
                            Select
                          </option>

                          {degrees?.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Class</Label>
                        <Input
                          id="exampleSelect"
                          name="Class_SemesterId"
                          type="select"
                          value={searchValues.Class_SemesterId || ""}
                          onChange={handleInputChange}
                        >
                          <option key={null} value={""}>
                            Select
                          </option>

                          {classes?.map((item, key) => (
                            <option key={key} value={item.SetupDetailId}>
                              {item.SetupDetailName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Grade / CGPA</Label>
                        <Input
                          type="text"
                          placeholder="Grade / CGPA"
                          onChange={handleInputChange}
                          name="Grade_Percentage_CGPA_Marks"
                          maxLength="50"
                          value={searchValues.Grade_Percentage_CGPA_Marks}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="">Created Date</Label>
                        <DatePicker
                          value={getDate(searchValues.CreatedDate, "/")}
                          dateFormat={dateFormat}
                          onChange={(e) => AllDateSet(e, "CreatedDate")}
                          className="form-control"
                          name="CreatedDate"
                          placeholderText={dateFormatPlaceholder}
                          showYearDropdown
                        />
                      </FormGroup>
                    </Col>

                    <Col md={12} className="text-right">
                      <Button
                        color="primary"
                        size="sm"
                        className="mr-2"
                        onClick={handleSearchClick}
                      >
                        Search
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12}>
            <Card>
              <CardHeader>
                <Row>
                  <Col lg={6} md={6}>
                    Report Student Probation
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <DataTableExtensions
                  {...finalData}
                  exportHeaders={true}
                  fileName="Report Student Probation"
                >
                  <DataTable
                    dense
                    direction="auto"
                    defaultSortField="FirstName"
                    fixedHeader
                    striped
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    progressPending={pending}
                    responsive
                    customStyles={DataTableCustomStyles}
                    fixedHeaderScrollHeight="auto"
                    subHeaderAlign="right"
                    subHeaderWrap
                  />
                </DataTableExtensions>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Report_Student_Probation
