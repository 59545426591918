import React, { useState } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Table,
    Row,
    Col,
    Button,
    FormGroup,
    Form,
    Label,
    Input
} from "reactstrap";
import { fetchData } from '../utils/Api.js'
import { ApiMethods, ControllerName, OperationTypeId, SetupMasterIds } from '../utils/Constants.js'
import { GetSetupMaster, DeleteSetupDetail,AllowAlphabatic } from '../utils/CommonMethods.js'
import Modal_Category from "components/modal/ModalCategory"

import Swal from "sweetalert2";

const Category = (props) => {
    const initialValues = {
        searchCategoryName: "",

    };

    const [values, setValues] = useState(initialValues);
    const [categoryList, setcategoryList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [categoryEditId, setcategoryEditId] = useState(0);


    const handleInputChange = (e) => {
       
    
        const { name, value } = e.target;
        let values = e.target.value;


        if (e.target.getAttribute("isalphabetic") === "true") {
       
            values = AllowAlphabatic(e.target.value);
        }






        setValues({
            ...values,
            [name]: values,
        });
    };

    React.useEffect(() => {

        // need to define the function and call it separately
        const load = async () => {
            resetFormelement();
        };
        load();
    }, []);

    const onEdit = ({ CategoryId }) => {
        openNewmodal(CategoryId);
    }

    const resetFormelement = async () => {
      
        ReBindGrid(SetupMasterIds.Category, 0, "", 0);


    }
    const onDelete = async ({ CategoryId }) => {

        var UserId = localStorage.getItem('UserId');
        var UserIp = localStorage.getItem('UserIP');
        var deleteData = await DeleteSetupDetail(CategoryId, UserId, UserIp);
        if (deleteData.response === true && deleteData.data != null) {
            if (deleteData.data[0].HasError === 1) {

                Swal.fire({ title: 'Error', text: deleteData.data[0].Message, icon: 'error' });
            }
            else {


                Swal.fire({ title: 'Success', text: "Deleted Successfully", icon: 'success' });

            }
        }
        resetFormelement();

    }

    const handleSearchClick = async (e) => {
        e.preventDefault();
      
        ReBindGrid(SetupMasterIds.Category, "0", values.searchCategoryName, 0);

    }

    const handleCancelClick = async (e) => {
      
        e.preventDefault();


        resetFormelement();
        setValues(initialValues);

    }


    const ReBindGrid = async (setupMasterId, parentId, setupDetailName, setupDetailId = 0) => {

        var data = await GetSetupMaster(setupMasterId, parentId, setupDetailName, setupDetailId);
       
        setcategoryList(data.data);

    }
    const openNewmodal = (CategoryId) => {
     
        setcategoryEditId(CategoryId);
        setOpenModal(true);
    }
    const closeNewmodal = () => {
        setOpenModal(false);
        setcategoryEditId(0);
        resetFormelement();
        setValues(initialValues);

    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg={12} md={12}>
                        <Card className="card-user">
                            <CardBody>
                                <Form>
                                    <Row form>

                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="">Category Name</Label>
                                                <Input
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    name="searchCategoryName"
                                                    value={values.searchCategoryName}
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    isalphabetic="true"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="text-right">
                                            <Button color="primary" size="sm" className="mr-2" onClick={handleSearchClick}>Search</Button>
                                            <Button color="secondary" size="sm" onClick={handleCancelClick}>Cancel</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12}>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col lg={6} md={6}>
                                        Category List
                                    </Col>
                                    <Col lg={6} md={6} className="text-right">
                                        <Button color="primary2" size="sm" className="m-0" onClick={() => openNewmodal({ CategoryId: 0 })}>Add New</Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Table bordered striped>
                                    <thead>
                                        <tr>
                                            <th>Sr #</th>
                                            <th>Name</th>

                                            {/* <th>Active</th>
                                            <th>CreatedBy</th>
                                            <th>Last Modified By</th> */}
                                            <th className="text-center" style={{ width: 150 }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categoryList && categoryList.map((item, key) => (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>{item.SetupDetailName}</td>

                                                {/* <td>{item.IsActive ? "Yes" : "No"}</td>
                                                <td>{item.CreatedBy + ' on ' + item.CreatedDate}</td>
                                                <td>{item.ModifiedBy + " " + (item.ModifiedDate == '' ? "" : "on" + item.ModifiedDate)}</td> */}
                                                <td className="text-center">
                                                    <Button color="primary" className="btn-circle" size="sm" onClick={(e) => onEdit({ CategoryId: item.SetupDetailId })}><i className="nc-icon nc-ruler-pencil"></i></Button>
                                                    <Button color="danger" className="btn-circle" size="sm" onClick={() => onDelete({ CategoryId: item.SetupDetailId })}><i className="nc-icon nc-simple-remove"></i></Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {
                    openModal &&
                    <Modal_Category {...props}
                        HeaderText="Add/Edit Category"
                        Ismodalshow={openModal}
                        closeNewmodal={closeNewmodal}
                        CategoryId={categoryEditId}

                        ReBindGrid={ReBindGrid}
                    />

                }
            </div>

        </>
    );
}

export default Category