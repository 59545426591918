import React, { useState } from "react";

import {
  Col,
  Container,
  Row,
  Card,
  CardTitle,
  CardBody,
  CardText,
  Button,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { MarketingContentController } from "utils/CommonMethods";
import ReactModalVideo from "components/GeneralComponent/ReactModalVideo";
import useApiCallOnMount from "hooks/useApiCallOnMount";
import { baseImageUrl } from "utils/Api";
import ApiStateHandler from "components/GeneralComponent/ApiStatusHandler";
import { MarketingContentModules } from "utils/Constants";
import HomeSupport from "./HomeSupport";

const AdsDetailID = MarketingContentModules.AdsModule;
const GetAds = async () => {
  try {
    var { data } = await MarketingContentController(2, 0, AdsDetailID);
    if (data.DataSet.Table) {
      return data.DataSet.Table.filter((item) => item.Content_Display);
    } else {
      return [];
    }
  } catch (error) {
    if (error.message) {
      throw new Error(error.message);
    } else {
      throw new Error("Something went wrong");
    }
  }
};

const LEFT_MAX_WORDS = 100;
const MAX_WORDS = 20;

const WhoWeAre = () => {
  const [activeAd, setActiveAd] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, data, error, errorText] = useApiCallOnMount(GetAds);
  const history = useHistory();
  const dataExists = data && data?.length > 0;

  const item1 =
    dataExists &&
    data.find((item) => item.Content_Position === 1 && item.Content_Display);
  const item2 =
    dataExists &&
    data.find((item) => item.Content_Position === 2 && item.Content_Display);
  const item3 =
    dataExists &&
    data.find((item) => item.Content_Position === 3 && item.Content_Display);

  const handleClick = (ad) => {
    setActiveAd(ad);
    setShowModal(true);
  };

  return (
    <section className="section  pt-4 pb-0">
      <Container>
        <h2 className="works-title-top mb-4">
          <span className="title-bg"> {item1?.Content_Title} </span>
          {/* <span className="title-bg"> Qurbani 2024: Our Impact </span> */}
        </h2>
        <ApiStateHandler
          loading={loading}
          error={error}
          errorText={errorText}
          dataExists={dataExists}
        >
          {dataExists ? (
            <Row className="no-gutters">
              <Col md={1} />
              <Col md={10}>
                <Row className="gutters">
                  {dataExists &&
                    item1 &&
                    // item1.Content_Description
                    (item1.Content_Description ? (
                      <Col lg="8" md="12" sm="12" xs="12">
                        {/* <CardTitle tag="h4" className="one-line-heading">
                          {item1.Content_Title}
                        </CardTitle> */}
                        <Card
                          style={{
                            width: "100%",
                            padding: "0px",
                            height: "83%",
                            background: "red",
                          }}
                          className="bg-greyish ad-video-card"
                        >
                          <CardBody className="pt-2">
                            <CardText className="ads-content-description-page p-2">
                              {item1?.Content_Description.split(" ")?.length >
                              LEFT_MAX_WORDS ? (
                                <>
                                  <div
                                    className="description-div"
                                    dangerouslySetInnerHTML={{
                                      __html: item1?.Content_Description.split(
                                        " "
                                      )
                                        .slice(0, LEFT_MAX_WORDS)
                                        .join(" "),
                                    }}
                                  />

                                  <span
                                    className="read-more-text"
                                    onClick={(e) => {
                                      // Make it high priority
                                      e.preventDefault();
                                      e.stopPropagation();

                                      history.push(
                                        `/ads?id=${item1.Content_ID}`
                                      );
                                    }}
                                  >
                                    {" "}
                                    read more...
                                  </span>
                                </>
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item1?.Content_Description,
                                  }}
                                />
                              )}
                            </CardText>
                          </CardBody>
                        </Card>
                      </Col>
                    ) : (
                      <Col lg="7" md="12" sm="12" xs="12">
                        <div
                          onClick={() => item1?.VideoURL && handleClick(item1)}
                          className={
                            item1?.VideoURL
                              ? "pointer adds-left-image-card2"
                              : "adds-left-image"
                          }
                        >
                          {/* <CardTitle tag="h4" className="one-line-heading">
                            {item1?.Content_Title}
                          </CardTitle> */}
                          <div class="video-box">
                            <img
                              src={
                                baseImageUrl +
                                item1?.DocAttachmentPath +
                                "/" +
                                item1?.FileGeneratedName
                              }
                              width="100%"
                              alt=""
                              className={
                                item1?.VideoURL
                                  ? " pointer adds-left-image"
                                  : " "
                              }
                              onClick={() =>
                                item1?.VideoURL && handleClick(item1)
                              }
                            />
                            {item1.VideoURL && (
                              <div className="video-box-overlay">
                                <div className="video-box-overlay-play">
                                  <i className="fa fa-play"></i>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    ))}

                  <Col lg="5" md="12" sm="12" xs="12">
                    <div style={{ textAlign: "justify", fontSize: "16px" }}>
                      {/* We are Sab Saath: Pakistan’s first-ever non-profit
                        charity platform, bringing together all of Pakistan’s
                        trusted, accredited and registered NGOs so that you, the
                        donor, can log on from anywhere in the world, can choose
                        an NGO, appeal or cause of your liking, and make an
                        instant donation. */}
                      {/* Last year, Sab Saath’s Qurbani service filled the hearts
                      and bellies of 6,000+ people in need. Through your
                      generosity, we provided the gift of nutritious meals to
                      verified families, including orphans, widows,
                      differently-abled individuals, and 4 flood-affected
                      communities across Southern Punjab and Balochistan.
                     
                      <br />
                      <br />
                      This Eid, let’s continue our tradition of compassion and
                      solidarity. Join us in making a difference. */}

                     {/* <p> This Eid, your generosity made a huge impact!</p> 
                      <p>Sab Saath Mil Kar, 
                      we distributed 5,704+ Kgs of meat to 28,224+
                      people in need in 31 underprivileged communities across 5 
                      provinces in Pakistan and families in need in Gaza.</p>
                      <p>
                      Thanks
                      to your compassion, families were able to celebrate Eid ul 
                      Azha with full hearts and bellies.
                      </p>
                      <p>
                      Together, we continue
                      to alleviate misery and spread joy.</p> */}

                      {/* 𝙏𝙝𝙞𝙨 𝙀𝙞𝙙, 𝙮𝙤𝙪𝙧 𝙜𝙚𝙣𝙚𝙧𝙤𝙨𝙞𝙩𝙮 𝙢𝙖𝙙𝙚 𝙖 𝙝𝙪𝙜𝙚 𝙞𝙢𝙥𝙖𝙘𝙩! 𝙎𝙖𝙗 𝙎𝙖𝙖𝙩𝙝
                      𝙞𝙡 𝙆𝙖𝙧, 𝙬𝙚 𝙙𝙞𝙨𝙩𝙧𝙞𝙗𝙪𝙩𝙚𝙙 5,704+ 𝙆𝙜𝙨 𝙤𝙛 𝙢𝙚𝙖𝙩 𝙩𝙤 28,224+
                      𝙥𝙚𝙤𝙥𝙡𝙚 𝙞𝙣 𝙣𝙚𝙚𝙙 𝙞𝙣 31 𝙪𝙣𝙙𝙚𝙧𝙥𝙧𝙞𝙫𝙞𝙡𝙚𝙜𝙚𝙙 𝙘𝙤𝙢𝙢𝙪𝙣𝙞𝙩𝙞𝙚𝙨 𝙖𝙘𝙧𝙤𝙨𝙨 5
                      𝙥𝙧𝙤𝙫𝙞𝙣𝙘𝙚𝙨 𝙞𝙣 𝙋𝙖𝙠𝙞𝙨𝙩𝙖𝙣 𝙖𝙣𝙙 𝙛𝙖𝙢𝙞𝙡𝙞𝙚𝙨 𝙞𝙣 𝙣𝙚𝙚𝙙 𝙞𝙣 𝙂𝙖𝙯𝙖. 𝙏𝙝𝙖𝙣𝙠𝙨
                      𝙩𝙤 𝙮𝙤𝙪𝙧 𝙘𝙤𝙢𝙥𝙖𝙨𝙨𝙞𝙤𝙣, 𝙛𝙖𝙢𝙞𝙡𝙞𝙚𝙨 𝙬𝙚𝙧𝙚 𝙖𝙗𝙡𝙚 𝙩𝙤 𝙘𝙚𝙡𝙚𝙗𝙧𝙖𝙩𝙚 𝙀𝙞𝙙 𝙪𝙡
                      𝘼𝙯𝙝𝙖 𝙬𝙞𝙩𝙝 𝙛𝙪𝙡𝙡 𝙝𝙚𝙖𝙧𝙩𝙨 𝙖𝙣𝙙 𝙗𝙚𝙡𝙡𝙞𝙚𝙨. 𝙏𝙤𝙜𝙚𝙩𝙝𝙚𝙧, 𝙬𝙚 𝙘𝙤𝙣𝙩𝙞𝙣𝙪𝙚
                      𝙩𝙤 𝙖𝙡𝙡𝙚𝙫𝙞𝙖𝙩𝙚 𝙢𝙞𝙨𝙚𝙧𝙮 𝙖𝙣𝙙 𝙨𝙥𝙧𝙚𝙖𝙙 𝙟𝙤𝙮. */}
                      Sab Saath (literally “All Together”) is a non-profit platform. We bring registered and accredited NGOs 
                      together to coordinate relief efforts across Pakistan. We stand as the bridge that connects individuals, 
                      donors and corporations recognizing that true change arises from collaboration. We showcase meticulously 
                      vetted cases spanning across education, healthcare, water, disaster relief, and beyond so that you, the donor,
                       can donate with peace of mind towards a cause of your choice. In essence, we help you help others.

                    </div>
                    <br />
                    <Row className="mt-4">
                      <Col lg="6" md="6" sm="6" xs="6"></Col>
                      <Col
                        lg="6"
                        md="6"
                        sm="6"
                        xs="6"
                        style={{ textAlign: "center" }}
                      >
                        {/* <div
                          style={{
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          It's that simple.
                        </div> */}
                        {
                          /* <Link
                          to="/about-us"
                          className="btn btn-primary w-65"
                          style={{ background: "#062150" }}
                        >
                          Learn More
                        </Link> */
                          //   <Link
                          //   to="/QurbaniCampaign"
                          //   className="btn btn-primary w-65"
                          //   style={{ background: "#062150" }}
                          // >
                          //   Donate Now
                          // </Link>
                        }
                        <Link
                          to="/about-us"
                          className="btn btn-primary w-65"
                          style={{ background: "#062150" }}
                        >
                          Read More
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                  {/* {dataExists && (
                    <Col
                      lg="4"
                      md="12"
                      sm="12"
                      xs="12"
                      className="pl-lg-2 pl-md-2 pl-sm-0"
                    >
                      <Row>
                        {item2 && (
                          <Col lg="12" md="12" sm="12" xs="12">
                            <div
                              className={
                                item2?.VideoURL && !item2?.Content_Description
                                  ? " pointer  adds-right-image-card"
                                  : " adds-right-image-card"
                              }
                              style={{
                                width: "100%",
                                padding: "0px",
                              }}
                              onClick={() =>
                                item2.VideoURL && handleClick(item2)
                              }
                            >
                              {item2?.Content_Description ? (
                                <>
                                  <CardTitle
                                    tag="h4"
                                    className="one-line-heading"
                                  >
                                    {item2?.Content_Title}
                                  </CardTitle>
                                  <div className="ads-content-description">
                                    {item2?.Content_Description.split(" ")
                                      .length > MAX_WORDS ? (
                                      <>
                                        <div
                                        className="description-div"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              item2?.Content_Description.split(
                                                " "
                                              )
                                                .slice(0, MAX_WORDS)
                                                .join(" "),
                                          }}
                                        />

                                        <span
                                          className="read-more-text"
                                          onClick={(e) => {
                                            // Make it high priority
                                            e.preventDefault()
                                            e.stopPropagation()

                                            history.push(
                                              `/ads?id=${item2.Content_ID}`
                                            )
                                          }}
                                        >
                                          {" "}
                                          read more...
                                        </span>
                                      </>
                                    ) : (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item2?.Content_Description,
                                        }}
                                      />
                                    )}
                                  </div>
                                </>
                              ) : (
                                <div class="video-box thumbnail-ads-section ">
                                  <CardTitle
                                    tag="h4"
                                    className="one-line-heading"
                                  >
                                    {item2?.Content_Title}
                                  </CardTitle>
                                  <img
                                    src={
                                      baseImageUrl +
                                      item2?.DocAttachmentPath +
                                      "/" +
                                      item2?.FileGeneratedName
                                    }
                                    width="100%"
                                    alt=""
                                  />
                                  {item2?.VideoURL && (
                                    <div className="video-box-overlay video-box-overlay2">
                                      <div className="video-box-overlay-play">
                                        {" "}
                                        <i className="fa fa-play"></i>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </Col>
                        )}
                        {item3 && (
                          <Col lg="12" md="12" sm="12" xs="12">
                            {item3?.Content_Description ? (
                              <>
                                <CardTitle
                                  tag="h4"
                                  className="one-line-heading"
                                >
                                  {item3?.Content_Title}
                                </CardTitle>
                                <div className="ads-content-description">
                                  {item3?.Content_Description.split(" ")
                                    .length > MAX_WORDS ? (
                                    <>
                                      <div
                                      className="description-div"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item3?.Content_Description.split(
                                              " "
                                            )
                                              .slice(0, MAX_WORDS)
                                              .join(" "),
                                        }}
                                      />

                                      <span
                                        className="read-more-text"
                                        onClick={(e) => {
                                          // Make it high priority
                                          e.preventDefault()
                                          e.stopPropagation()

                                          history.push(
                                            `/ads?id=${item3.Content_ID}`
                                          )
                                        }}
                                      >
                                        {" "}
                                        read more...
                                      </span>
                                    </>
                                  ) : (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item3?.Content_Description,
                                      }}
                                    />
                                  )}
                                </div>
                              </>
                            ) : (
                              <div
                                className={`  video-box thumbnail-ads-section adds-right-image-card ${
                                  item3.VideoURL ? "pointer" : ""
                                } `}
                                style={{
                                  width: "100%",
                                  padding: "0px",
                                }}
                                onClick={() =>
                                  item3?.VideoURL && handleClick(item3)
                                }
                              >
                                <CardTitle
                                  tag="h4"
                                  className="one-line-heading"
                                >
                                  {item3?.Content_Title}
                                </CardTitle>
                                <img
                                  src={
                                    baseImageUrl +
                                    item3?.DocAttachmentPath +
                                    "/" +
                                    item3?.FileGeneratedName
                                  }
                                  width="100%"
                                  alt=""
                                />
                                {item3?.VideoURL && (
                                  <div className="video-box-overlay video-box-overlay2">
                                    <div className="video-box-overlay-play">
                                      {" "}
                                      <i className="fa fa-play"></i>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </Col>
                        )}
                      </Row>
                    </Col>
                  )} */}
                </Row>
                {/* {dataExists && data.length > 3 && (
                  <Row>
                    <Col
                      lg="12"
                      md="12"
                      sm="12"
                      xs="12"
                      className="text-center"
                    >
                      <Link to="/ads" className="p-sm-0 btn-see-more-ads">
                        See more Ads
                      </Link>
                    </Col>
                  </Row>
                )} */}
              </Col>

              <Col md={1} />
            </Row>
          ) : (
            !item1 && !item2 && !item3 && <div>Work in Progress</div>
          )}
        </ApiStateHandler>
        {/* <HomeSupport /> */}
      </Container>
      <ReactModalVideo
        showModal={showModal}
        setShowModal={setShowModal}
        itemContent={activeAd}
      />
    </section>
  );
};

export default WhoWeAre;
