import { Roles } from "utils/Constants"

const useIsTrusteeHOD = () => {
  const RoleId = localStorage.getItem("RoleId")
  const isTrusteeOrHOD =
    RoleId === Roles.Trustee.toLocaleString() ||
    RoleId === Roles.HOD.toLocaleString()

  return isTrusteeOrHOD
}

export default useIsTrusteeHOD
