import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Roles } from "utils/Constants"

const useIsAdvisor = () => {
  const RoleId = localStorage.getItem("RoleId")
  const isAdvisor = RoleId === Roles.Advisor.toLocaleString()

  return isAdvisor
}

export default useIsAdvisor
